import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import {
  Wizard,
  Button,
  Form,
  Modal,
  FormRefHandle,
  WizardSlideHeader,
  WizardSlideContent,
  BooleanInput,
  Tooltip,
  FormField,
  BaseSelect,
  FormWatch,
  LoadingIndicator,
  BaseSelectOption,
  InfoIcon, SlideOverCloseModal,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { DatevWizardFormValidationSchema, DatevWizardFormValidationValues } from './DatevWizardFormValidationSchema';
import { DatevClientMasterDataCard } from '@client/project/shared';
import {
  DatevClientDto,
  useApiAddDatevApiAccessMutation,
  useApiCreateDatevAuthenticationUrlQuery,
  useApiUpdateDatevApiAccessMutation,
} from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import { useDispatch } from 'react-redux';
import { DatevAuthState, removeDatevData, setDatevClientId, updateDatevAuthData } from '@client/project/store';

interface DatevWizardProps {
  isOpen: boolean;
  onClose: () => void;
  isEdit?: boolean;
  apiId: string;
  apiAccessId?: string;
  isReset?: boolean;
  datevAuthState?: DatevAuthState;
}

export const DatevWizard = ({
  isOpen,
  onClose,
  isEdit,
  apiId,
  apiAccessId,
  isReset,
  datevAuthState,
}: DatevWizardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const authenticatedTenantData = useMemo(() => {
    return datevAuthState?.datevTenantAuthData;
  }, [datevAuthState?.datevTenantAuthData]);

  const authenticatedClientData = useMemo(() => {
    return datevAuthState?.datevClientAuthData;
  }, [datevAuthState?.datevClientAuthData]);

  const formRef = useRef<FormRefHandle<DatevWizardFormValidationValues>>();
  const [currentStep, setCurrentStep] = useState(isEdit ? 2 : 0);
  const [allowNextStep, setAllowNextStep] = useState(true);
  const [selectedDatevClientId, setSelectedDatevClientId] = useState<string>('');
  const [isResetAuth, setIsResetAuth] = useState(false);
  const [isAllCheckboxesChecked, setIsAllCheckboxesChecked] = useState(false);

  const { data: createDatevAuthUrlResponse, isFetching } = useApiCreateDatevAuthenticationUrlQuery(
    {
      datevClientId: selectedDatevClientId,
    },
    {
      skip: (!!authenticatedTenantData && selectedDatevClientId === '' && !isResetAuth) || !isAllCheckboxesChecked,
      refetchOnMountOrArgChange: true
    },
  );

  const [addDatevApiAccess, { isLoading: isAddingDatevApiAccess }] = useApiAddDatevApiAccessMutation();
  const [updateDatevApiAccess, { isLoading: isUpdatingDatevApiAccess }] = useApiUpdateDatevApiAccessMutation();

  useEffect(() => {
    if (datevAuthState?.datevClientAuthData) {
      setCurrentStep(3);
    } else if (datevAuthState?.datevTenantAuthData) {
      setCurrentStep(2);
    }
  }, [datevAuthState]);

  const handleAuthenticateTenant = async () => {
    setIsAllCheckboxesChecked(true);
    /* if (createDatevAuthUrlResponse?.url) {
      window.open(createDatevAuthUrlResponse.url, '_self');
    } */
  };

  // client authentication uses same query but with client id as param
  useEffect(() => {
    if (createDatevAuthUrlResponse?.url && (selectedDatevClientId || isAllCheckboxesChecked)) {
      window.open(createDatevAuthUrlResponse.url, '_self');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createDatevAuthUrlResponse?.url]);

  const handleAuthenticateClient = async () => {
    const client = formRef.current?.getValues()?.client;
    if (client) {
      updateDatevAuthData({
        datevClientId: client,
      } as DatevAuthState);
      dispatch(setDatevClientId(client));
      setIsAllCheckboxesChecked(true);
      setSelectedDatevClientId(client);
    }
  };

  const handleAddDatevApiAccess = async () => {
    if (formRef.current) {
      try {
        if (isReset) {
          await safeMutation(
            updateDatevApiAccess,
            {
              apiAccessId: apiAccessId ?? datevAuthState?.datevApiAccessId ?? '',
              body: {
                tenantDatevAccessToken: authenticatedClientData?.accessToken ?? '',
                tenantDatevAccessTokenExpiresIn: authenticatedClientData?.accessTokenExpiresIn ?? 0,
                tenantDatevRefreshToken: authenticatedClientData?.refreshToken ?? '',
                tenantDatevRefreshTokenExpiresIn: authenticatedClientData?.refreshTokenExpiresIn ?? 0,
                tokenEmittent: authenticatedClientData?.tokenEmittent ?? '',
                isActiveConnection: true,
                datevClients:
                  authenticatedTenantData?.clients.map((client) => {
                    if (client.datevClientId === authenticatedClientData?.datevClientId)
                      return {
                        accessToken: authenticatedClientData?.accessToken ?? '',
                        refreshToken: authenticatedClientData?.refreshToken ?? '',
                        refreshTokenExpiresIn: authenticatedClientData?.refreshTokenExpiresIn ?? '',
                        datevClientId: authenticatedClientData.datevClientId,
                        name: authenticatedClientData.name,
                        clientNumber: authenticatedClientData.clientNumber,
                        consultantNumber: authenticatedClientData.consultantNumber,
                        isDefault: true,
                        isDocumentManagementAvailable: authenticatedClientData?.isDocumentManagementAvailable ?? false,
                        accountingInformation: authenticatedClientData?.accountingInformation ?? [],
                      } as DatevClientDto;
                    else
                      return {
                        datevClientId: client.datevClientId,
                        name: client.name,
                        clientNumber: client.clientNumber,
                        consultantNumber: client.consultantNumber,
                        isDefault: false,
                        isDocumentManagementAvailable: false,
                      } as DatevClientDto;
                  }) ?? [],
              },
            },
            isUpdatingDatevApiAccess,
          );
        } else {
          await safeMutation(
            addDatevApiAccess,
            {
              apiId: apiId,
              body: {
                tenantDatevAccessToken: authenticatedTenantData?.loginResult?.accessToken ?? '',
                tenantDatevAccessTokenExpiresIn: authenticatedTenantData?.loginResult?.accessTokenExpiry ?? 0,
                tenantDatevRefreshToken: authenticatedTenantData?.loginResult?.refreshToken ?? '',
                tenantDatevRefreshTokenExpiresIn: authenticatedTenantData?.loginResult?.refreshTokenExpiry ?? 0,
                tokenEmittent: authenticatedTenantData?.loginResult?.tokenEmittent ?? '',
                isActiveConnection: true,
                datevClients:
                  authenticatedTenantData?.clients.map((client) => {
                    if (client.datevClientId === authenticatedClientData?.datevClientId)
                      return {
                        accessToken: authenticatedClientData?.accessToken ?? '',
                        refreshToken: authenticatedClientData?.refreshToken ?? '',
                        refreshTokenExpiresIn: authenticatedClientData?.refreshTokenExpiresIn ?? '',
                        datevClientId: authenticatedClientData.datevClientId,
                        name: authenticatedClientData.name,
                        clientNumber: authenticatedClientData.clientNumber,
                        consultantNumber: authenticatedClientData.consultantNumber,
                        isDefault: true,
                        isDocumentManagementAvailable: authenticatedClientData?.isDocumentManagementAvailable ?? false,
                        accountingInformation: authenticatedClientData?.accountingInformation ?? [],
                      } as DatevClientDto;
                    else
                      return {
                        datevClientId: client.datevClientId,
                        name: client.name,
                        clientNumber: client.clientNumber,
                        consultantNumber: client.consultantNumber,
                        isDefault: false,
                        isDocumentManagementAvailable: false,
                      } as DatevClientDto;
                  }) ?? [],
              },
            },
            isAddingDatevApiAccess,
          );
        }
        dispatch(removeDatevData());
        onClose();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const validateSteps = useCallback(() => {
    if (formRef.current) {
      const values = formRef?.current?.getValues();
      switch (currentStep) {
        case 1: {
          if (values.smartLogin && values.invoiceDataService) {
            setAllowNextStep(true);
          } else {
            setAllowNextStep(false);
          }
          break;
        }

        case 2: {
          if (values.client !== '') {
            setAllowNextStep(true);
          } else {
            setAllowNextStep(false);
          }
          break;
        }

        default:
          setAllowNextStep(true);
          break;
      }
    }
  }, [currentStep, formRef]);

  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const handleClose = () => {
    setIsCloseModalOpen(false);
    onClose();
  };

  const handleAbort = () => {
    setIsCloseModalOpen(false);
  };

  const defaultFormValues: DatevWizardFormValidationValues = {
    smartLogin: false,
    invoiceDataService: false,
    isActive: false,
    client: '',
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {
      if (currentStep === 3) {
        setIsCloseModalOpen(true);
      } else {
        onClose();
      }
    }}>
      <Form<DatevWizardFormValidationValues>
        validationSchema={DatevWizardFormValidationSchema}
        defaultValues={defaultFormValues}
        ref={formRef}
        className="h-full"
      >
        <FormWatch<DatevWizardFormValidationValues>
          onChange={() => {
            validateSteps();
          }}
          fieldNames={[
            'smartLogin',
            'invoiceDataService',
            'isActive',
            'client',
          ]}
        >
          {() => (
            <Wizard
              currentStep={currentStep}
              onSlideChange={(step) => setCurrentStep(step)}
              className="w-[740px] h-[560px]"
            >
              <Wizard.Slides>
                {/* SLIDE 1 */}
                <Wizard.Slide>
                  <WizardSlideHeader
                    icon={<img src="/assets/datev-logo.svg" alt="Datev Logo" className="w-20 h-20" />}
                    title={t('app.settingsApiDatevConfigureConnection')}
                  />
                  <WizardSlideContent>{t('app.settingsApiDatevConfigureConnectionDescription')}</WizardSlideContent>
                </Wizard.Slide>

                {/* SLIDE 2 - ADDRESS */}
                <Wizard.Slide>
                  <WizardSlideHeader
                    title={t('app.settingsApiDatevCheckListTitle')}
                    subTitle={t('app.settingsApiDatevCheckListDescription')}
                  />
                  <WizardSlideContent>
                    <div className="flex flex-col gap-1">
                      <FormField name="smartLogin">
                        {(control) => (
                          <div className="relative inline-block">
                            <BooleanInput
                              label={t('app.settingsApiDatevSmartLoginTitle')}
                              subLabel={t('app.settingsApiDatevSmartLoginDescription')}
                              className=""
                              children={<div className="mr-2 w-5" />}
                              {...control}
                            />
                            <Tooltip
                              placement="bottom"
                              className="absolute top-[50%] right-[50px] transform translate-x-1/2 -translate-y-1/2"
                              label={<QuestionMarkCircleIcon className="w-5 h-5 text-black " />}
                            >
                              <div>{t('app.settingsApiDatevSmartLoginTooltip')}</div>
                            </Tooltip>
                          </div>
                        )}
                      </FormField>
                      <FormField name="invoiceDataService">
                        {(control) => (
                          <div className="relative inline-block">
                            <BooleanInput
                              label={t('app.settingsApiDatevInvoiceDataServiceTitle')}
                              subLabel={t('app.settingsApiDatevAuthorisation')}
                              {...control}
                            />
                            <Tooltip
                              placement="bottom"
                              className="absolute top-[50%] right-[50px] transform translate-x-1/2 -translate-y-1/2"
                              label={<QuestionMarkCircleIcon className="w-5 h-5 text-black " />}
                            >
                              <div>{t('app.settingsApiDatevInvoiceDataServiceTooltip')}</div>
                            </Tooltip>
                          </div>
                        )}
                      </FormField>
                    </div>
                  </WizardSlideContent>
                </Wizard.Slide>

                {/* SLIDE 3 */}
                <Wizard.Slide>
                  <WizardSlideHeader
                    title={t('app.settingsApiDatevChooseClient')}
                    // subTitle={
                    //   title={t('app.settingsApiDatevChooseClient')}'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.'
                    // }
                  />
                  <WizardSlideContent>
                    <div className="relative">
                      {isFetching && (selectedDatevClientId || isAllCheckboxesChecked) && <LoadingIndicator mode="overlay" />}
                      <FormField name="client">
                        {(control) => (
                          <BaseSelect
                            label={t('app.settingsApiDatevClient')}
                            options={
                              (authenticatedTenantData?.clients.map((client) => {
                                return {
                                  label: client.name,
                                  value: client.datevClientId,
                                };
                              }) as BaseSelectOption[]) ?? []
                            }
                            disabled={isFetching}
                            {...control}
                          />
                        )}
                      </FormField>
                    </div>
                  </WizardSlideContent>
                </Wizard.Slide>

                {/* SLIDE 4 */}
                <Wizard.Slide>
                  <WizardSlideHeader
                    title={t('app.settingsApiDatevConnected')}
                    // subTitle={
                    //   'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.'
                    // }
                  />
                  <WizardSlideContent>
                    {!!authenticatedClientData && <DatevClientMasterDataCard clientData={authenticatedClientData} />}
                    {/* <FormField name="isActive">
                      {(control) => (
                        <BooleanInput
                          label={
                            control.value
                              ? t('app.settingsApiDatevConnectionSyncActive')
                              : t('app.settingsApiDatevConnectionSyncInactive')
                          }
                          icon={<SortingArrowsIcon className="rotate-90" />}
                          variant="switch"
                          className="mt-4"
                          {...control}
                        />
                      )}
                    </FormField> */}
                  </WizardSlideContent>
                </Wizard.Slide>
              </Wizard.Slides>
              <Wizard.Navigation>
                {({ isLast, canGoNext, previous, next }) => (
                  <Modal.Controls
                    className={classNames({
                      'bg-slate-100': currentStep === 0,
                      'bg-white': currentStep !== 0,
                    })}
                  >
                    {(() => {
                      if (currentStep === 0 || isLast) {
                        return (
                          <Button
                            variant="text"
                            onClick={() => {
                              if (isLast) {
                                setIsCloseModalOpen(true);
                              } else {
                                onClose();
                              }
                            }}
                          >
                            {t('common.cancel')}
                          </Button>
                        );
                      } else {
                        return (
                          <Button
                            variant="text"
                            onClick={() => {
                              if (currentStep === 2) {
                                setSelectedDatevClientId('');
                                setIsResetAuth(true);
                                dispatch(removeDatevData());
                              }
                              previous();
                            }}
                          >
                            {currentStep === 2 ? (
                              <Tooltip label={<>{t('common.back')}</>}>
                                <div className="flex items-center gap-2 italic text-sm">
                                  <InfoIcon className="w-4" />
                                  {t('appSettingsApiDatevConnectionReAuthMessage')}
                                </div>
                              </Tooltip>
                            ) : (
                              t('common.back')
                            )}
                          </Button>
                        );
                      }
                    })()}
                    {(() => {
                      if (currentStep === 1) {
                        return (
                          <Button
                            variant="primary"
                            onClick={handleAuthenticateTenant}
                            disabled={!canGoNext || !allowNextStep || isFetching}
                          >
                            {t('common.next')}
                          </Button>
                        );
                      } else if (currentStep === 2) {
                        return (
                          <Button
                            variant="primary"
                            onClick={handleAuthenticateClient}
                            disabled={!canGoNext || !allowNextStep || isFetching}
                          >
                            {t('common.next')}
                          </Button>
                        );
                      } else if (currentStep === 3) {
                        return (
                          <Button variant="primary" onClick={handleAddDatevApiAccess} disabled={isAddingDatevApiAccess}>
                            {t('common.save')}
                          </Button>
                        );
                      } else {
                        return (
                          <Button variant="primary" onClick={next} disabled={!canGoNext || !allowNextStep}>
                            {t('common.next')}
                          </Button>
                        );
                      }
                    })()}
                  </Modal.Controls>
                )}
              </Wizard.Navigation>
            </Wizard>
          )}
        </FormWatch>
      </Form>
      <Modal
        isOpen={isCloseModalOpen}
        onClose={handleAbort}
        showCloseButton={false}
        variant="small-wide"
      >
        <SlideOverCloseModal
          onClose={handleClose}
          onAbort={handleAbort}
          description={t('appSettingsApiDatevConnectionCancelMessage')}
        />
      </Modal>
    </Modal>
  );
};
