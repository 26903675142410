import {
  JLL_CUSTOM_REPORT_DASHBOARD_IDS,
  JLL_CUSTOM_REPORT_DASHBOARD_NAME,
  JLL_CUSTOM_REPORT_DASHBOARDS_CONFIG,
  mapDashboardWidgetsToGridLayout,
  prepareWidgetsToSave,
  WidgetReportDashboard,
} from '@client/project/shared';
import {
  Button,
  DecoratedCard,
  Form,
  FormField,
  LoadingIndicator,
  PencilIcon,
  SlideOver,
  SlideOverContent,
  SlideOverControls,
  SlideOverHeader,
  TextInput,
  TrashIcon,
} from '@client/shared/toolkit';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import {
  ReportDashboardReadModel,
  UpsertReportDashboardPayload,
  useApiDeleteReportDashboardMutation,
  useApiGetReportDashboardsQuery,
  useApiPostUpdateReportDashboardMutation,
} from '@client/shared/api';
import { useEffect, useMemo, useState } from 'react';
import { useLoadedProjectId } from '@client/project/store';
import { useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';
import * as yup from 'yup';
import { InferType } from 'yup';

export const ReportDashboardEditFormValidationSchema = yup.object({
  title: yup.string().required(),
});

export type ReportDashboardEditFormValidationValues = InferType<typeof ReportDashboardEditFormValidationSchema>;

export const ReportDashboard = () => {
  const { reportId } = useParams();
  const navigate = useNavigate();
  const loadedProjectId = useLoadedProjectId();
  const { t } = useTranslation();

  const [isEditSlideOverOpen, setIsEditSlideOverOpen] = useState(false);

  // TODO load only the specific report! (new BE endpoint required)
  const { data, isFetching, isError } = useApiGetReportDashboardsQuery(
    {
      projectId: loadedProjectId || '',
    },
    { skip: loadedProjectId == null },
  );

  const [updateReportDashboard, { isLoading: isUpdatingReportDashboard }] = useApiPostUpdateReportDashboardMutation();
  const [deleteReportDashboard, { isLoading: isDeletingReportDashboard }] = useApiDeleteReportDashboardMutation();

  if (!reportId && !isFetching) {
    navigate(ROUTES_CONFIG.REPORTING_BASE.name);
  }

  const isJLLReport = useMemo(() => {
    return reportId && JLL_CUSTOM_REPORT_DASHBOARD_IDS.includes(reportId);
  }, [reportId]);

  useEffect(() => {
    if (isError) {
      navigate(ROUTES_CONFIG.REPORTING_BASE.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const report = useMemo(() => {
    if (isJLLReport) {
      const jllReport = JLL_CUSTOM_REPORT_DASHBOARDS_CONFIG[reportId as JLL_CUSTOM_REPORT_DASHBOARD_NAME];
      if (!jllReport) {
        navigate(ROUTES_CONFIG.REPORTING_BASE.name);
        return null;
      }
      return jllReport;
    }
    if (data) {
      const foundReport = data.find((reportData) => reportData.id === reportId);
      if (!foundReport) {
        navigate(ROUTES_CONFIG.REPORTING_BASE.name);
        return null;
      }
      return foundReport;
    }
    return null;
  }, [data, reportId, navigate, isJLLReport]);

  const onDelete = async () => {
    if (report?.id && loadedProjectId) {
      try {
        const deleteResponse = await safeMutation(
          deleteReportDashboard,
          {
            dashboardId: report.id,
            projectId: loadedProjectId,
          },
          isDeletingReportDashboard,
        );
        if (deleteResponse) {
          navigate(ROUTES_CONFIG.REPORTING_BASE.name);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleSubmit = async (data: ReportDashboardEditFormValidationValues) => {
    if (data.title && report?.id && loadedProjectId && !isJLLReport) {
      await safeMutation(
        updateReportDashboard,
        {
          projectId: loadedProjectId,
          dashboardId: report.id,
          body: {
            name: data.title,
            ...prepareWidgetsToSave(mapDashboardWidgetsToGridLayout('report', report as ReportDashboardReadModel), undefined, 'report'),
          } as UpsertReportDashboardPayload,
        },
        isUpdatingReportDashboard,
      );
      setIsEditSlideOverOpen(false);
    }
  };

  const defaultFormValues = {
    title: report?.name ?? t('reporting.customReportTitle'),
  };

  return (
    <>
      {isFetching || !report || isUpdatingReportDashboard || isDeletingReportDashboard ? (
        <LoadingIndicator mode="overlay-window" />
      ) : (
        <>
          <DecoratedCard className="w-[1755px]">
            <DecoratedCard.Header>
              <div className="flex justify-between w-full items-center">
                <div className="flex-1 truncate leading-none">{report.name}</div>
                {report.id && !isJLLReport && (
                  <Button className="flex-none" variant="custom" onClick={() => setIsEditSlideOverOpen(true)}>
                    <PencilIcon className="w-6 h-6" />
                  </Button>
                )}
              </div>
            </DecoratedCard.Header>
            <DecoratedCard.Content className="px-4">
              <WidgetReportDashboard reportDashboardData={report} isEditable={!isJLLReport} />
            </DecoratedCard.Content>
          </DecoratedCard>
          <SlideOver isOpen={isEditSlideOverOpen} onClose={() => setIsEditSlideOverOpen(false)}>
            <SlideOverHeader
              onClose={() => setIsEditSlideOverOpen(false)}
              title={report.name}
              subTitle={t('dashboard.widget.edit.subtitle')}
              backgroundClassName="bg-gray-900"
            />
            <Form<ReportDashboardEditFormValidationValues>
              onSubmit={handleSubmit}
              validationSchema={ReportDashboardEditFormValidationSchema}
              defaultValues={defaultFormValues}
              className="flex flex-col flex-grow min-h-0"
            >
              <SlideOverContent className="p-8">
                <FormField name="title">
                  {(control) => <TextInput label={t('dashboard.widget.edit.title')} {...control} />}
                </FormField>
              </SlideOverContent>
              <SlideOverControls>
                <div className="w-full flex justify-between">
                  <Button variant="danger" className="h-full" onClick={onDelete}>
                    <TrashIcon className="w-4 h-4 text-white" />
                  </Button>
                </div>
                <div className="flex">
                  <Button variant="secondary" className="mr-2" onClick={() => setIsEditSlideOverOpen(false)}>
                    {t('common.cancel')}
                  </Button>
                  <Button variant="primary" className="mr-2" formSubmit>
                    {t('common.save')}
                  </Button>
                </div>
              </SlideOverControls>
            </Form>
          </SlideOver>
        </>
      )}
    </>
  );
};
