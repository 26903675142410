import { useTranslation } from 'react-i18next';
import { useLoadedProject } from '@client/project/store';
import {
  ExternalApiReadModel,
  useApiGetExternalApisQuery,
  useApiPostProjectValuationMutation,
} from '@client/shared/api';
import React, { useEffect, useState } from 'react';
import { ProtectedNavLink, ROUTES_CONFIG, useValidatePermissionsAndLicenses } from '@client/shared/permissions';
import { safeMutation } from '@client/shared/utilities';
import { Button, LoadingIndicator } from '@client/shared/toolkit';
import { PriceHubble } from '../../..';

interface MarketPricesCardProps {
  multiProject?: boolean;
}

export const MapMarketPricesCard = ({ multiProject = false }: MarketPricesCardProps) => {
  const { t } = useTranslation();
  const project = useLoadedProject();
  const projectId = project.data?.project.payload.id ?? '';

  const [triggerProjectValuation, { isLoading }] = useApiPostProjectValuationMutation();
  const [isActive, setIsActive] = useState<boolean>(false);

  const { hasPermissionsAndLicense } = useValidatePermissionsAndLicenses(ROUTES_CONFIG.SETTINGS_CONNECT, projectId);
  const { data: externalApis, isFetching: isLoadingExternalApis } = useApiGetExternalApisQuery();

  useEffect(() => {
    if (externalApis)
      setIsActive(!!externalApis?.find((x: ExternalApiReadModel) => x.api.name === 'PriceHubble')?.api.isActive);
  }, [externalApis]);

  const handleProjectValuation = async () => {
    try {
      await safeMutation(
        triggerProjectValuation,
        {
          projectId: projectId,
        },
        isLoading,
      );
    } catch (e) {
      console.error(e);
    }
  };

  const priceHubbledata = project.currentData?.project.payload.priceHubbleValuation;

  return isLoadingExternalApis ? (
    <LoadingIndicator text={t('common.loading')} />
  ) : (
    <>
      {!priceHubbledata && multiProject ? (
        <div />
      ) : !priceHubbledata || !isActive ? (
        isActive ? (
          <div className="px-5 py-2 w-full">
            <Button className="w-full" variant="primary" onClick={() => handleProjectValuation()} disabled={isLoading}>
              {t('projectTaxonomy.dashboardGetPricehubble')}
            </Button>
          </div>
        ) : (
          <div className="mx-5 my-2">
            <ProtectedNavLink
              routeConfig={ROUTES_CONFIG.SETTINGS_CONNECT}
              className="w-full"
              disabledClassName="w-full"
              projectId={projectId}
            >
              <Button className="w-full" variant="primary" disabled={!hasPermissionsAndLicense}>
                {t('projectTaxonomy.dashboardGetPricehubbleCredentials')}
              </Button>
            </ProtectedNavLink>
          </div>
        )
      ) : (
        <div>
          <PriceHubble priceHubbledata={priceHubbledata} handleProjectValuation={handleProjectValuation} />
          {!multiProject && (
            <div className="mx-5 my-2">
              <Button
                className="w-full"
                variant="primary"
                onClick={() => handleProjectValuation()}
                disabled={isLoading}
              >
                {t('projectTaxonomy.dashboardRefreshPricehubble')}
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};
