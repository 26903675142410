import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppState, UiState, UserState } from './uiSlice';

type RootState = {
  ui: UiState;
};

export const useUi = () => {
  const ui = useSelector<RootState, UiState>((x) => x.ui);
  return useMemo(() => ui, [ui]);
};

export const getAppState = (state: RootState): AppState => state.ui.appState;

export const getAppUser = (state: RootState): UserState => state.ui.appUser;
