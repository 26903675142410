import { BgfIcon, LoadingIndicator, WorldwideLocationIcon, WidgetContainer } from '@client/shared/toolkit';
import { DataFeed, FontLight } from './components';
import { useTranslation } from 'react-i18next';
import { Fragment, PropsWithChildren, useMemo } from 'react';
import { formatCurrency, formatDate, formatPercentage, formatUnit } from '@client/shared/utilities';
import { DashboardWidgetConfig, DashboardWidgetVariant } from '../WidgetDashboard';
import { getValueForCustomFieldType } from './DashboardWidgetProjectCustomFields';
import { useGetCurrency } from '@client/project/store';
import {
  ProjectDashboardCustomDataType,
} from '@client/shared/api';
import { useProjectDashboardData } from './hooks';

interface DashboardWidgetProjectMetadataProps extends PropsWithChildren {
  type: 'address' | 'grz' | 'custom';
  variant?: DashboardWidgetVariant;
  config?: DashboardWidgetConfig;
}

export const getCustomDataTitleByType = (
  type: ProjectDashboardCustomDataType,
  value: string | number,
  currentCurrency = 'EUR',
): string => {
  switch (type) {
    case 'String':
      return value.toString();
    case 'Percent': {
      const percentageValue = value as number;
      return formatPercentage(percentageValue / 100, { maxDigits: 2 });
    }
    case 'Date':
      return formatDate(new Date(value.toString()));
    default: {
      const currencyValue = value as number;
      return formatCurrency(currencyValue, {
        maxDigits: 2,
        minDigits: 2,
        currency: currentCurrency,
      });
    }
  }
};

export const DashboardWidgetProjectMetadata = (props: DashboardWidgetProjectMetadataProps) => {
  const { type, children = [], variant = 'card', config } = props;
  const { t } = useTranslation();
  const currentCurrency = useGetCurrency();
  const { data: projectData, isFetching } = useProjectDashboardData();

  const project = useMemo(() => {
    return projectData?.project;
  }, [projectData?.project]);

  const calculationModel = useMemo(() => {
    return projectData?.project?.calculationModel?.metadata;
  }, [projectData?.project?.calculationModel?.metadata]);

  const federalState = useMemo(() => {
    if (projectData?.project?.userDefinedFields?.length) {
      const foundUdf = projectData?.project?.userDefinedFields?.find((udf) => {
        return udf.definition.name === 'Bundesland';
      });
      return foundUdf ? getValueForCustomFieldType(foundUdf) : '';
    }
    return '';
  }, [projectData?.project?.userDefinedFields]);

  const customData: DashboardWidgetConfig | null = useMemo(() => {
    if (projectData?.project?.calculationModel?.customData && config) {
      const foundCustomData = projectData.project.calculationModel.customData.find((cd) => cd.name === config.name);
      if (foundCustomData) {
        return {
          name: foundCustomData.name,
          title: foundCustomData.valuePlanned ? getCustomDataTitleByType(foundCustomData.type ?? 'Currency', foundCustomData.valuePlanned, currentCurrency) : '-',
          icon: config.icon,
        };
      }
    }
    if (config) {
      return {
        name: config.name ?? '',
        title: '-',
        icon: config.icon,
      };
    }
    return null;
  }, [projectData?.project?.calculationModel?.customData, config, currentCurrency]);

  const constructionCostsPerSquareMeterPlanned = useMemo(() => {
    if (projectData?.project?.calculationModel?.customData) {
      const foundCustomData = projectData.project.calculationModel.customData.find((cd) => cd.name === 'Baukosten/m²');
      if (foundCustomData && foundCustomData.valuePlanned) {
        return formatCurrency(foundCustomData.valuePlanned, {
          maxDigits: 2,
          minDigits: 2,
          currency: currentCurrency,
        });
      }
    }
    return '-';
  }, [projectData?.project?.calculationModel?.customData, currentCurrency]);

  const content = useMemo(() => {
    switch (type) {
      case 'address': {
        const addressLine1 =
          project?.street && project?.number
            ? `${project?.street} ${project?.number}`
            : project?.street ?? project?.number;
        const addressLine2 =
          project?.postalCode && project?.city
            ? `${project?.postalCode} ${project?.city}`
            : project?.street ?? project?.city;
        return (
          <DataFeed
            header={<FontLight classNames="text-slate-500">{t('common.address')}</FontLight>}
            title={
              addressLine1 || addressLine2 || federalState ? (
                <div className="flex flex-col">
                  <FontLight>{addressLine1 ?? '-'}</FontLight>
                  <FontLight>{addressLine2 ?? '-'}</FontLight>
                  {federalState && <FontLight>{federalState}</FontLight>}
                </div>
              ) : (
                '-'
              )
            }
            icon={<WorldwideLocationIcon />}
          />
        );
      }
      case 'grz':
        return (
          <DataFeed
            header={<FontLight classNames="text-slate-500">{t('project.bgfRoi')}</FontLight>}
            footer={
              <FontLight classNames="text-slate-500">{constructionCostsPerSquareMeterPlanned} Baukosten/m²</FontLight>
            }
            title={formatUnit(calculationModel?.grossFloorValue.value, calculationModel?.grossFloorValue.unit ?? '')}
            icon={<BgfIcon />}
          />
        );
      case 'custom': {
        if (customData) {
          return (
            <DataFeed
              className="border-t"
              header={<FontLight classNames="text-slate-500">{customData.name}</FontLight>}
              title={customData.title}
              icon={customData.icon}
            />
          );
        }
        return null;
      }
      default:
        return null;
    }
  }, [t, project, type, calculationModel, federalState, constructionCostsPerSquareMeterPlanned, customData]);

  const Component = variant === 'card' ? WidgetContainer : Fragment;

  return (
    <Component>
      {isFetching ? (
        <LoadingIndicator className="p-2" />
      ) : (
        <>
          {content}
          {children}
        </>
      )}
    </Component>
  );
};
