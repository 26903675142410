import { useTranslation } from 'react-i18next';
import { WidgetSummary } from '@client/shared/toolkit';
import { DashboardWidgetProps } from '../WidgetDashboard';

export const DashboardWidgetKPI = (props: DashboardWidgetProps) => {
  const { widget } = props;
  const { t } = useTranslation();
  return (
    <WidgetSummary
      title={widget.title ? widget.title : t('dashboard.widget.kpi.title')}
      isLoading={false}
      data={[{ label: 'Label', value: 'Value' }, { label: 'Label', value: 'Value' }, { label: 'Label', value: 'Value' }, { label: 'Label', value: 'Value' }, { label: 'Label', value: 'Value' }]}
    />
  );
};
