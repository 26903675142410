import { Navigate, Route, Routes } from 'react-router-dom';
import {
  HelpRoute,
  SettingsRoute,
  DevelopmentRoute,
  ProjectsRoute,
  PortfoliosRoute,
  DatevCallbackRoute,
} from './routes';
import { ProtectedRoute, RequireAuth } from '@client/shared/permissions';
import { ProjectModule, MultiProjectModule } from '@client/project/shell';
import { LoginRoute, LogoutRoute, LogoutSessionsRoute, SsoRoute } from '@client/main/auth';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { PortfolioModule } from '@client/portfolio/shell';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={ROUTES_CONFIG.PROJECTS.path} />} />

      <Route
        path={`${ROUTES_CONFIG.DATEV_CALLBACK.path}/*`}
        element={
          <RequireAuth>
            <ProtectedRoute routeConfig={ROUTES_CONFIG.DATEV_CALLBACK}>
              <DatevCallbackRoute />
            </ProtectedRoute>
          </RequireAuth>
        }
      />

      <Route path="/auth/*" element={<LoginRoute />} />
      <Route path="/sso/*" element={<SsoRoute />} />

      <Route path={ROUTES_CONFIG.DEVELOPMENT.path} element={<DevelopmentRoute />} />

      {/* <Route path="/preview" element={<PreviewRoute />} /> */}

      {/* Authentication required */}
      <Route
        path={`${ROUTES_CONFIG.PROJECTS.path}/*`}
        element={
          <RequireAuth>
            <ProtectedRoute routeConfig={ROUTES_CONFIG.PROJECTS}>
              <ProjectsRoute />
            </ProtectedRoute>
          </RequireAuth>
        }
      />
      {/* <Route path="/teams" element={<RequireAuth><TeamsRoute /></RequireAuth>} /> */}
      {/* <Route path="/templates" element={<RequireAuth><TemplatesRoute /></RequireAuth>} /> */}
      {/* <Route path="/applications" element={<RequireAuth><ApplicationsRoute /></RequireAuth>} /> */}

      <Route
        path={ROUTES_CONFIG.HELP.path}
        element={
          <RequireAuth>
            <HelpRoute />
          </RequireAuth>
        }
      />

      <Route path={`${ROUTES_CONFIG.SETTINGS.path}/*`}>
        <Route index element={<Navigate to={ROUTES_CONFIG.SETTINGS_ACCOUNT.path} replace={true} />} />
        <Route path="*" element={
          <RequireAuth>
            <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS}>
              <SettingsRoute />
            </ProtectedRoute>
          </RequireAuth>
        } />
      </Route>

      <Route
        path="/logout"
        element={
          <RequireAuth>
            <LogoutRoute />
          </RequireAuth>
        }
      />

      <Route
        path="/logoutsessions"
        element={
          <RequireAuth>
            <LogoutSessionsRoute />
          </RequireAuth>
        }
      />

      {/* project module */}
      <Route
        path={`${ROUTES_CONFIG.PROJECT.path}/:id/*`}
        element={
          <RequireAuth>
            <ProtectedRoute routeConfig={ROUTES_CONFIG.PROJECT}>
              <ProjectModule />
            </ProtectedRoute>
          </RequireAuth>
        }
      />

      {/* multi project module */}
      <Route
        path={`${ROUTES_CONFIG.MULTI_PROJECT.path}/:id/*`}
        element={
          <RequireAuth>
            <ProtectedRoute routeConfig={ROUTES_CONFIG.MULTI_PROJECT}>
              <MultiProjectModule />
            </ProtectedRoute>
          </RequireAuth>
        }
      />

      {/* portfolios module */}
      <Route
        path={`${ROUTES_CONFIG.PORTFOLIOS.path}/*`}
        element={
          <RequireAuth>
            <ProtectedRoute routeConfig={ROUTES_CONFIG.PORTFOLIOS}>
              <PortfoliosRoute />
            </ProtectedRoute>
          </RequireAuth>
        }
      />

      {/* portfolio module */}
      <Route
        path={`${ROUTES_CONFIG.PORTFOLIO.path}/:id/*`}
        element={
          <RequireAuth>
            <ProtectedRoute routeConfig={ROUTES_CONFIG.PORTFOLIO}>
              <PortfolioModule />
            </ProtectedRoute>
          </RequireAuth>
        }
      />

      {/* Catch all */}
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};
