import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, TextInput, FormField } from '@client/shared/toolkit';
import {
  useApiGetUserInvitationQuery,
  useApiPostLoginMutation,
  useApiPostAcceptUserInvitationMutation,
  useApiPostCheckSessionMutation,
  useApiGetChallengeQuery,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { RegisterFormValidationValues, RegisterFormValidationSchema } from './RegisterFormValidationSchema';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { EyeSlashIcon, EyeIcon } from '@heroicons/react/24/solid';

export const RegisterView = () => {
  const { t } = useTranslation();

  const [email, setEmailAddress] = useState('');
  const { token } = useParams<{ token: string }>();

  const navigate = useNavigate();

  const [register, registerState] = useApiPostAcceptUserInvitationMutation();
  const [loginCall, loginCallState] = useApiPostLoginMutation();
  const [callCheckSession, callCheckSessionState] = useApiPostCheckSessionMutation();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const {
    data: invitationData,
    isFetching,
    isError,
  } = useApiGetUserInvitationQuery({
    tokenId: token ?? '',
  });

  const { data: challengeResponse, isFetching: isFetchingChallenge } = useApiGetChallengeQuery(
    {
      returnUrl: '/',
    },
    {
      skip: window.location.host.split('.')[0] === 'probis',
    },
  );

  const { isLoading } = callCheckSessionState || loginCallState || registerState

  useEffect(() => {
    if (invitationData) {
      setEmailAddress(invitationData.email);
    }
  }, [invitationData]);

  if (isError) {
    navigate('/auth/login');
  }

  const handleSubmit = async (data: RegisterFormValidationValues) => {
    try {
      await register({
        body: {
          firstName: data.firstName,
          lastName: data.lastName,
          password: data.password,
        },
        tokenId: token ?? '',
      }).unwrap();

      if (challengeResponse?.challengeUrl) {
        window.location.href = challengeResponse.challengeUrl;
      } else {
        await doLogin(email, data.password);
      }
    } catch {
      // intentionally left blank
    }
  };

  const doLogin = useCallback(
    async (email: string, password: string) => {
      try {
        await loginCall({
          body: {
            password,
            email,
          },
        }).unwrap();
        await callCheckSession();
        navigate(ROUTES_CONFIG.PROJECTS.path);
      } catch {
        // intentionally left blank
      }
    },
    [callCheckSession, loginCall, navigate],
  );

  const defaultFormValues = { firstName: '', lastName: '', password: '', passwordConfirmation: '' };

  return (
    <>
      <div className="flex flex-row items-center justify-between mt-3 mb-6">
        <h1 className="text-4xl font-medium text-gray-600">{t('auth.registerTitle')}</h1>
      </div>
      <Form<RegisterFormValidationValues>
        onSubmit={handleSubmit}
        validationSchema={RegisterFormValidationSchema}
        defaultValues={defaultFormValues}
      >
        <div className="divide-gray-100 divide-y">
          <TextInput label={t('common.email')} value={email} disabled={true} />
          <FormField name="firstName">
            {(control) => (
              <TextInput label={t('common.firstName')} disabled={isLoading || isFetching} autoComplete="given-name" {...control} />
            )}
          </FormField>
          <FormField name="lastName">
            {(control) => (
              <TextInput label={t('common.lastName')} disabled={isLoading || isFetching} autoComplete="family-name" {...control} />
            )}
          </FormField>
          <FormField name="password">
            {(control) => (
              <div className="flex justify-between items-center bg-white" onBlur={() => setShowPassword(false)}>
                <TextInput
                  label={t('common.password')}
                  disabled={isLoading || isFetching}
                  inputType={showPassword ? 'text' : 'password'}
                  autoComplete="new-password"
                  className="w-full"
                  {...control}
                />
                <div
                  className="w-5 cursor-pointer mx-2"
                  onClick={() => setShowPassword(!showPassword)}
                  data-cy="button-show-password"
                >
                  {showPassword ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
                </div>
              </div>
            )}
          </FormField>
          <FormField name="passwordConfirmation">
            {(control) => (
              <div
                className="flex justify-between items-center bg-white"
                onBlur={() => setShowPasswordConfirmation(false)}
              >
                <TextInput
                  label={t('auth.passwordConfirmation')}
                  disabled={isLoading || isFetching}
                  inputType={showPasswordConfirmation ? 'text' : 'password'}
                  autoComplete="new-password"
                  className="w-full"
                  {...control}
                />
                <div
                  className="w-5 cursor-pointer mx-2"
                  onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                  data-cy="button-show-password"
                >
                  {showPasswordConfirmation ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
                </div>
              </div>
            )}
          </FormField>
        </div>

        <div className="pt-16 flex justify-between">
          <Button
            variant="secondary"
            onClick={(evt) => {
              evt.preventDefault();
              navigate('/auth/login');
            }}
          >
            {t('common.cancel')}
          </Button>

          <Button variant="primary" disabled={isLoading || isFetching || isFetchingChallenge} formSubmit={true}>
            {t('auth.registerAction')}
          </Button>
        </div>
      </Form>
    </>
  );
};
