import {
  BaseSelect,
  Form,
  FormField, SlideOver,
  TextInput,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import {
  WidgetDashboardEditFormProps,
  WidgetDashboardEditFormValidationSchema,
  WidgetDashboardEditFormValidationValues
} from './WidgetEditFormDefault';

export const WidgetEditFormCashOutPlan = (props: WidgetDashboardEditFormProps) => {
  const { buttons, widget, handleSubmit, sizeOptions, selectedSize } = props;
  const { t } = useTranslation();

  const defaultFormValues = useMemo(() => {
    return {
      title: widget?.widget.title ?? t('dashboard.widget.cashOutPlan.title'),
      size: selectedSize
    };
  }, [t, widget?.widget.title, selectedSize]);

  return (
    <Form<WidgetDashboardEditFormValidationValues>
      onSubmit={handleSubmit}
      validationSchema={WidgetDashboardEditFormValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
    >
      <SlideOver.Content className="p-8">
        <FormField name="title">
          {(control) => <TextInput label={t('dashboard.widget.cashOutPlan.edit.title')} {...control} />}
        </FormField>
        <FormField name="size">
          {(control) => <BaseSelect label={t('dashboard.widget.size')} options={sizeOptions} {...control} />}
        </FormField>
      </SlideOver.Content>
      {buttons}
    </Form>
  );
};
