import { LicenseFeature, ProbisPermission } from '@client/shared/api';
import { ReactElement, ReactNode } from 'react';

export type RouteConfig = {
  path: string
  name: string
  permissions: ProbisPermission[]
  licenses: LicenseFeature[]
  disabled: boolean // if route is generally disabled (if fallback route exist, redirect to fallback route)
  fallbackRoute?: string
}
export type RouteNavType = {
  label: string
  icon?: ReactElement | ReactNode | string
  iconPath?: string
  routeConfig: RouteConfig
  disabled?: boolean
}

export enum ROUTE_NAMES {
  SSO,
  DATEV_CALLBACK,
  MULTI_PROJECT,
  MULTI_PROJECT_DASHBOARD,
  MULTI_PROJECT_REPORTING,
  PROJECTS,
  PROJECTS_PROJECT_SETTINGS,
  PROJECTS_PROJECT_UPLOAD,
  PROJECT,
  PROJECT_DASHBOARD,
  PROJECT_DASHBOARD_SETTINGS,
  PROJECT_TAXONOMY,
  PROJECT_TAXONOMY_VIEW,
  PROJECT_TAXONOMY_PLOT_VIEW,
  PROJECT_CALCULATE,
  PROJECT_RENT,
  PROJECT_RENT_DEAL_VIEW,
  PROJECT_SELL,
  PROJECT_SELL_DEAL_VIEW,
  PROJECT_VARIANTS,
  PROJECT_REPORTING,
  PROJECT_CONTRACT,
  PROJECT_CONTRACT_VIEW,
  PROJECT_CONTRACT_EDIT,
  PROJECT_COMMITMENT_VIEW,
  PROJECT_CONTROL,
  PROJECT_INVOICE_VIEW,
  PROJECT_INVOICE_DOCUMENT_VIEW,
  PROJECT_INVOICE_ICS_VIEW,
  PROJECT_TASKS,
  PROJECT_PLAN,
  PROJECT_MANAGE,
  PROJECT_UPLOAD,
  REPORTING_BASE,
  REPORTING_COMPARISON,
  REPORTING_BUDGET,
  REPORTING_FORECAST,
  REPORTING_DETAILS,
  REPORTING_VARIATION,
  REPORTING_FORECAST_STEPS,
  REPORTING_CONTROLLING_REPORT,
  REPORTING_COST_BREAKDOWN_REPORT,
  REPORTING_RISK,
  REPORTING_CARBON_EMISSION_CHART,
  REPORTING_CONTRACT,
  REPORTING_BMW_CHANGE_MGMT,
  REPORTING_BMW_RISKS_OPS,
  REPORTING_BMW_SAZ,
  REPORTING_BMW_COST_WATERFALL,
  REPORTING_BMW_SUMMARY,
  REPORTING_REPORT_VIEW,
  REPORTING_CUSTOM_REPORT,
  SETTINGS,
  SETTINGS_ACCOUNT,
  SETTINGS_COMPANIES,
  SETTINGS_LOGIN_SECURITY,
  SETTINGS_COMPANY,
  SETTINGS_PARTNER_COMPANIES,
  SETTINGS_PARTNER_COMPANY_VIEW,
  // SETTINGS_TRUSTED_COMPANIES,
  SETTINGS_COMPANY_USERS,
  SETTINGS_TEAMS,
  SETTINGS_PERMISSIONS,
  SETTINGS_LICENSES,
  SETTINGS_ICS,
  SETTINGS_TEMPLATES,
  SETTINGS_WORKFLOWS,
  SETTINGS_CATALOGS,
  SETTINGS_USER_DEFINED_FIELDS,
  SETTINGS_CALCULATE_GROUPS,
  SETTINGS_EURIBOR_RATES,
  SETTINGS_DEFAULT_VALUES,
  SETTINGS_CONNECT,
  SETTINGS_CONNECT_DATEV,
  SETTINGS_CONNECT_DATEV_EDIT,
  SETTINGS_VERSIONS,
  HELP,
  DEVELOPMENT,
  COMPANY,
  CONTACTS,
  PORTFOLIOS,
  PORTFOLIO,
  PORTFOLIO_PROJECTING,
  PORTFOLIO_PROPERTY,
  PORTFOLIO_TASKS,
  PORTFOLIO_TIMELINE,
  PORTFOLIO_PROJECTING_REQUIREMENT_VIEW
}
type RoutesConfigType = Record<keyof typeof ROUTE_NAMES, RouteConfig>

/**
 * Config of all routes with their permissions and licenses
 * TODO correct permissions + licenses
 */
export const ROUTES_CONFIG: RoutesConfigType = {
  SSO: {
    name: 'sso',
    path: '/sso/*',
    permissions: [],
    licenses: [],
    disabled: false
  },
  DATEV_CALLBACK: {
    name: 'datev/callback',
    path: '/datev/callback',
    permissions: [],
    licenses: [],
    disabled: false
  },
  MULTI_PROJECT: {
    name: 'multi-project',
    path: '/multi-project',
    permissions: ['PROJECT_READ'],
    licenses: [],
    disabled: false
  },
  MULTI_PROJECT_DASHBOARD: {
    name: 'dashboard',
    path: '/multi-project/:id/dashboard',
    permissions: [],
    licenses: [],
    disabled: false
  },
  MULTI_PROJECT_REPORTING: {
    name: 'reporting',
    path: '/multi-project/:id/reporting',
    permissions: [],
    licenses: ['REPORTING'],
    disabled: true,
    fallbackRoute: '/dashboard'
  },
  PROJECTS: {
    name: 'projects',
    path: '/projects',
    permissions: [],
    licenses: [],
    disabled: false
  },
  PROJECTS_PROJECT_SETTINGS: {
    name: '/:id/settings',
    path: '/projects/:id/settings',
    permissions: ['PROJECT_READ'],
    licenses: [],
    disabled: false
  },
  PROJECTS_PROJECT_UPLOAD: {
    name: '/:tenantId/:projectId/upload',
    path: '/projects/:tenantId/:projectId/upload',
    permissions: ['PROJECT_READ'],
    licenses: [],
    disabled: false
  },
  PROJECT: {
    name: 'project',
    path: '/project',
    permissions: ['PROJECT_READ'],
    licenses: [],
    disabled: false
  },
  PROJECT_DASHBOARD_SETTINGS: {
    name: 'settings',
    path: '/project/:id/dashboard/settings',
    permissions: ['PROJECT_READ'],
    licenses: [],
    disabled: false
  },
  PROJECT_DASHBOARD: {
    name: 'dashboard',
    path: '/project/:id/dashboard',
    permissions: ['PROJECT_READ'],
    licenses: [],
    disabled: false
  },
  PROJECT_TAXONOMY: {
    name: 'taxonomy',
    path: '/project/:id/taxonomy',
    permissions: ['PLOT_AND_TAXONOMY_READ'],
    licenses: ['PLOT_AND_TAXONOMY'],
    disabled: false
  },
  PROJECT_TAXONOMY_VIEW: {
    name: '/:itemId/view',
    path: '/project/:id/taxonomy/:itemId/view',
    permissions: ['PLOT_AND_TAXONOMY_READ'],
    licenses: ['PLOT_AND_TAXONOMY'],
    disabled: false
  },
  PROJECT_TAXONOMY_PLOT_VIEW: {
    name: '/plot/:itemId/view',
    path: '/project/:id/taxonomy/plot/:itemId/view',
    permissions: ['PLOT_AND_TAXONOMY_READ'],
    licenses: ['PLOT_AND_TAXONOMY'],
    disabled: false
  },
  PROJECT_CALCULATE: {
    name: 'calculate',
    path: '/project/:id/calculate',
    permissions: [],
    licenses: ['CALCULATE'],
    disabled: false
  },
  PROJECT_RENT: {
    name: 'rent',
    path: '/project/:id/rent',
    permissions: ['RENT_READ'],
    licenses: ['RENT_AND_SELL'],
    disabled: false
  },
  PROJECT_RENT_DEAL_VIEW: {
    name: '/:dealId/view',
    path: '/project/:id/rent/:dealId/view',
    permissions: ['RENT_READ'],
    licenses: ['RENT_AND_SELL'],
    disabled: false
  },
  PROJECT_SELL: {
    name: 'sell',
    path: '/project/:id/sell',
    permissions: ['SELL_READ'],
    licenses: ['RENT_AND_SELL'],
    disabled: false
  },
  PROJECT_SELL_DEAL_VIEW: {
    name: '/:dealId/view',
    path: '/project/:id/sell/:dealId/view',
    permissions: ['SELL_READ'],
    licenses: ['RENT_AND_SELL'],
    disabled: false
  },
  PROJECT_VARIANTS: {
    name: 'variants',
    path: '/project/:id/variants',
    permissions: ['PROJECT_READ'],
    licenses: ['CALCULATE'],
    disabled: false
  },
  PROJECT_REPORTING: {
    name: 'reporting',
    path: '/project/:id/reporting',
    permissions: ['PROJECT_READ'],
    licenses: ['REPORTING'],
    disabled: false
  },
  PROJECT_CONTRACT: {
    name: 'contract',
    path: '/project/:id/contract',
    permissions: ['CONTRACT_READ'],
    licenses: ['CONTRACT_AND_CONTROL'],
    disabled: false
  },
  PROJECT_CONTRACT_VIEW: {
    name: '/:contractId/view',
    path: '/project/:id/contract/:contractId/view',
    permissions: ['CONTRACT_READ'],
    licenses: ['CONTRACT_AND_CONTROL'],
    disabled: false
  },
  PROJECT_CONTRACT_EDIT: {
    name: '/:contractId/edit',
    path: '/project/:id/contract/:contractId/edit',
    permissions: ['CONTRACT_READ'],
    licenses: ['CONTRACT_AND_CONTROL'],
    disabled: false
  },
  PROJECT_COMMITMENT_VIEW: {
    name: '/commitment/:commitmentId/view',
    path: '/project/:id/contract/commitment/:commitmentId/view',
    permissions: ['CONTRACT_READ'],
    licenses: ['CONTRACT_AND_CONTROL'],
    disabled: false
  },
  PROJECT_CONTROL: {
    name: 'control',
    path: '/project/:id/control',
    permissions: ['INVOICE_READ'],
    licenses: ['CONTRACT_AND_CONTROL'],
    disabled: false
  },
  PROJECT_INVOICE_VIEW: {
    name: '/invoice/:invoiceId/view',
    path: '/project/:id/control/invoice/:invoiceId/view',
    permissions: ['INVOICE_READ'],
    licenses: ['CONTRACT_AND_CONTROL'],
    disabled: false
  },
  PROJECT_INVOICE_DOCUMENT_VIEW: {
    name: '/invoice-document/:documentId/view',
    path: '/project/:id/control/invoice-document/:documentId/view',
    permissions: ['INVOICE_READ'],
    licenses: ['CONTRACT_AND_CONTROL'],
    disabled: false
  },
  PROJECT_INVOICE_ICS_VIEW: {
    name: '/invoice/:invoiceId/ics/view',
    path: '/project/:id/control/invoice/:invoiceId/ics/view',
    permissions: ['INVOICE_READ'],
    licenses: ['CONTRACT_AND_CONTROL'],
    disabled: false
  },
  PROJECT_TASKS: {
    name: 'tasks',
    path: '/project/:id/tasks',
    permissions: ['PROJECT_READ'],
    licenses: ['MANAGE'],
    disabled: false
  },
  PROJECT_PLAN: {
    name: 'plan',
    path: '/project/:id/plan',
    permissions: ['PROJECT_READ'],
    licenses: ['MANAGE'],
    disabled: true
  },
  PROJECT_MANAGE: {
    name: 'manage',
    path: '/project/:id/manage',
    permissions: ['PROJECT_READ'],
    licenses: ['MANAGE'],
    disabled: true
  },
  PROJECT_UPLOAD: {
    name: 'upload',
    path: '/project/:id/upload',
    permissions: [],
    licenses: [],
    disabled: true
  },
  REPORTING_BASE: {
    name: 'base',
    path: '/project/:id/reporting/base',
    permissions: ['PROJECT_READ'],
    licenses: ['REPORTING'],
    disabled: false
  },
  REPORTING_COMPARISON: {
    name: 'comparison',
    path: '/project/:id/reporting/comparison',
    permissions: ['PROJECT_READ'],
    licenses: ['REPORTING'],
    disabled: false
  },
  REPORTING_BUDGET: {
    name: 'budget',
    path: '/project/:id/reporting/budget',
    permissions: ['PROJECT_READ'],
    licenses: ['REPORTING'],
    disabled: false
  },
  REPORTING_FORECAST: {
    name: 'forecast',
    path: '/project/:id/reporting/forecast',
    permissions: ['PROJECT_READ'],
    licenses: ['REPORTING'],
    disabled: false
  },
  REPORTING_DETAILS: {
    name: 'details',
    path: '/project/:id/reporting/details',
    permissions: ['PROJECT_READ'],
    licenses: ['REPORTING'],
    disabled: false
  },
  REPORTING_VARIATION: {
    name: 'variation',
    path: '/project/:id/reporting/variation',
    permissions: ['PROJECT_READ'],
    licenses: ['REPORTING'],
    disabled: false
  },
  REPORTING_FORECAST_STEPS: {
    name: 'forecast-steps',
    path: '/project/:id/reporting/forecast-steps',
    permissions: ['PROJECT_READ'],
    licenses: ['REPORTING'],
    disabled: false
  },
  REPORTING_CONTROLLING_REPORT: {
    name: 'controlling-report',
    path: '/project/:id/reporting/controlling-report',
    permissions: ['PROJECT_READ'],
    licenses: ['REPORTING'],
    disabled: false
  },
  REPORTING_COST_BREAKDOWN_REPORT: {
    name: 'cost-breakdown-report',
    path: '/project/:id/reporting/cost-breakdown-report',
    permissions: ['PROJECT_READ'],
    licenses: ['REPORTING'],
    disabled: false
  },
  REPORTING_CARBON_EMISSION_CHART: {
    name: 'carbon-emission-chart',
    path: '/project/:id/reporting/carbon-emission-chart',
    permissions: ['PROJECT_READ'],
    licenses: ['REPORTING'],
    disabled: false
  },
  REPORTING_CONTRACT: {
    name: 'contract-report',
    path: '/project/:id/reporting/contract-report',
    permissions: ['PROJECT_READ'],
    licenses: ['REPORTING'],
    disabled: false
  },
  REPORTING_BMW_CHANGE_MGMT: {
    name: 'change-mgmt-report',
    path: '/project/:id/reporting/change-mgmt-report',
    permissions: ['PROJECT_READ'],
    licenses: ['BMW_REPORTING'],
    disabled: false
  },
  REPORTING_BMW_RISKS_OPS: {
    name: 'risk-ops-report',
    path: '/project/:id/reporting/risk-ops-report',
    permissions: ['PROJECT_READ'],
    licenses: ['BMW_REPORTING'],
    disabled: false
  },
  REPORTING_BMW_SAZ: {
    name: 'saz-report',
    path: '/project/:id/reporting/saz-report',
    permissions: ['PROJECT_READ'],
    licenses: ['BMW_REPORTING'],
    disabled: false
  },
  REPORTING_BMW_COST_WATERFALL: {
    name: 'cost-waterfall-report',
    path: '/project/:id/reporting/cost-waterfall-report',
    permissions: ['PROJECT_READ'],
    licenses: ['BMW_REPORTING'],
    disabled: false
  },
  REPORTING_BMW_SUMMARY: {
    name: 'summary-report',
    path: '/project/:id/reporting/summary-report',
    permissions: ['PROJECT_READ'],
    licenses: ['BMW_REPORTING'],
    disabled: false
  },
  REPORTING_RISK: {
    name: 'risk-report',
    path: '/project/:id/reporting/risk-report',
    permissions: ['PROJECT_READ'],
    licenses: ['BMW_REPORTING'],
    disabled: false
  },
  REPORTING_REPORT_VIEW: {
    name: '/:reportId/view',
    path: '/project/:id/reporting/:reportId/view',
    permissions: ['PROJECT_READ'],
    licenses: ['REPORTING'],
    disabled: false
  },
  REPORTING_CUSTOM_REPORT: {
    name: 'custom-report/:reportId/view',
    path: '/project/:id/reporting/custom-report/:reportId/view',
    permissions: ['PROJECT_READ'],
    licenses: ['REPORTING'],
    disabled: false
  },
  SETTINGS: {
    name: 'settings',
    path: '/settings',
    permissions: [],
    licenses: [],
    disabled: false
  },
  SETTINGS_ACCOUNT: {
    name: 'your-account',
    path: '/settings/your-account',
    permissions: [],
    licenses: [],
    disabled: false
  },
  SETTINGS_COMPANIES: {
    name: 'companies',
    path: '/settings/companies',
    permissions: [],
    licenses: [],
    disabled: false
  },
  SETTINGS_LOGIN_SECURITY: {
    name: 'login-security',
    path: '/settings/login-security',
    permissions: [],
    licenses: [],
    disabled: false
  },
  SETTINGS_COMPANY: {
    name: 'company',
    path: '/settings/company',
    permissions: ['TENANT_MANAGE'],
    licenses: [],
    disabled: false,
    fallbackRoute: '/settings'
  },
  SETTINGS_PARTNER_COMPANIES: {
    name: 'partner-companies',
    path: '/settings/partner-companies',
    permissions: ['TENANT_MANAGE'],
    licenses: [],
    disabled: false,
    fallbackRoute: '/settings'
  },
  SETTINGS_PARTNER_COMPANY_VIEW: {
    name: '/:companyId/view',
    path: '/settings/partner-companies/:companyId/view',
    permissions: ['TENANT_MANAGE'],
    licenses: [],
    disabled: false,
    fallbackRoute: '/settings'
  },
  /* SETTINGS_TRUSTED_COMPANIES: {
    name: 'trusted-companies',
    path: '/settings/trusted-companies',
    permissions: ['TENANT_MANAGE'],
    licenses: [],
    disabled: false,
    fallbackRoute: '/settings'
  }, */
  SETTINGS_COMPANY_USERS: {
    name: 'company-users',
    path: '/settings/company-users',
    permissions: ['TENANT_MANAGE'],
    licenses: [],
    disabled: false,
    fallbackRoute: '/settings'
  },
  SETTINGS_TEAMS: {
    name: 'teams',
    path: '/settings/teams',
    permissions: ['TENANT_MANAGE'],
    licenses: [],
    disabled: false,
    fallbackRoute: '/settings'
  },
  SETTINGS_PERMISSIONS: {
    name: 'permissions',
    path: '/settings/permissions',
    permissions: ['TENANT_MANAGE'],
    licenses: [],
    disabled: false,
    fallbackRoute: '/settings'
  },
  SETTINGS_LICENSES: {
    name: 'licenses',
    path: '/settings/licenses',
    permissions: ['TENANT_MANAGE'],
    licenses: [],
    disabled: false,
    fallbackRoute: '/settings'
  },
  SETTINGS_ICS: {
    name: 'ics',
    path: '/settings/ics',
    permissions: ['TENANT_MANAGE'],
    licenses: [],
    disabled: false,
    fallbackRoute: '/settings'
  },
  SETTINGS_TEMPLATES: {
    name: 'templates',
    path: '/settings/templates',
    permissions: ['TENANT_MANAGE'],
    licenses: [],
    disabled: false,
    fallbackRoute: '/settings'
  },
  SETTINGS_WORKFLOWS: {
    name: 'workflows',
    path: '/settings/workflows',
    permissions: ['TENANT_MANAGE'],
    licenses: [],
    disabled: false,
    fallbackRoute: '/settings'
  },
  SETTINGS_CATALOGS: {
    name: 'catalogs',
    path: '/settings/catalogs',
    permissions: ['TENANT_MANAGE'],
    licenses: [],
    disabled: false,
    fallbackRoute: '/settings'
  },
  SETTINGS_USER_DEFINED_FIELDS: {
    name: 'user-defined-fields',
    path: '/settings/user-defined-fields',
    permissions: ['TENANT_MANAGE'],
    licenses: [],
    disabled: false,
    fallbackRoute: '/settings'
  },
  SETTINGS_CALCULATE_GROUPS: {
    name: 'calculate-groups',
    path: '/settings/calculate-groups',
    permissions: ['TENANT_MANAGE'],
    licenses: [],
    disabled: false,
    fallbackRoute: '/settings'
  },
  SETTINGS_EURIBOR_RATES: {
    name: 'euribor-rates',
    path: '/settings/euribor-rates',
    permissions: ['TENANT_MANAGE'],
    licenses: [],
    disabled: false,
    fallbackRoute: '/settings'
  },
  SETTINGS_DEFAULT_VALUES: {
    name: 'default-values',
    path: '/settings/default-values',
    permissions: ['TENANT_MANAGE'],
    licenses: [],
    disabled: false,
    fallbackRoute: '/settings'
  },
  SETTINGS_CONNECT: {
    name: 'connect',
    path: '/settings/connect',
    permissions: ['TENANT_MANAGE'],
    licenses: [],
    disabled: false,
    fallbackRoute: '/settings'
  },
  SETTINGS_CONNECT_DATEV: {
    name: 'datev',
    path: '/settings/connect/datev',
    permissions: ['TENANT_MANAGE'],
    licenses: [],
    disabled: false,
    fallbackRoute: '/settings/connect'
  },
  SETTINGS_CONNECT_DATEV_EDIT: {
    name: 'datev/:id/edit',
    path: '/settings/connect/datev/:id/edit',
    permissions: ['TENANT_MANAGE'],
    licenses: [],
    disabled: false,
    fallbackRoute: '/settings/connect'
  },
  SETTINGS_VERSIONS: {
    name: 'versions',
    path: '/settings/versions',
    permissions: [],
    licenses: [],
    disabled: false,
    fallbackRoute: '/settings'
  },
  HELP: {
    name: 'help',
    path: '/help',
    permissions: [],
    licenses: [],
    disabled: false
  },
  DEVELOPMENT: {
    name: 'development',
    path: '/development',
    permissions: [],
    licenses: [],
    disabled: false
  },
  COMPANY: {
    name: 'company',
    path: '/company',
    permissions: [],
    licenses: [],
    disabled: true
  },
  CONTACTS: {
    name: 'contact',
    path: '/contact',
    permissions: [],
    licenses: [],
    disabled: true
  },
  PORTFOLIOS: {
    name: 'portfolios',
    path: '/portfolios',
    permissions: [],
    licenses: [],
    disabled: false
  },
  PORTFOLIO: {
    name: 'portfolio',
    path: '/portfolio',
    permissions: [],
    licenses: [],
    disabled: false
  },
  PORTFOLIO_PROJECTING: {
    name: 'projecting',
    path: '/portfolio/:id/projecting',
    permissions: [],
    licenses: [],
    disabled: false
  },
  PORTFOLIO_PROJECTING_REQUIREMENT_VIEW: {
    name: '/:clusterItemId/:requirementId/view',
    path: '/portfolio/:id/projecting/:clusterItemId/:requirementId/view',
    permissions: [],
    licenses: [],
    disabled: false
  },
  PORTFOLIO_PROPERTY: {
    name: 'property',
    path: '/portfolio/:id/property',
    permissions: [],
    licenses: [],
    disabled: false
  },
  PORTFOLIO_TASKS: {
    name: 'tasks',
    path: '/portfolio/:id/tasks',
    permissions: [],
    licenses: [],
    disabled: false
  },
  PORTFOLIO_TIMELINE: {
    name: 'timeline',
    path: '/portfolio/:id/timeline',
    permissions: [],
    licenses: [],
    disabled: true
  }
}
