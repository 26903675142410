import { fork, put, take, takeEvery } from 'redux-saga/effects';
import { appError, startAppInit, finishAppInit, createAppError, setSession, settings } from '@client/shared/store';
import { api } from '@client/shared/api';
import toast from 'react-hot-toast';
import { getCookie } from 'typescript-cookie';
import { i18n } from '@client/shared/utilities';
import { ACCESS_TOKEN_COOKIE_NAME, REFRESH_TOKEN_COOKIE_NAME } from '@client/main/auth';

/**
 * The main method coordinating the application "startup".
 * If you need to execute any logic before the user can actually do something,
 * this is the right place.
 */
function* initializeApp(): Generator {
  yield take(startAppInit);

  try {
    // try to hydrate a session cookie (if exists)
    //const sessionId = getCookie('session_id');
    const token = getCookie(ACCESS_TOKEN_COOKIE_NAME);
    const refreshToken = getCookie(REFRESH_TOKEN_COOKIE_NAME);

    let checkSessionResult;

    if (token) {
      // change app state to reflect the possible session id
      yield put(
        setSession({
          sessionId: token || '',
          refreshToken: refreshToken || '',
        }),
      );

      // // check if the session is valid on the server (ignore cache!)
      yield put(
        api.endpoints.apiPostCheckSession.initiate(undefined, {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }) as any,
      );

      // wait for the result of the call, depending on result we might need to check for SSO
      checkSessionResult = yield take([
        api.endpoints.apiPostCheckSession.matchFulfilled,
        api.endpoints.apiPostCheckSession.matchRejected,
      ]);
    } else if (refreshToken) {
      yield put(
        api.endpoints.apiPostRefreshToken.initiate(undefined, {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }) as any,
      );
      yield take([api.endpoints.apiPostRefreshToken.matchFulfilled, api.endpoints.apiPostRefreshToken.matchRejected]);
    }

    const hasTenantSubdomain = window.location.hostname.split('.')[0] !== 'probis';
    const isDevMode = settings.devMode;
    const isSsoPath = window.location.pathname.includes('/sso');
    if (
      isDevMode &&
      hasTenantSubdomain &&
      !isSsoPath &&
      ((!token && !refreshToken) || api.endpoints.apiPostCheckSession.matchRejected(checkSessionResult))
    ) {
      // If there is no session, request a challenge to check if the tenant has a Sso realm configured
      yield put(
        api.endpoints.apiGetChallenge.initiate(
          {
            returnUrl: window.location.pathname,
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as any,
      );
      yield take([api.endpoints.apiGetChallenge.matchFulfilled, api.endpoints.apiGetChallenge.matchRejected]);
    } else {
      // notify that the app is ready
      yield put(finishAppInit());
    }
  } catch (err) {
    yield put(appError(createAppError(err)));
  }
}

function* onPasswordChange() {
  yield takeEvery([api.endpoints.apiPostUpdatePassword.matchFulfilled], function* () {
    toast.success(i18n.t('app.notificationPasswordChanged'));
    yield;
  });

  yield takeEvery([api.endpoints.apiPostUpdatePassword.matchRejected], function* () {
    toast.error(i18n.t('app.notificationPasswordChangeFailed'));
    yield;
  });
}

export function* appSaga() {
  yield fork(initializeApp);
  yield fork(onPasswordChange);
}
