import cn from 'classnames';
import React, { ReactElement, ReactNode, useRef } from 'react';
import { DragScrollContainer } from '../DragScrollContainer';

export type WizardSelectListProps = {
  className?: string;
  items: WizardSelectListItemProps[];
  grid?: boolean;
  gridCols?: string;
};

export const WizardSelectList = (props: WizardSelectListProps) => {
  const { items, className, grid = false, gridCols = 'grid-cols-4' } = props;
  const innerRef = useRef<HTMLDivElement>(null);

  if (!grid) {
    return (
      <DragScrollContainer
        innerRef={innerRef}
        horizontal
        className={cn('flex gap-[30px] w-full hide-scrollbars', className)}
      >
        {items.length > 0 &&
          items.map((item, i) => {
            return <WizardSelectListItem key={`wizard-select-item-${i}`} {...item} />;
          })}
      </DragScrollContainer>
    );
  }

  return (
    <div className={cn('w-full grid gap-5', gridCols, className)}>
      {items.length > 0 &&
        items.map((item, i) => {
          return <WizardSelectListItem key={`wizard-select-item-${i}`} {...item} />;
        })}
    </div>
  );
};

export type WizardSelectListItemProps = {
  title: string;
  icon: ReactNode | string;
  subtitle?: string;
  className?: string;
  disabled?: boolean;
  handleClick?: () => void;
  active?: boolean;
  iconSize?: string;
  inBox?: boolean;
};
export const WizardSelectListItem = (props: WizardSelectListItemProps) => {
  const {
    title,
    icon,
    subtitle,
    className,
    disabled = false,
    handleClick,
    active = false,
    iconSize,
    inBox = false
  } = props;

  return (
    <div
      className={cn(
        'overflow-hidden flex-none justify-self-center w-full max-w-[160px]',
        {
          'opacity-40': disabled,
          'cursor-pointer': handleClick,
        },
        className,
      )}
      onClick={handleClick}
    >
      <div
        className={cn(
          'bg-white rounded-2xl flex flex-col items-center justify-center border-2 transition-colors duration-300 w-full aspect-square',
          {
            'border-slate-200': !active,
            'border-slate-400': active,
            'hover:border-slate-400': !disabled,
            'p-12': !iconSize,
          },
        )}
      >
        {icon ? React.cloneElement(icon as ReactElement, { className: iconSize ?? 'w-full h-full' }) : ''}
        {inBox && (
          <div className="w-full mt-2 text-center overflow-hidden flex flex-col gap-0.5 px-2">
            <span className="text-sm font-bold">{title}</span>
            {subtitle && <span className="text-xs text-gray-500">{subtitle}</span>}
          </div>
        )}
      </div>
      {!inBox && (
        <div className="w-full mt-5 text-center overflow-hidden flex flex-col gap-0.5">
          <span className="text-[15px] font-bold">{title}</span>
          {subtitle && <span className="text-xs text-gray-500">{subtitle}</span>}
        </div>
      )}
    </div>
  );
};
