import React, { RefObject, useEffect, useMemo, useState } from 'react';
import {
  AiEvalValuesReadModel,
  SelectCompanyBranchReadModel,
  ShortContractReadModel,
  useApiGetProjectSelectCompanyBranchesQuery,
} from '@client/shared/api';
import {
  ComboSelect,
  ComboSelectAdditionalOption,
  ComboSelectOption,
  ContactIcon,
  DocumentViewerFileDataGroup,
  DocumentViewerFileDataInlineEdit,
  DocumentViewerFileDataSet,
  FormField,
  FormRefHandle,
  LoadingIndicator
} from '@client/shared/toolkit';
import { InvoiceCreateFormValidationValues } from '..';
import { BranchData } from '../InvoiceDocumentReview';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { getCompanyBranches, useLoadedProjectId } from '@client/project/store';
import { useTranslation } from 'react-i18next';
import { AddCompanyModal } from '../../AddCompanyModal';

interface InvoiceContractorAndClientEditProps {
  labelContractor: string;
  labelClient: string;
  formRef?: RefObject<FormRefHandle<InvoiceCreateFormValidationValues>> | null;
  originalContractor: SelectCompanyBranchReadModel | null;
  originalClient: SelectCompanyBranchReadModel | null;
  branches?: SelectCompanyBranchReadModel[];
  selectedContract?: ShortContractReadModel | null;
  canEdit: boolean;
  updateEditMode?: (isEditMode: boolean) => void;
  updateUnsavedData?: (formField: string, unsaved: boolean) => void;
  invoiceDocumentFileData?: AiEvalValuesReadModel | null;
}

export const InvoiceContractorAndClientEdit = (props: InvoiceContractorAndClientEditProps) => {
  const {
    labelContractor,
    labelClient,
    formRef,
    originalContractor,
    originalClient,
    branches,
    selectedContract,
    canEdit,
    updateEditMode,
    updateUnsavedData,
    invoiceDocumentFileData,
  } = props;

  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const canWrite = useValidateProjectPermission(['INVOICE_WRITE'], loadedProjectId ?? '');

  // selected contractor from branches list by user
  const [selectedContractor, setSelectedContractor] = useState<SelectCompanyBranchReadModel | null>(null);
  // selected client from branches list by user
  const [selectedClient, setSelectedClient] = useState<SelectCompanyBranchReadModel | null>(null);

  const [createCompanyField, setCreateCompanyField] = useState<'contractor' | 'client'>('contractor');
  const [createdCompany, setCreatedCompany] = useState<string | undefined>(undefined);
  const [isOpenCreateCompanyModal, setIsOpenCreateCompanyModal] = useState(false);
  const [allowChangeMode, setAllowChangeMode] = useState(true);

  const { data: loadedBranches, isFetching } = useApiGetProjectSelectCompanyBranchesQuery(
    {
      projectId: loadedProjectId ?? '',
    },
    {
      skip: !loadedProjectId || !!branches,
    },
  );

  const companiesOptions = useMemo(() => {
    const currentBranches = branches ? branches : loadedBranches;
    return getCompanyBranches(currentBranches);
  }, [branches, loadedBranches]);

  useEffect(() => {
    setSelectedContractor(originalContractor);
  }, [originalContractor]);

  useEffect(() => {
    setSelectedClient(originalClient);
  }, [originalClient]);

  useEffect(() => {
    if (createdCompany && formRef?.current && (branches?.length || loadedBranches?.length)) {
      const foundBranch = branches?.find((branch) => branch.id === createdCompany || branch.companyId === createdCompany) ?? loadedBranches?.find((branch) => branch.id === createdCompany || branch.companyId === createdCompany);
      if (foundBranch) {
        if (createCompanyField === 'contractor') {
          setSelectedContractor(foundBranch);
          formRef?.current?.setValue('invoicingPartyId', foundBranch.id);
          if (updateUnsavedData) {
            updateUnsavedData('invoicingPartyId', originalContractor?.id === foundBranch?.id);
          }
        } else {
          setSelectedClient(foundBranch);
          formRef?.current?.setValue('invoiceRecipientId', foundBranch.id);
          if (updateUnsavedData) {
            updateUnsavedData('invoiceRecipientId', originalContractor?.id === foundBranch?.id);
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdCompany, loadedBranches, branches]);

  // contractor and client are coming from contract if fileData
  useEffect(() => {
    if (selectedContract) {
      if (selectedContract.contractorId) {
        const foundContractor = branches?.find((branch) => branch.id === selectedContract.contractorId);
        setSelectedContractor(foundContractor ?? null);
      } else {
        setSelectedContractor(null);
      }
      if (selectedContract.clientId) {
        const foundClient = branches?.find((branch) => branch.id === selectedContract.clientId);
        setSelectedClient(foundClient ?? null);
      } else {
        setSelectedClient(null);
      }
    }
  }, [selectedContract, branches]);

  // const { setUnsavedData } = useContext(InvoiceEditContext);

  return (
    <>
      {isFetching && <LoadingIndicator text={t('projectControl.companiesLoading') ?? ''} mode="overlay" />}
      {/*--------- CONTRACTOR -------*/}
      <DocumentViewerFileDataGroup className="relative" divider={false}>
        <DocumentViewerFileDataInlineEdit
          updateEditMode={updateEditMode}
          allowChangeMode={allowChangeMode}
          toggleContent={
            canEdit ? (
              <FormField name="invoicingPartyId">
                {(control) => (
                  <ComboSelect
                    label={labelContractor}
                    icon={<ContactIcon className="h-6 w-6" />}
                    pageOptions={true}                    
                    options={companiesOptions}
                    additionalOptionOnClick={() => {
                      setIsOpenCreateCompanyModal(true);
                      setCreateCompanyField('contractor');
                    }}
                    additionalOption={
                      <ComboSelectAdditionalOption label={t('projectControl.createNewContractor')} />
                    }
                    disabled={!canWrite}
                    {...control}
                    nullable
                    handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                    onChange={(branchId) => {
                      const foundContractor = branches?.find((branch) => branch.id === branchId);
                      setSelectedContractor(foundContractor ?? null);
                      control.onChange(branchId);
                      formRef?.current?.setValue('contractId', null);
                      if (updateUnsavedData) {
                        updateUnsavedData('invoicingPartyId', originalContractor?.id === foundContractor?.id);
                      }
                    }}
                  />
                )}
              </FormField>
            ) : undefined
          }
        >
          <div className="divide-y-2">
            {selectedContractor ? (
              <BranchData
                branch={selectedContractor}
                label={labelContractor}
                branchNameClassName={originalContractor?.id !== selectedContractor?.id ? 'text-secondary' : undefined}
              />
            ) : (
              <DocumentViewerFileDataSet label={labelContractor}>
                <span className="italic text-sm text-gray-500">-</span>
              </DocumentViewerFileDataSet>
            )}
          </div>
        </DocumentViewerFileDataInlineEdit>
      </DocumentViewerFileDataGroup>

      {/*--------- CLIENT -------*/}
      <DocumentViewerFileDataGroup className="relative" divider={false}>
        <DocumentViewerFileDataInlineEdit
          updateEditMode={updateEditMode}
          allowChangeMode={allowChangeMode}
          toggleContent={
            canEdit ? (
              <FormField name="invoiceRecipientId">
                {(control) => (
                  <ComboSelect
                    label={labelClient}
                    icon={<ContactIcon className="h-6 w-6" />}
                    pageOptions={true}                    
                    options={companiesOptions}
                    additionalOptionOnClick={() => {
                      setIsOpenCreateCompanyModal(true);
                      setCreateCompanyField('client');
                    }}
                    additionalOption={
                      <ComboSelectAdditionalOption label={t('projectControl.createNewContractor')} />
                    }
                    disabled={!canWrite}
                    {...control}
                    nullable
                    handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                    onChange={(branchId) => {
                      const foundContract = branches?.find((branch) => branch.id === branchId);
                      setSelectedClient(foundContract ?? null);
                      control.onChange(branchId);
                      formRef?.current?.setValue('contractId', null);
                      if (updateUnsavedData) {
                        updateUnsavedData('invoiceRecipientId', originalContractor?.id === foundContract?.id);
                      }
                    }}
                  />
                )}
              </FormField>
            ) : undefined
          }
        >
          <div className="divide-y-2">
            {selectedClient ? (
              <BranchData
                branch={selectedClient}
                label={labelClient}
                branchNameClassName={originalClient?.id !== selectedClient?.id ? 'text-secondary' : undefined}
              />
            ) : (
              <DocumentViewerFileDataSet label={labelClient}>
                <span className="italic text-sm text-gray-500">-</span>
              </DocumentViewerFileDataSet>
            )}
          </div>
        </DocumentViewerFileDataInlineEdit>
      </DocumentViewerFileDataGroup>
      <AddCompanyModal
        isOpen={isOpenCreateCompanyModal}
        onClose={(branchId) => {
          setCreatedCompany(branchId);
          setIsOpenCreateCompanyModal(false);
        }}
        field={createCompanyField}
        invoiceDocumentFileData={invoiceDocumentFileData}
      />
    </>
  );
};
