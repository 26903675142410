import { useTranslation } from 'react-i18next';
import { WidgetContainer, WidgetContainerContent, WidgetContainerTitle } from '@client/shared/toolkit';
import { DashboardWidgetProps } from '../WidgetDashboard';

export const DashboardWidgetTasks = (props: DashboardWidgetProps) => {
  const { widget } = props;
  const { t } = useTranslation();
  return (
    <WidgetContainer>
      <WidgetContainerTitle>{widget.title ? widget.title : t('dashboard.widget.tasks.title')}</WidgetContainerTitle>
      <WidgetContainerContent className="flex-1 overflow-y-auto">
        Tasks
      </WidgetContainerContent>
    </WidgetContainer>
  );
};
