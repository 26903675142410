import * as yup from 'yup';
import { InferType } from 'yup';
import { WidgetDashboardEditFormProps } from './WidgetEditFormDefault';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  AddButton,
  BaseSelect,
  BaseSelectOption,
  Form,
  FormField,
  FormRefHandle,
  NumberInput,
  SlideOver,
  SlideOverTitle,
  TextInput,
  TrashIcon,
} from '@client/shared/toolkit';
import { LightColor, MetricType, UpsertTrafficLightPayload } from '@client/shared/api';
import { getTrafficLightIconByName } from '../Widgets';

export const WidgetDashboardEditFormTrafficLightsValidationSchema = yup.object({
  title: yup.string().required('validation.required'),
  size: yup.string().required('validation.required'),
  trafficLights: yup
    .array()
    .of(yup.mixed<UpsertTrafficLightPayload>().required('validation.required'))
    .min(1)
    .required('validation.required'),
});

export type WidgetDashboardEditFormTrafficLightsValidationValues = InferType<
  typeof WidgetDashboardEditFormTrafficLightsValidationSchema
>;

const DEFAULT_TRAFFIC_LIGHT: UpsertTrafficLightPayload = {
  name: '',
  factor: 0.1,
  color: 'Green',
  metricType: 'OverallStatus',
};

export const WidgetEditFormTrafficLights = (props: WidgetDashboardEditFormProps) => {
  const { buttons, widget, handleSubmit, selectedSize } = props;
  const { t } = useTranslation();

  const formRef = useRef<FormRefHandle<WidgetDashboardEditFormTrafficLightsValidationValues>>();
  const [trafficLights, setTrafficLights] = useState<UpsertTrafficLightPayload[]>([DEFAULT_TRAFFIC_LIGHT]);

  useEffect(() => {
    setTrafficLights(widget?.widget.trafficLights ?? [DEFAULT_TRAFFIC_LIGHT]);
  }, [widget?.widget.trafficLights]);

  const defaultFormValues = useMemo(() => {
    return {
      title: widget?.widget.title ?? t('dashboard.widget.trafficLight.title'),
      size: selectedSize,
      trafficLights: widget?.widget.trafficLights ?? [DEFAULT_TRAFFIC_LIGHT],
    };
  }, [t, widget?.widget.title, selectedSize, widget?.widget.trafficLights]);

  const onAdd = () => {
    if (trafficLights.length < 3) {
      const copy = [...trafficLights];
      copy.push(DEFAULT_TRAFFIC_LIGHT);
      setTrafficLights(copy);
      formRef.current?.setValue('trafficLights', copy);
    }
  };

  const onUpdateTrafficLight = (data: UpsertTrafficLightPayload, index: number) => {
    if (trafficLights[index]) {
      const copy = [...trafficLights];
      copy[index] = data;
      setTrafficLights(copy);
      formRef.current?.setValue('trafficLights', copy);
    }
  };

  const onDeleteTrafficLight = (index: number) => {
    if (trafficLights[index]) {
      const copy = [...trafficLights];
      copy.splice(index, 1);
      setTrafficLights(copy);
      formRef.current?.setValue('trafficLights', copy);
    }
  };

  return (
    <Form<WidgetDashboardEditFormTrafficLightsValidationValues>
      onSubmit={handleSubmit}
      validationSchema={WidgetDashboardEditFormTrafficLightsValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
      ref={formRef}
    >
      <SlideOver.Content className="p-8">
        <FormField name="title">
          {(control) => <TextInput label={t('dashboard.widget.trafficLight.title')} {...control} />}
        </FormField>

        {trafficLights.map((trafficLight, index) => (
          <TrafficLightForm
            key={`traffic-light-form-${index}`}
            trafficLight={trafficLight}
            index={index}
            onUpdateTrafficLight={(data: UpsertTrafficLightPayload) => onUpdateTrafficLight(data, index)}
            canDelete={trafficLights.length > 1}
            onDelete={() => onDeleteTrafficLight(index)}
          />
        ))}
        {trafficLights.length < 3 && (
          <div className="flex w-full justify-end items-center z-50 relative">
            <AddButton onClick={onAdd} className="-mt-4" />
          </div>
        )}
      </SlideOver.Content>
      {buttons}
    </Form>
  );
};

export interface TrafficLightFormProps {
  trafficLight: UpsertTrafficLightPayload;
  onUpdateTrafficLight: (data: UpsertTrafficLightPayload) => void;
  index: number;
  canDelete: boolean;
  onDelete: () => void;
}

export const TrafficLightForm = (props: TrafficLightFormProps) => {
  const { trafficLight, index, canDelete, onDelete, onUpdateTrafficLight } = props;
  const { t } = useTranslation();

  const trafficLightColorOptions: BaseSelectOption[] = useMemo(() => {
    return [
      {
        value: 'Green',
        label: t('dashboard.widget.trafficLight.trafficLightColorGreen'),
      },
      {
        value: 'Amber',
        label: t('dashboard.widget.trafficLight.trafficLightColorYellow'),
      },
      {
        value: 'Red',
        label: t('dashboard.widget.trafficLight.trafficLightColorRed'),
      },
    ];
  }, [t]);

  const trafficLightIconOptions: BaseSelectOption[] = useMemo(() => {
    return [
      {
        value: 'OverallStatus',
        label: t('dashboard.widget.trafficLight.trafficLightIconOverallStatus'),
        info: getTrafficLightIconByName('OverallStatus')
      },
      {
        value: 'ProjectOrganization',
        label: t('dashboard.widget.trafficLight.trafficLightIconProjectOrganization'),
        info: getTrafficLightIconByName('ProjectOrganization')
      },
      {
        value: 'Risks',
        label: t('dashboard.widget.trafficLight.trafficLightIconRisks'),
        info: getTrafficLightIconByName('Risks')
      },
      {
        value: 'Costs',
        label: t('dashboard.widget.trafficLight.trafficLightIconCosts'),
        info: getTrafficLightIconByName('Costs')
      },
      {
        value: 'Deadlines',
        label: t('dashboard.widget.trafficLight.trafficLightIconDeadlines'),
        info: getTrafficLightIconByName('Deadlines')
      },
      {
        value: 'Qualities',
        label: t('dashboard.widget.trafficLight.trafficLightIconQualities'),
        info: getTrafficLightIconByName('Qualities')
      },
      {
        value: 'Awarding',
        label: t('dashboard.widget.trafficLight.trafficLightIconAwarding'),
        info: getTrafficLightIconByName('Awarding')
      },
      {
        value: 'Marketing',
        label: t('dashboard.widget.trafficLight.trafficLightIconMarketing'),
        info: getTrafficLightIconByName('Marketing')
      },
      {
        value: 'PlanningStatus',
        label: t('dashboard.widget.trafficLight.trafficLightIconPlanningStatus'),
        info: getTrafficLightIconByName('PlanningStatus')
      },
      {
        value: 'ConstructionStatus',
        label: t('dashboard.widget.trafficLight.trafficLightIconConstructionStatus'),
        info: getTrafficLightIconByName('ConstructionStatus')
      },
    ];
  }, [t]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <SlideOverTitle title={`${t('dashboard.widget.trafficLight.trafficLight')} ${index + 1}`} />
        {canDelete && <TrashIcon className="text-gray-500 w-5 h-5 mt-4 cursor-pointer" onClick={onDelete} />}
      </div>
      <TextInput
        label={t('dashboard.widget.trafficLight.trafficLightTitle')}
        value={trafficLight.name}
        onChange={(val) => {
          const copy = { ...trafficLight };
          copy.name = val ?? 0.1;
          onUpdateTrafficLight(copy);
        }}
      />
      <NumberInput
        label={t('dashboard.widget.trafficLight.trafficLightFactor')}
        value={trafficLight.factor}
        onChange={(val) => {
          const copy = { ...trafficLight };
          copy.factor = val ?? 0.1;
          onUpdateTrafficLight(copy);
        }}
        decimalScale={2}
        min={0}
        max={1}
        maxLength={3}
        step={0.1}
      />
      <BaseSelect
        label={t('dashboard.widget.trafficLight.trafficLightIcon')}
        value={trafficLight.metricType}
        options={trafficLightIconOptions}
        onChange={(val) => {
          const copy = { ...trafficLight };
          copy.metricType = val as MetricType ?? 'OverallStatus';
          onUpdateTrafficLight(copy);
        }}
      />
      <BaseSelect
        label={t('dashboard.widget.trafficLight.trafficLightColor')}
        value={trafficLight.color}
        options={trafficLightColorOptions}
        onChange={(val) => {
          const copy = { ...trafficLight };
          copy.color = val as LightColor ?? 'Green';
          onUpdateTrafficLight(copy);
        }}
      />
    </div>
  );
};
