import React, { PropsWithChildren, useMemo } from 'react';
import { WidgetContainer, WidgetContainerContent, WidgetContainerTitle } from '@client/shared/toolkit';
import { DashboardWidgetVariant, WidgetConfig } from '../WidgetDashboard';
import { useTranslation } from 'react-i18next';
import {
  Chart as ChartJS,
  Filler,
  LinearScale,
  CategoryScale,
  BarElement,
  Tooltip,
  BarController,
  Title,
  ChartOptions,
  ChartData,
  ChartDataset,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { getLanguageAndLocale } from '@client/shared/utilities';

const CHART_TICKS_COLOR = '#64748B'; // slate-500
const INITIAL_BUDGET_COLOR = '#CBD5E1'; // slate-300
const BUDGET_COLOR = '#94A3B8'; // slate-400
const COMMISSIONED_COLOR = '#637288';
const SUPPLEMENT_COLOR = '#334155'; // slate-700
const PAYMENT_COLOR = '#1E2A3B';
const FORECAST_COLOR = '#0C4A6E'; // sky-900
const FORECAST_TOP_COLOR = '#0A5A85';
const INCOME_COLOR = '#3DD598';
const RESULT_COLOR = '#B71E23';

export interface DashboardWidgetFinancingControllingProps extends PropsWithChildren {
  widget: WidgetConfig;
  variant?: DashboardWidgetVariant;
}

export const DashboardWidgetFinancingControlling = ({ widget, children }: DashboardWidgetFinancingControllingProps) => {
  const { t } = useTranslation();
  ChartJS.register(Filler, LinearScale, CategoryScale, BarElement, Tooltip, BarController, Title);
  const locale = getLanguageAndLocale().locale;

  const options: ChartOptions = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      barPercentage: 0.7,
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          stacked: true,
          beginAtZero: true,
          ticks: {
            font: {
              family: 'Roboto',
              size: 10,
            },
            color: CHART_TICKS_COLOR,
            padding: 5,
            z: 10,
          },
          grid: {
            drawOnChartArea: false,
            drawTicks: false,
          },
          border: {
            display: false,
            dash: [5, 15],
          },
        },
        y: {
          stacked: true,
          beginAtZero: true,
          ticks: {
            font: {
              family: 'Roboto',
              size: 10,
            },
            color: CHART_TICKS_COLOR,
            mirror: true,
            padding: 0,
            labelOffset: -10,
            z: 10,
            callback: (value) => {
              return new Intl.NumberFormat(locale, {
                maximumFractionDigits: 2,
                signDisplay: 'auto',
                notation: 'compact',
                compactDisplay: 'short',
              }).format(Number(value));
            },
          },
          position: 'right',
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
        },
      },
      layout: {
        padding: {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        },
      },
    };
  }, [locale]);

  const chartData: ChartData = useMemo(() => {
    return {
      labels: [
        t('dashboard.widget.financingControlling.initialBudget'),
        t('dashboard.widget.financingControlling.budget'),
        t('dashboard.widget.financingControlling.commissioned'),
        t('dashboard.widget.financingControlling.supplement'),
        t('dashboard.widget.financingControlling.payment'),
        t('dashboard.widget.financingControlling.forecast'),
        t('dashboard.widget.financingControlling.income'),
        t('dashboard.widget.financingControlling.result'),
      ],
      datasets: [
        {
          label: '',
          data: [null, null, null, 90, null, null, null, null],
          backgroundColor: 'transparent',
          type: 'bar' as const,
          barPercentage: 0.5,
        },
        {
          label: '',
          data: [100, 120, 90, 10, 20, 150, 100, -50],
          backgroundColor: [
            INITIAL_BUDGET_COLOR,
            BUDGET_COLOR,
            COMMISSIONED_COLOR,
            SUPPLEMENT_COLOR,
            PAYMENT_COLOR,
            FORECAST_COLOR,
            INCOME_COLOR,
            RESULT_COLOR,
          ],
          type: 'bar' as const,
          barPercentage: 0.5,
        },
        {
          label: '',
          data: [null, null, null, null, null, 20, null, null],
          backgroundColor: FORECAST_TOP_COLOR,
          type: 'bar' as const,
          barPercentage: 0.5,
        },
      ] as ChartDataset[],
    };
  }, [t]);

  return (
    <WidgetContainer className="flex flex-col">
      <WidgetContainerTitle>
        {widget.title ? t(widget.title) : t('dashboard.widget.financingControlling.title')}
      </WidgetContainerTitle>
      <WidgetContainerContent className="flex-1">
        <Chart options={options} type="bar" data={chartData} height={95} />
      </WidgetContainerContent>
      {children}
    </WidgetContainer>
  );
};
