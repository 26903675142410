import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import {
  ProjectDashboardCustomDataType,
  ProjectReadModel,
  UnitSystem,
  useApiGetProjectQuery,
  UpsertTrafficLightPayload
} from '@client/shared/api';
import { MaximizeIcon, Modal, PencilIcon } from '@client/shared/toolkit';
import { WidgetDashboardWizard } from './WidgetDashboardWizard';
import { WidgetDashboardEdit } from './WidgetDashboardEdit';
import {
  areLayoutsEqual,
  dashboardConfig,
  DashboardConfigKeys,
  DashboardWidgetType,
  // findEmptyCell,
  getWidget,
  WidgetSizeType,
} from './utils';
import cn from 'classnames';
import { useLoadedProjectId, useUnitSystem } from '@client/project/store';

export type WidgetPosition = {
  x: number;
  y: number;
}

export interface DashboardWidgetProps {
  widget: WidgetConfig;
  layout?: Layout;
}

export type DashboardWidgetConfig = {
  name: string;
  icon: ReactNode;
  title?: string;
  size?: 'large' | 'default';
  variant?: 'feed' | 'other';
  hideIfEmpty?: boolean;
  type?: ProjectDashboardCustomDataType;
};

export type DashboardWidgetVariant = 'card' | 'child';

export interface Widget {
  widget: WidgetConfig;
  layout: Layout;
  breakpoints?: { [key: string]: Layout };
}

export interface WidgetConfig {
  type: DashboardWidgetType;
  id?: string;
  name?: string;
  title?: string;
  description?: string;
  col?: number;
  order?: number;
  colSpan?: string;
  config?: DashboardWidgetConfig[];
  variant?: string;
  icon?: ReactNode | string;
  children?: ReactNode | string;
  sizes?: WidgetSizeType[];
  widgets?: WidgetConfig[];
  text?: string; // textbox widget
  lat?: string; // map widget
  lng?: string; // map widget
  trafficLights?: UpsertTrafficLightPayload[]; // traffic lights widget
}

export interface WidgetDashboardProps {
  type?: DashboardConfigKeys;
  layout: Widget[];
  isEditable?: boolean;
  onSave?: (widgets: Widget[]) => void;
  dashboardId?: string;
  dashboardName?: string;
  multiProject?: boolean;
}

export const WidgetDashboard = (props: WidgetDashboardProps) => {
  const { type = 'default', layout, isEditable = true, onSave, dashboardId, dashboardName, multiProject = false } = props;

  const unitSystem = useUnitSystem();
  const loadedProjectId = useLoadedProjectId();
  const { data: projectData } = useApiGetProjectQuery(
    {
      projectId: loadedProjectId || '',
      unitSystem: unitSystem as UnitSystem,
    },
    { skip: loadedProjectId == null },
  );

  // const [isEditing, setIsEditing] = useState(false);
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [wizardConfig, setWizardConfig] = useState<Layout | null>(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isExpandOpen, setIsExpandOpen] = useState(false);
  const [widgetToEdit, setWidgetToEdit] = useState<Widget | null>(null);
  const [widgetToResize, setWidgetToResize] = useState<Widget | null>(null);
  const [widgetToExpand, setWidgetToExpand] = useState<Widget | null>(null);
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string>('lg');
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), []);

  const gridConfig = useMemo(() => {
    return dashboardConfig[type];
  }, [type]);

  const gridLayout: Layouts | null = useMemo(() => {
    if (gridConfig && dashboardId) {
      const breakpoints: {[key: string]: Layout[]} = {
        lg: []
      };
      if (gridConfig.breakpoints) {
        Object.keys(gridConfig.breakpoints).forEach((gridBreakpoint) => {
          breakpoints[gridBreakpoint] = [];
        });
      }

      Object.keys(breakpoints).forEach((breakpoint) => {
        layout.forEach((widget) => {
          if (widget.breakpoints && widget.breakpoints[breakpoint]) {
            breakpoints[breakpoint].push({
              isResizable: isEditable,
              isDraggable: isEditable,
              ...widget.breakpoints[breakpoint],
            });
          } else {
            breakpoints[breakpoint].push({
              isResizable: isEditable,
              isDraggable: isEditable,
              ...widget.layout,
            });
          }
        });
      });
      return breakpoints;
    }
    return null;
  }, [gridConfig, layout, isEditable, dashboardId]);

  const saveLayout = useCallback(
    (newLayout: Layout[]) => {
      if (onSave && isEditable) {
        // filter out the placeholder widgets, they don't need to be saved
        const copyOldLayout = [...layout];
        const notPlaceholderWidgets: Widget[] = copyOldLayout
          .filter((widgetItem) => {
            return widgetItem.widget.type !== DashboardWidgetType.Placeholder;
          })
          .map((widgetItem) => {
            const newPosition = newLayout.find((layoutItem) => layoutItem.i === widgetItem.widget.id);
            return {
              layout: newPosition as Layout, // override the new position
              widget: widgetItem.widget,
            };
          });
        onSave(notPlaceholderWidgets);
      }
    },
    [layout, onSave, isEditable],
  );

  const onDragStop = (newLayout: Layout[]) => {
    setIsDragging(false);
    // TODO what is this doing?
    /* const originalNewLayout = [...newLayout];
    let rearrange = false;
    const fixedLayout = newLayout.map((item) => {
      if (item.y > 2) {
        rearrange = true;
        // find the new empty cell (there must be one, as we did not resize)
        const emptyCell = findEmptyCell(originalNewLayout, gridConfig.rows, gridConfig.cols?.lg ?? 0);
        if (emptyCell) {
          return {
            ...item,
            x: emptyCell.x,
            y: emptyCell.y,
          };
        }
      }
      return item;
    });

    if (rearrange) {
      setGridLayout({...gridLayout, lg: fixedLayout });
    } else {
      setGridLayout({...gridLayout, lg: newLayout });
    }
    */
    // setIsEditing(false);
  };

  const onResizeStart = (
    _newLayout: Layout[],
    oldItem: Layout
  ) => {
    const widgetToResize = layout.find(({ widget }) => widget.id === oldItem.i);
    widgetToResize && setWidgetToResize(widgetToResize);
  };

  const onResize = (
    _newLayout: Layout[],
    oldItem: Layout,
    newItem: Layout,
    placeholder: Layout
  ) => {
    const isResizeAllowed = widgetToResize?.widget?.sizes?.some(
      (size: { w: number; h: number }) => newItem.w === size.w && newItem.h === size.h
    );

    if (!isResizeAllowed) {
      const resetSize = { w: oldItem.w, h: oldItem.h };
      Object.assign(newItem, resetSize);
      Object.assign(placeholder, resetSize);
    }
  };

  const onResizeStop = () => {
    setWidgetToResize(null);
  };

  const onLayoutChange = (currentLayout: Layout[], allLayouts: Layouts) => {
    if (
      gridLayout !== null &&
      onSave &&
      !areLayoutsEqual(allLayouts.lg, gridLayout.lg)
    ) {
      saveLayout(allLayouts.lg);
    }
  };

  const onBreakpointChange = (newBreakpoint: string) => {
    setCurrentBreakpoint(newBreakpoint);
  };

  const onNewWidgetAdd = (widget: WidgetConfig, position?: Layout) => {
    setWizardConfig({
      i: '0',
      x: position?.x ?? 0,
      y: position?.y ?? 0,
      w: widget.sizes?.length ? widget.sizes[0].w : 1,
      h: widget.sizes?.length ? widget.sizes[0].h : 1,
    } as Layout);
    setIsWizardOpen(true);
  };

  const handleOnWizardSelect = (widget: WidgetConfig) => {
    setIsWizardOpen(false);
    if (wizardConfig) {
      setWidgetToEdit({
        widget: {
          type: widget.type,
        },
        layout: wizardConfig,
      });
      setIsEditOpen(true);
    }
  };

  const handleOnWidgetEdit = (widget: Widget) => {
    setWidgetToEdit(widget);
    setIsEditOpen(true);
  };

  const handleOnWizardClose = () => {
    setIsWizardOpen(false);
  };

  const handleOnWidgetEditClose = () => {
    setIsEditOpen(false);
  };

  const handleOnWidgetExpand = (widget: Widget) => {
    setWidgetToExpand(widget);
    setIsExpandOpen(true);
  }

  const handleOnWidgetCollapse = () => {
    setWidgetToExpand(null);
    setIsExpandOpen(false);
  }

  const resetSelectedWidget = () => {
    setWizardConfig(null);
    setWidgetToEdit(null);
  };

  return (
    <>
      {projectData && gridLayout != null && gridConfig && (
        <ResponsiveReactGridLayout
          className={cn('layout mb-6', gridConfig.classNames)}
          layouts={gridLayout}
          // compactType="horizontal"
          onBreakpointChange={onBreakpointChange}
          // onResizeStart={() => setIsEditing(true)}
          onResizeStart={onResizeStart}
          onResizeStop={onResizeStop}
          onResize={onResize}
          onDragStart={() => setIsDragging(true)}
          onDragStop={onDragStop}
          allowOverlap={false}
          onLayoutChange={onLayoutChange}
          isResizable={isEditable}
          draggableCancel=".grid-item-disable-drag"
          preventCollision={false}
          // TODO Breakpoints should be used, they work, but they are not calculated correctly. Investigation needed.
          // breakpoints={{ lg: 1536, md: 1280, sm: 1024, xs: 768 }}
          {...gridConfig}
        >
          {layout.map((widget: Widget) => (
            <div key={widget.layout.i} className={cn('group relative', { 'select-none': isDragging })} data-key={widget.layout.i}>
              {getWidget(widget.widget, 'card', projectData?.project as ProjectReadModel, multiProject, widget.layout, onNewWidgetAdd)}
              {isEditable && widget.widget.type !== DashboardWidgetType.Placeholder && (
                <div className="grid-item-disable-drag absolute top-0 right-0 transition-opacity duration-300 opacity-0 group-hover:opacity-100 group-hover:hover:opacity-100 cursor-pointer p-2 z-50 pointer-events-auto flex">
                  {widget.widget.type === DashboardWidgetType.MapView && widget.layout.w === 1 && widget.layout.h === 1 && (
                    <div className="me-3" onClick={() => { handleOnWidgetExpand({ ...widget, layout: { ...widget.layout, h: 3 }}); }}>
                      <MaximizeIcon className="w-5 h-auto" />
                    </div>
                  )}
                  <div onClick={() => { handleOnWidgetEdit(widget); }}>
                    <PencilIcon className="w-5 h-auto" />
                  </div>
                </div>
              )}
            </div>
          ))}
        </ResponsiveReactGridLayout>
      )}
      <WidgetDashboardWizard
        widgets={dashboardConfig[type].availableWidgets}
        onSelect={handleOnWizardSelect}
        isOpen={isWizardOpen}
        onClose={handleOnWizardClose}
        wizardConfig={wizardConfig}
        gridLayout={gridLayout}
        dashboardType={type}
      />
      <WidgetDashboardEdit
        dashboardType={type}
        widget={widgetToEdit}
        isOpen={isEditOpen}
        onClose={handleOnWidgetEditClose}
        onAfterLeave={resetSelectedWidget}
        dashboardId={dashboardId}
        dashboardName={dashboardName}
        gridLayout={gridLayout}
        layout={layout}
      />
      <Modal position={currentBreakpoint === 'xxs' ? 'top' : 'center'}
        className="h-[500px]"
        variant={currentBreakpoint === 'lg' ? 'medium' : 'small'}
        showCloseButton={false}
        showMaximizeButton={true}
        isMaximized={true}
        isOpen={isExpandOpen}
        onClose={() => setIsExpandOpen(false)}
        onClickMaximizeButton={handleOnWidgetCollapse}
      >
        <div className="h-full bg-white">
          {widgetToExpand && getWidget(widgetToExpand.widget, 'card', projectData?.project as ProjectReadModel, multiProject, { ...widgetToExpand.layout, w: currentBreakpoint === 'lg' ? 3 : widgetToExpand.layout.w })}
        </div>
      </Modal>
    </>
  );
};
