import { BusIcon, BuyingIcon, ChargingStationIcon, InfoIcon, KawaiiCoffeeIcon, MapMarkerIcon, MetroCustomIcon, PlaneIcon, CutleryIcon, TrainCustomIcon } from "@client/shared/toolkit";
import { Fragment, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { PoisList } from "../DashboardWidgetMapView";

export enum PlaceFieldType {
  evChargeOptions = 'evChargeOptions',
}

/* enum PoiCategory {
  subwayStations = 'subwayStations',
  busStops = 'busStops',
  trainStations = 'trainStations',
  airports = 'airports',
  evChargingStations = 'evChargingStations',
  groceryStores = 'groceryStores',
  coffeeShops = 'coffeeShops',
  restaurants = 'restaurants',
  touristAttractions = 'touristAttractions',
} */

enum CategoryTranslations {
  subwayStations = 'metro',
  busStops = 'bus',
  trainStations = 'train',
  airports = 'airport',
  evChargingStations = 'evcharging',
  groceryStores = 'shopping',
  coffeeShops = 'coffee',
  restaurants = 'restaurant',
  touristAttractions = 'landmarks',
}

export interface BasePoi {
  category: string;
  distance: string;
  name: string;
}

export type Poi = BasePoi & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in PlaceFieldType]?: any;
};

type RenderPoisProps = {
  pois: PoisList;
};

export const PoiRow = ({ header, icon, content }: { header: ReactNode; icon: ReactNode; content: ReactNode }) => (
  <div className="flex justify-start flex-auto py-1 gap-1 h-auto">
    <div className="w-8 pl-4 flex flex-col justify-center min-w-[32px]">{icon}</div>
    <div className="ml-4 flex flex-col justify-evenly flex-grow">
      {header && <div>{header}</div>}
      <div className="font-bold leading-tight text-md">{content}</div>
    </div>
  </div>
);

export const RenderPois = (props: RenderPoisProps) => {
  const { t } = useTranslation();

  const getIcon = (category: string) => {
    switch (category) {
      case 'subwayStations':
        return <MetroCustomIcon className="aspect-square w-full" />;
      case 'busStops':
        return <BusIcon className="aspect-square w-full" />;
      case 'trainStations':
        return <TrainCustomIcon className="aspect-square w-full" />;
      case 'airports':
        return <PlaneIcon className="aspect-square w-full" />;
      case 'evChargingStations':
        return <ChargingStationIcon className="aspect-square w-full" />;
      case 'groceryStores':
        return <BuyingIcon className="aspect-square w-full" />;
      case 'coffeeShops':
        return <KawaiiCoffeeIcon className="aspect-square w-full" />;
      case 'restaurants':
        return <CutleryIcon className="aspect-square w-full" />;
      case 'touristAttractions':
        return <MapMarkerIcon className="aspect-square w-full" />;
      default:
        return <InfoIcon className="aspect-square w-full" />;
    }
  };

  return (Object.keys(props.pois).map((category, index) => props.pois[category as keyof PoisList].length > 0 ? (
      <PoiRow
        header={<span className="font-light text-sm">{t(`poi.${CategoryTranslations[category as keyof typeof CategoryTranslations]}`)}</span>}
        content={props.pois[category as keyof PoisList].map((poi, poiIndex) => (
          <Fragment key={poiIndex}>
            <span className="text-sm font-bold">{poi.name} </span>
            <span className="text-sm font-light">
              {poi.distance}&nbsp;km
            </span>
            {!(props.pois[category as keyof PoisList].length === poiIndex + 1) ? <span className="font-light">, </span> : null}
          </Fragment>
        ))}
        icon={getIcon(category)}
        key={index}
      />
    ) : null)
  );
};
