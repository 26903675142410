import {
  BaseSelect,
  BuildingIcon,
  Button,
  ComboSelect,
  CurrencySelect,
  DateFromIcon,
  DatePicker,
  EditNodeIcon,
  Form,
  FormField,
  LoadingIndicator,
  Modal,
  ProjectInfoCardMapContent,
  RulerIcon,
  SkyscrapersDottedIcon,
  SlideOverOnCloseProps,
  SlideOverTitle,
  TextInput,
  UsDollarCircledIcon,
} from '@client/shared/toolkit';
import { EditProjectFormValidationSchema, EditProjectFormValidationValues, ProjectImageUploadModal } from '.';
import classNames from 'classnames';
import { MapPinIcon, TagIcon } from '@heroicons/react/24/outline';
import { countryCodes, getUnitSystemLabel, safeMutation } from '@client/shared/utilities';
import { EditUserDefinedFields, ProjectTaxPickerInput } from '@client/project/shared';
import { RefObject, useMemo, useState } from 'react';
import { Currency, ProjectReadModel, useApiPostUpdateProjectMetadataMutation, UserDefinedFieldPayload, ValueType } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { getMeasures, useLoadedMeasureTypes } from '@client/project/store';

type NestedModal = 'None' | 'Edit';

interface ProjectDataProps extends SlideOverOnCloseProps {
  project?: ProjectReadModel;
  thumbnail?: string;
  formRef: RefObject<HTMLFormElement>;
  readOnly: boolean;
}

export const ProjectData = (props: ProjectDataProps) => {
  const { project, thumbnail, onClose, formRef, readOnly } = props;

  const { t } = useTranslation();

  const [postProjectMetadata, { isLoading }] = useApiPostUpdateProjectMetadataMutation();
  const [nestedModal, setNestedModal] = useState<NestedModal>('None');
  const [udfUpdatePayload, setUdfUpdatePayload] = useState<UserDefinedFieldPayload[] | undefined>();

  const availableMeasureTypes = useLoadedMeasureTypes();
  const measureOptions = useMemo(() => getMeasures(availableMeasureTypes), [availableMeasureTypes]);

  const handleCloseNestedModal = () => {
    setNestedModal('None');
  };

  const lat = project?.payload.latitude;
  const lon = project?.payload.longitude;

  const handleSubmit = async (data: EditProjectFormValidationValues) => {
    if (project?.payload.id) {
      try {
        await safeMutation(
          postProjectMetadata,
          {
            projectId: project?.payload.id,
            body: {
              projectId: data.projectId,
              name: data.name,
              street: data.street,
              houseNumber: data.houseNumber,
              postalCode: data.postalCode,
              city: data.city,
              countryCode: data.countryCode,
              vat: data.vat,
              selectedMeasureId: data.measure,
              currency: data.currency as Currency,
              calculateValueType: data.calculateValueType as ValueType,
              userDefinedFieldsPayload: udfUpdatePayload?.length ? udfUpdatePayload : undefined,
              isBenchmarkProject: true,
              benchmarkSettingsPayload: null
            },
          },
          isLoading,
        );
        onClose(true);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const valueTypeOptions = [
    { label: t('common.net'), value: 'Net' },
    { label: t('common.gross'), value: 'Gross' },
  ];

  const defaultFormValues = {
    projectId: project?.payload.projectId ?? '',
    name: project?.payload.name ?? '',
    street: project?.payload.street ?? '',
    houseNumber: project?.payload.number ?? '',
    postalCode: project?.payload.postalCode ?? '',
    city: project?.payload.city ?? '',
    countryCode: project?.payload.countryCode ?? undefined,
    vat: project?.payload.vat ?? undefined,
    measure: project?.payload.measure.id ?? measureOptions[0].value,
    currency: project?.payload.currency.currency,
    calculateValueType: project?.payload.calculateValueType,
  };

  return (
    <div className="flex-grow-0 flex flex-col h-full overflow-y-auto">
      {isLoading && <LoadingIndicator text={t('project.updatingProjectLoadingIndicator')} mode="overlay" />}
      <Form<EditProjectFormValidationValues>
        onSubmit={handleSubmit}
        validationSchema={EditProjectFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex"
        ref={formRef}
      >
        <div className={classNames(lat && lon ? 'w-2/3' : 'w-full')}>
          <div className="relative group overflow-hidden mb-2 rounded-md ">
            <div
              style={{ backgroundImage: `url(${thumbnail})` }}
              className="aspect-video bg-cover bg-center w-full h-40 bg-gray-500 transition-transform duration-300 transform group-hover:scale-105"
            />
            <div className="absolute top-0 bg-white rounded-full h-12 w-12 flex items-center justify-center mt-4 ml-4">
              <SkyscrapersDottedIcon className="h-7" />
            </div>
            <div className="absolute inset-0 bg-black bg-opacity-75 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
              <Button variant="primary" onClick={() => setNestedModal('Edit')}>
                {t('app.editUploadImage')}
              </Button>
            </div>
          </div>
          <div className="bg-white divide-gray-100 divide-y">
            <FormField name="name">
              {(control) => (
                <TextInput
                  label={t('app.editProjectName')}
                  icon={<TagIcon className="h-6 w-6" />}
                  {...control}
                  disabled={readOnly}
                />
              )}
            </FormField>
            <div className="grid grid-cols-2 divide-x">
              <FormField name="projectId">
                {(control) => (
                  <TextInput
                    label={t('app.editProjectId')}
                    icon={<TagIcon className="h-6 w-6" />}
                    {...control}
                    disabled={readOnly}
                  />
                )}
              </FormField>
              <FormField name="countryCode">
                {(control) => (
                  <ComboSelect
                    icon={<MapPinIcon className="h-6 w-6" />}
                    label={t('common.addressCountry')}
                    options={countryCodes.getCountries()}
                    disabled={readOnly}
                    {...control}
                  />
                )}
              </FormField>
            </div>
            <div className="grid grid-cols-2 divide-x">
              <FormField name="street">
                {(control) => (
                  <TextInput
                    label={t('common.addressStreet')}
                    icon={<MapPinIcon className="h-6 w-6" />}
                    {...control}
                    disabled={readOnly}
                  />
                )}
              </FormField>
              <FormField name="houseNumber">
                {(control) => <TextInput label={t('common.addressHouseNumber')} {...control} disabled={readOnly} />}
              </FormField>
            </div>
            <div className="grid grid-cols-2 divide-x">
              <FormField name="postalCode">
                {(control) => (
                  <TextInput
                    label={t('common.addressPostalCode')}
                    icon={<MapPinIcon className="h-6 w-6" />}
                    {...control}
                    disabled={readOnly}
                  />
                )}
              </FormField>
              <FormField name="city">
                {(control) => <TextInput label={t('common.addressCity')} {...control} disabled={readOnly} />}
              </FormField>
            </div>
            <div className="grid grid-cols-2 divide-x">
              <FormField name="vat">
                {(control) => (
                  <ProjectTaxPickerInput
                    label={t('app.newProjectTaxRate')}
                    projectTaxRate={project?.payload.vat}
                    disabled={readOnly}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="measure">
                {(control) => (
                  <ComboSelect
                    icon={<BuildingIcon className="h-6 w-6" />}
                    label={t('project.measure.label')}
                    options={measureOptions}
                    {...control}
                  />
                )}
              </FormField>
            </div>
            <div className="grid grid-cols-2 divide-x">
              <DatePicker
                icon={<DateFromIcon className="h-6 w-6" />}
                label={t('project.calculatedStartDate')}
                value={project?.payload.start}
                onChange={() => {/*nothing to do*/}}
                disabled={true}
              />
              <DatePicker
                label={t('project.calculatedEndDate')}
                icon={<DateFromIcon className='h-6 w-6 scale-x-[-1]' />}
                value={project?.payload.end}
                onChange={() => {/*nothing to do*/}}
                disabled={true}
              />
            </div>            
            <div className="grid grid-cols-3 divide-x">
              <CurrencySelect disabled />             
              <FormField name="calculateValueType">
                {(control) => (
                  <BaseSelect
                    icon={<UsDollarCircledIcon className="h-6 w-6" />}
                    label={t('projectCalculate.valueType')}
                    options={valueTypeOptions}
                    disabled={true}
                    {...control}
                  />
                )}
              </FormField>
              <TextInput
                label={t('project.measurementSystem')}
                icon={<RulerIcon />}
                disabled={true}
                value={getUnitSystemLabel(project?.payload.unitSystem ?? '-')}
              />
            </div>          
          </div>
        </div>
        {lat && lon && (
          <div className="w-1/3 ml-2">
            <ProjectInfoCardMapContent latitude={lat} longitude={lon} />
          </div>
        )}
      </Form>

      <SlideOverTitle title={t('project.catalogs')} />
      <div className="grid grid-cols-2 divide-x">
        <TextInput
          icon={<EditNodeIcon />}
          label={t('app.newProjectCostCatalog')}
          value={project?.payload.primaryCostCatalog.name ?? '-'}
          onChange={() => {/*nothing to do*/}}
          disabled={true}
        />
        <TextInput
          label={t('app.newProjectRiskCatalog')}
          icon={<EditNodeIcon />}
          value={project?.payload.primaryRiskCatalog?.name ?? '-'}
          onChange={() => {/*nothing to do*/}}
          disabled={true}
        />
      </div>
      <div className="grid grid-cols-2 divide-x">
        <TextInput
          icon={<EditNodeIcon />}
          label={t('app.newProjectEarningsCatalog')}
          value={project?.payload.primaryEarningsCatalog?.name ?? '-'}
          onChange={() => {/*nothing to do*/}}
          disabled={true}
        />
        <TextInput
          label={t('app.newProjectFinancingCatalog')}
          icon={<EditNodeIcon />}
          value={project?.payload.primaryFinancingCatalog?.name ?? '-'}
          onChange={() => {/*nothing to do*/}}
          disabled={true}
        />
      </div>

      {!readOnly && (
        <EditUserDefinedFields
          elementId={project?.payload.id}
          type="Project"
          setUpdatePayload={setUdfUpdatePayload}
          hasPadding={false}
          projectId={project?.payload.id}
        />
      )}
      {project && project.payload.id && (
        <Modal isOpen={nestedModal === 'Edit'} onClose={handleCloseNestedModal} zIndex="z-50">
          <ProjectImageUploadModal projectId={project?.payload.id} onClose={handleCloseNestedModal} />
        </Modal>
      )}
    </div>
  );
};
