import React, { useMemo, useState } from 'react';
import { DecoratedCard, ToggleSlider } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { CashOutReportReadModel } from '@client/shared/api';
import { useLoadedProjectId } from '@client/project/store';
import { CashOutChart, CashFlowSettings } from '@client/project/shared';
import { NavLink } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';

// ------- CONSTANTS START -------
const MONTH_X_AXIS_WIDTH = 30;
const CHART_PADDING_X = 20;
// ------- CONSTANTS END -------

export const ReportCashOut = ({ title }: { title?: string }) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();

  const [showNet, setShowNet] = useState(true);
  const [labels, setLabels] = useState<string[]>([]);
  const [data, setData] = useState<undefined | CashOutReportReadModel[]>(undefined);

  const chartMinWidth = useMemo(() => {
    const minWidth = labels.length * MONTH_X_AXIS_WIDTH + CHART_PADDING_X * 2;
    return minWidth >= 1200 ? minWidth : 1200;
  }, [labels.length]);

  return (
    <div className="min-w-[1200px]" style={{ minWidth: chartMinWidth }}>
      <DecoratedCard className="w-full">
        <DecoratedCard.Header showActionButton={false}>
          <div className="flex flex-row justify-between items-center w-full">
            <div className="flex w-full gap-3">
              <div className="truncate">{title ? title : t('reporting.reportCashOut.fullTitle')}</div>
              <div className="flex-1 flex gap-2">
                {data && data.length > 0 && (
                  <ToggleSlider
                    headerLabel={t('reporting.netGrossSwitchLabel')}
                    left={showNet}
                    onClick={() => setShowNet((prev) => !prev)}
                    labelLeft={t('projectControl.net')}
                    labelRight={t('projectControl.gross')}
                  />
                )}
                <CashFlowSettings readOnly>
                  <NavLink
                    to={`${ROUTES_CONFIG.PROJECT_CALCULATE.path.replace(':id', loadedProjectId ?? '')}?view=cashFlow`}
                    className="mt-2 font-bold text-sm text-right text-gray-500 hover:text-sky-700 transition-colors duration-200 inline-block"
                  >
                    {t('reporting.reportCashOut.cashFlowSettingsLinkText')}
                  </NavLink>
                </CashFlowSettings>
              </div>
            </div>
          </div>
        </DecoratedCard.Header>
        <DecoratedCard.Content className="w-full h-full flex relative">
          <div className="my-6">
            {/* export-chart id important for exporting chart as PDF */}
            <div className="h-[67vh]" id="export-chart">
              <CashOutChart updateData={setData} setLabels={setLabels} showNet={showNet} />
            </div>
          </div>
        </DecoratedCard.Content>
      </DecoratedCard>
    </div>
  );
};
