import {
  LoadingIndicator,
  WidgetContainer,
  WidgetContainerContent,
  WidgetContainerTitle,
} from '@client/shared/toolkit';
import {
  Chart as ChartJS,
  Filler,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Title,
  ChartOptions
} from 'chart.js';
import React, { useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import { getLanguageAndLocale } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { useProjectDashboardData } from './hooks';

const DASHBOARD_WIDGET_CASH_FLOW_BG_COLOR_RED = '#D18386';
const DASHBOARD_WIDGET_CASH_FLOW_BG_COLOR_GREEN = '#17A085';

export const DashboardWidgetCashFlowChart = () => {
  ChartJS.register(
    Filler,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    Title,
  );
  const { t } = useTranslation();
  const { data: projectData, isFetching } = useProjectDashboardData();
  const locale = getLanguageAndLocale().locale;
  const options: ChartOptions = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      barPercentage: 0.7,
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          ticks: {
            display: false,
          },
          grid: {
            drawOnChartArea: false,
            drawTicks: false,
          },
          border: {
            display: false,
            dash: [5, 15],
          },
        },
        y: {
          beginAtZero: true,
          ticks: {
            font: {
              family: 'Roboto',
              size: 10,
            },
            color: '#64748B',
            callback: (value, index, ticks) => {
              return new Intl.NumberFormat(locale, {
                maximumFractionDigits: 2,
                signDisplay: 'auto',
                notation: 'compact',
                compactDisplay: 'short',
              }).format(Number(value));
            },
            mirror: true,
            padding: 0,
            labelOffset: -10,
            z: 10,
          },
          position: 'right',
          grid: {
            drawTicks: false,
          },
          border: {
            display: false,
          },
        },
      },
      layout: {
        padding: {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        },
      },
    };
  }, [locale]);

  const data = useMemo(() => {
    const months: string[] = [];
    const chartData: number[] = [];
    if (projectData?.project?.cashFlowLtm) {
      // chartData = projectData.project.cashFlowLtm.map((lm) => lm.value);
      projectData.project.cashFlowLtm.forEach((item) => {
        const prevMonthLabel = (new Date(item.month)).toLocaleString(locale, { month: 'long' });
        months.push(prevMonthLabel);
        chartData.push(item.value);
      });
      /*[
      -7376187.60,
      -8554138.88,
      -9730400.53,
      -5789270.62,
      -4045376.78,
      -4308210.44,
      -1777148.02,
      -848464.42,
      -1111140.20,
      2733345.03,
      4412083.17,
      3725587.25,
      2839531.14
    ]; */
    }
    const bgColors: string[] = [];
    chartData.forEach((cd) => {
      if (cd >= 0) {
        bgColors.push(DASHBOARD_WIDGET_CASH_FLOW_BG_COLOR_GREEN);
      } else {
        bgColors.push(DASHBOARD_WIDGET_CASH_FLOW_BG_COLOR_RED);
      }
    });

    return {
      labels: months,
      datasets: [
        {
          label: 'label',
          data: chartData,
          backgroundColor: bgColors,
          fill: true,
        },
      ],
    };
  }, [locale, projectData?.project?.cashFlowLtm]);
  return (
    <WidgetContainer>
      {isFetching ? (
        <LoadingIndicator className="p-2" />
      ) : (
        <>
          <WidgetContainerTitle>{t('project.dashboard.widget.cashflow.title')}</WidgetContainerTitle>
          <WidgetContainerContent>
            <Chart options={options} type="bar" data={data} height={240}  />
          </WidgetContainerContent>
        </>
      )}
    </WidgetContainer>
  );
};
