import { useState, useRef, useEffect, useMemo } from 'react';
import {
  useApiGetCatalogsQuery,
  useApiGetCatalogsDeliveryPhasesQuery,
  useApiGetRiskCatalogsQuery,
  useApiPostCreateProjectMutation,
  useApiGetEarningsCatalogsQuery,
  useApiGetFinancingCatalogsQuery,
  useApiGetCatalogTemplatesQuery,
  ShortProjectGroupReadModel,
  UnitSystem, UserDefinedFieldPayload,
  useApiGetUserDefinedFieldsDefinitionQuery,
  ValueType,
} from '@client/shared/api';
import { ProjectNewFormValidationValues, ProjectNewFormValidationSchema } from './ProjectNewFormValidationSchema';
import { useNavigate } from 'react-router-dom';
import {
  Wizard,
  Button,
  Form,
  FormField,
  TextInput,
  NumberInput,
  DatePicker,
  BaseSelect,
  FileInput,
  Modal,
  FormRefHandle,
  FormWatch,
  TaskCompletedDottedIcon,
  TagWindowIcon,
  ExpandIcon,
  LocationIcon,
  PercentageIcon,
  RulerIcon,
  TimeLimitIcon,
  EditNodeIcon,
  CityBuildingsIcon,
  YardSaleIcon,
  GrzIcon,
  NewProjectWizardIcon,
  WizardSlideHeader,
  WizardSlideContent,
  ComboSelect,
  LoadingIndicator,
  UsDollarCircledIcon,
  BuildingIcon,
  CurrencySelect
} from '@client/shared/toolkit';
import { safeMutation, countryCodes, formatDateOnly, getUnitSystemLabel } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { RectangleGroupIcon } from '@heroicons/react/24/outline';
import { getMeasures, useLoadedDefaultValues, useLoadedMeasureTypes, useProjects } from '@client/project/store';
import { useFeatureFlags } from '@client/shared/store';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { EditUserDefinedFields } from '@client/project/shared';

interface NewProjectWizardProps {
  onClose: () => void;
  selectedGroupProject?: ShortProjectGroupReadModel | null;
}

export const ProjectNewWizard = ({ onClose, selectedGroupProject }: NewProjectWizardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { unitSystem: showUnitSystem } = useFeatureFlags();

  const { projects, groups } = useProjects();

  let allProjects = projects;
  groups.map((x) => (allProjects = allProjects.concat(x.projects)));

  let defaultProjectId = projects.length;

  const isExistingProjectId = (id: string): boolean => {
    return allProjects.some((p) => p.projectId === id);
  };

  while (isExistingProjectId(defaultProjectId.toString())) {
    defaultProjectId++;
  }

  const formRef = useRef<FormRefHandle<ProjectNewFormValidationValues>>();
  const [currentStep, setCurrentStep] = useState(0);
  const [newProjectId, setNewProjectId] = useState<string | undefined>();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>('none');
  const [fileError, setFileError] = useState(false);

  const [postProject, { isLoading }] = useApiPostCreateProjectMutation();

  const defaultValues = useLoadedDefaultValues();
  const availableMeasures = useLoadedMeasureTypes();
  const measureOptions = useMemo(() => getMeasures(availableMeasures), [availableMeasures])

  const { data: catalogTemplates, isFetching: isLoadingCatalogTemplates } = useApiGetCatalogTemplatesQuery();
  const catalogTemplateOptions =
    catalogTemplates?.map((template) => ({
      label: template.name,
      value: template.catalogTemplateId,
    })) ?? [];

  const valueTypeOptions = [
    { label: t('common.net'), value: 'Net' },
    { label: t('common.gross'), value: 'Gross' },
  ]

  catalogTemplateOptions.unshift({ label: t('app.newProjectCatalogTemplateNone'), value: 'none' });

  const { data: costCatalogs, isFetching: isLoadingCostCatalogs } = useApiGetCatalogsQuery();
  const costCatalogOptions =
    costCatalogs?.catalogs.map((catalog: { name: string; id: string }) => ({
      label: catalog.name,
      value: catalog.id,
    })) ?? [];

  const { data: riskCatalogs, isFetching: isLoadingRiskCatalogs } = useApiGetRiskCatalogsQuery();
  const riskCatalogOptions =
    riskCatalogs?.riskCatalogs.map((catalog: { name: string; id: string }) => ({
      label: catalog.name,
      value: catalog.id,
    })) ?? [];

  riskCatalogOptions.unshift({ label: t('app.newProjectRiskCatalogNone'), value: 'none' });

  const { data: earningsCatalogs, isFetching: isLoadingEarningsCatalogs } = useApiGetEarningsCatalogsQuery();
  const earningsCatalogOptions =
    earningsCatalogs?.earningsCatalogs.map((catalog: { name: string; id: string }) => ({
      label: catalog.name,
      value: catalog.id,
    })) ?? [];

  earningsCatalogOptions.unshift({ label: t('app.newProjectEarningsCatalogNone'), value: 'none' });

  const { data: financingCatalogs, isFetching: isLoadingFinancingCatalogs } = useApiGetFinancingCatalogsQuery();
  const financingCatalogOptions =
    financingCatalogs?.financingCatalogs.map((catalog: { name: string; id: string }) => ({
      label: catalog.name,
      value: catalog.id,
    })) ?? [];

  financingCatalogOptions.unshift({ label: t('app.newProjectFinancingCatalogNone'), value: 'none' });

  const { data: deliveryPhasesCatalogs, isFetching: isLoadingDeliveryPhasesCatalogs } = useApiGetCatalogsDeliveryPhasesQuery();

  const deliveryPhasesCatalogsOptions =
    deliveryPhasesCatalogs?.catalogs != null
      ? deliveryPhasesCatalogs?.catalogs.map((catalog: { name: string; id: string }) => ({
          label: catalog.name,
          value: catalog.id,
        }))
      : [];

  // for custom fields check
  const { data: userDefinedFieldsDefinitionResponse, isFetching: isLoadingUserDefinedFieldsDefinition } = useApiGetUserDefinedFieldsDefinitionQuery();
  const [udfUpdatePayload, setUdfUpdatePayload] = useState<UserDefinedFieldPayload[] | undefined>();
  const [isCustomFieldFormSubmitted, setIsCustomFieldFormSubmitted] = useState(false)
  const [customFieldsAreValid, setCustomFieldsAreValid] = useState(true)

  const mandatoryProjectUserDefinedFieldDefinitions = useMemo(() => {
    if (userDefinedFieldsDefinitionResponse) {
      const defs = userDefinedFieldsDefinitionResponse.userDefinedFieldsDefinition
      return defs.filter((def) => {
        return def.elementType === 'Project' && def.isRequired
      })
    }
    return []
  }, [userDefinedFieldsDefinitionResponse])

  const unitSystemOptions = ['Metric', 'Imperial'].map((option) => ({
    label: getUnitSystemLabel(option),
    value: option,
  }));

  const handleCloseModal = () => {
    onClose();
  };

  const projectIdExistsError = 'This ProjectId is already in use by another project';

  const handleOnSlideChange = async (index: number) => {
    // if (!showUnitSystem && index === 2) {
      // setCurrentStep(index++);
    // }
    // skip custom fields slide if there are no mandatory fields
    /* if (!mandatoryProjectUserDefinedFieldDefinitions.length && index === 5) {
      setCurrentStep(index++)
    } */
    if (mandatoryProjectUserDefinedFieldDefinitions.length && index === 6) {
      setIsCustomFieldFormSubmitted(true)
      // mandatory custom fields must be set
      if (!customFieldsAreValid) return
    }

    if (formRef.current) {
      await formRef.current.validateForm();

      // as in project slideovers we are relying on backend validation for this check
      // but in create project it needs to be done here as well, otherwise server error will be provoked only at the last page
      if (isExistingProjectId(formRef.current.getValues().projectId))
        await formRef.current.setError('projectId', projectIdExistsError);

      const { isValid } = formRef.current.getState();

      // FP: A bit hacky, but it is the only way to make validation not just run but show the errors
      if (!isValid) {
        await formRef.current.submitForm();
        await formRef.current.validateForm();

        if (isExistingProjectId(formRef.current.getValues().projectId))
          await formRef.current.setError('projectId', projectIdExistsError);

        const { isValid } = formRef.current.getState();

        if (isValid) {
          setCurrentStep(index);
        }
      }

      if (isValid) {
        setCurrentStep(index);
      }
    }
  };

  useEffect(() => {
    if (!defaultValues.data){
      return;
    }

    formRef.current?.setValue('unitSystem', defaultValues.data.unitSystem);
    formRef.current?.setValue('calculateValueType', defaultValues.data.valueType);
    formRef.current?.setValue('currency', defaultValues.data.currency);
  }, [formRef, defaultValues])

  useEffect(() => {
    if (formRef.current) {
      const values = formRef.current.getValues();

      if (values?.costCatalogId === 'no-id' && costCatalogs?.catalogs[0].id !== undefined) {
        formRef.current.setValue('costCatalogId', costCatalogs?.catalogs[0].id);
      }

      if (values?.deliveryPhasesCatalogId === 'no-id' && deliveryPhasesCatalogs?.catalogs[0].id !== undefined) {
        formRef.current.setValue('deliveryPhasesCatalogId', deliveryPhasesCatalogs?.catalogs[0].id);
      }
    }
  }, [formRef, costCatalogs, deliveryPhasesCatalogs]);

  const handleOpenProject = () => {
    if (newProjectId) {
      navigate(ROUTES_CONFIG.PROJECT_DASHBOARD.path.replace(':id', newProjectId));
    }
  };

  const handleSubmit = async () => {
    if (formRef.current) {
      const values = formRef.current.getValues();

      try {
        await handleSaveProject(values);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleSaveProject = async (data: ProjectNewFormValidationValues) => {
    try {
      const formData = new FormData();
      formData.append('projectId', data.projectId);
      formData.append('name', data.name);
      formData.append('projectGroupId', data.projectGroupId ?? '');
      formData.append('street', data.street ?? '');
      formData.append('houseNumber', data.houseNumber ?? '');
      formData.append('postalCode', data.postalCode ?? '');
      formData.append('city', data.city ?? '');
      formData.append('countryCode', data.countryCode ?? '');
      formData.append('vat', data.vat?.toString() ?? '0');
      formData.append('plotSize', data.plotSize?.toString() ?? '0');
      formData.append('gfz', data.gfz?.toString() ?? '0');
      formData.append('grz', data.grz?.toString() ?? '0');
      formData.append('startDate', formatDateOnly(data.startDate));
      formData.append('costCatalogId', data.costCatalogId ?? 'no-id');
      formData.append('riskCatalogId', data.riskCatalogId === 'none' ? '' : data.riskCatalogId ?? '');
      formData.append('earningsCatalogId', data.earningsCatalogId === 'none' ? '' : data.earningsCatalogId ?? '');
      formData.append('financingCatalogId', data.financingCatalogId === 'none' ? '' : data.financingCatalogId ?? '');

      formData.append('deliveryPhasesCatalogId', data.deliveryPhasesCatalogId ?? 'no-id');
      formData.append('selectedMeasureId', data.measure ?? '');
      formData.append('unitSystem', data.unitSystem ?? 'Metric');
      formData.append('calculateValueType', data.calculateValueType ?? 'Net');
      formData.append('currency', data.currency ?? 'Eur');

      if (udfUpdatePayload?.length) {
        formData.append('userDefinedFieldsPayload', JSON.stringify(udfUpdatePayload))
      }

      if (data.coverImage && data.coverImage[0]) {
        formData.append('coverImage', data.coverImage[0]);
      }

      const projectResult = await safeMutation(
        postProject,
        {
          body: formData as {
            ProjectId?: string | undefined;
            Name?: string | undefined;
            ProjectGroupId?: string | undefined;
            Street?: string | undefined;
            HouseNumber?: string | undefined;
            PostalCode?: string | undefined;
            City?: string | undefined;
            CountryCode?: string | undefined;
            TaxRate?: number | undefined;
            PlotSize?: number | undefined;
            Gfz?: number | undefined;
            Grz?: number | undefined;
            StartDate?: string | undefined;
            CostCatalogId?: string | undefined;
            RiskCatalogId?: string | undefined;
            EarningsCatalogId?: string | undefined;
            DeliveryPhasesCatalogId?: string | undefined;
            CoverImage?: Blob | undefined;
            SelectedMeasureId?: string | undefined;
            UnitSystem?: UnitSystem | undefined;
            CalculateValueType?: ValueType | undefined;
            UserDefinedFieldsPayload?: string | undefined;
          },
        },
        isLoading
      );
      setNewProjectId(projectResult?.projectId);
      // move to last page in wizard
      setCurrentStep(currentStep + 1);
    } catch (e) {
      console.log(e);
    }
  };

  const updateSelectedTemplate = (templateId: string) => {
    setSelectedTemplateId(templateId);
    const template = catalogTemplates?.find((x) => x.catalogTemplateId === templateId);

    const fallbackCostCatalog = costCatalogs?.catalogs[0].id ?? 'no-id';
    formRef.current?.setValue('costCatalogId', template?.costCatalog.id ?? fallbackCostCatalog);
    formRef.current?.setValue('riskCatalogId', template?.riskCatalog?.id ?? 'none');
    formRef.current?.setValue('earningsCatalogId', template?.earningsCatalog?.id ?? 'none');
    formRef.current?.setValue('financingCatalogId', template?.financingCatalog?.id ?? 'none');
  };

  const defaultFormValues = {
    projectId: defaultProjectId.toString(),
    name: '',
    projectGroupId: selectedGroupProject?.id ?? '',
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    countryCode: undefined,
    vat: 19,
    plotSize: 0,
    gfz: 0,
    grz: 0,
    costCatalogId: costCatalogs?.catalogs[0].id ?? 'no-id',
    riskCatalogId: riskCatalogOptions[0].value ?? 'none',
    earningsCatalogId: earningsCatalogOptions[0].value ?? 'none',
    financingCatalogId: financingCatalogOptions[0].value ?? 'none',
    deliveryPhasesCatalogId: deliveryPhasesCatalogs?.catalogs[0].id ?? 'no-id',
    startDate: undefined,
    coverImage: undefined,
    measure: measureOptions[0]?.value ?? '',
    unitSystem: 'Metric',
    calculateValueType: 'Net' as ValueType,
    currency: 'Eur'
  };

  useEffect(() => {
    if (measureOptions[0] && formRef.current) {
      formRef.current.setValue('measure', measureOptions[0].value);
    }
  }, [measureOptions, formRef]);

  return (
    <Form<ProjectNewFormValidationValues>
      validationSchema={ProjectNewFormValidationSchema}
      defaultValues={defaultFormValues}
      ref={formRef}
      className="h-full"
    >
      <Wizard
        currentStep={currentStep}
        onSlideChange={(step) => handleOnSlideChange(step)}
        className="w-[740px] h-[560px]"
      >
        {isLoading && <LoadingIndicator text={t('app.createProjectLoading') ?? ''} />}
        {(isLoadingCatalogTemplates || isLoadingCostCatalogs || isLoadingRiskCatalogs || isLoadingEarningsCatalogs || isLoadingFinancingCatalogs || isLoadingDeliveryPhasesCatalogs || isLoadingUserDefinedFieldsDefinition  || defaultValues.isFetching) && (
          <LoadingIndicator text={t('common.loading') ?? ''} />
        )}
        <Wizard.Slides>
          {/* SLIDE 1 */}
          <Wizard.Slide>
            <WizardSlideHeader
              icon={<NewProjectWizardIcon />}
              title={selectedGroupProject
                ? t('app.wizardNewSubProject', { name: selectedGroupProject.name, interpolation: { escapeValue: false } })
                : t('app.wizardNewProject')}
            />
            <WizardSlideContent>
              {selectedGroupProject && (
                <FormField name="projectGroupId">
                  {(control) => (
                    <TextInput
                      className="hidden"
                      disabled
                      label=""
                      icon={<TagWindowIcon />}
                      {...control}
                    />
                  )}
                </FormField>
              )}
              <FormField name="name">
                {(control) => (
                  <TextInput
                    label={t('app.newProjectName')}
                    icon={<TagWindowIcon />}
                    {...control}
                    passDataCy="create-project-name"
                  />
                )}
              </FormField>
              <FormField name="projectId">
                {(control) => (
                  <TextInput
                    label={t('app.newProjectId')}
                    icon={<TagWindowIcon />}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="startDate">
                {(control) => (
                  <DatePicker
                    label={t('app.newProjectStartDate')}
                    icon={<TimeLimitIcon />}
                    {...control}
                    passDataCy="create-project-start-date"
                  />
                )}
              </FormField>
            </WizardSlideContent>
          </Wizard.Slide>

          {/* SLIDE 2 - ADDRESS */}
          <Wizard.Slide>
            <WizardSlideHeader
              title={t('app.wizardAddress')}
              subTitle={t('app.wizardAddressDescription')}
            />
            <WizardSlideContent>
              <div className="flex flex-row divide-x">
                <FormField name="street">
                  {(control) => (
                    <TextInput
                      label={t('common.addressStreet')}
                      icon={<LocationIcon />}
                      className="w-4/6"
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="houseNumber">
                  {(control) => <TextInput label={t('common.addressHouseNumber')} className="w-2/6" {...control} />}
                </FormField>
              </div>
              <div className="flex flex-row divide-x">
                <FormField name="postalCode">
                  {(control) => (
                    <TextInput
                      label={t('common.addressPostalCode')}
                      icon={<CityBuildingsIcon />}
                      className="w-2/6"
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="city">
                  {(control) => <TextInput label={t('common.addressCity')} className="w-4/6" {...control} />}
                </FormField>
              </div>
              <FormField name="countryCode">
                {(control) => (
                  <ComboSelect label={t('common.addressCountry')} options={countryCodes.getCountries()} {...control} />
                )}
              </FormField>
            </WizardSlideContent>
          </Wizard.Slide>

          {/* SLIDE 3 - MEASUREMENT / UNIT SYSTEM */}
          {showUnitSystem && (
            <Wizard.Slide>
              <WizardSlideHeader
                title={t('app.wizardMeasurementSystem')}
                subTitle={t('app.wizardMeasurementSystemDescription')}
              />
              <WizardSlideContent>
                <FormField name="unitSystem">
                  {(control) => (
                    <BaseSelect
                      label={t('project.measurementSystem')}
                      icon={<RulerIcon />}
                      options={unitSystemOptions}
                      {...control}
                    />
                  )}
                </FormField>
              </WizardSlideContent>
            </Wizard.Slide>
          )}

          {/* SLIDE 4 - PLOT SIZE */}
          <Wizard.Slide>
            <WizardSlideHeader
              title={t('app.wizardPlotSize')}
              subTitle={t('app.wizardPlotSizeDescription')}
            />
            <WizardSlideContent>
              <FormField name="plotSize">
                {(control) => (
                  <NumberInput
                     label={`${t('app.newProjectPlotSize')} (${formRef.current?.getValues().unitSystem === 'Metric' ? 'm²' : 'ft²'})`}
                    icon={<ExpandIcon />}
                    {...control}
                  />
                )}
              </FormField>
              <div className="flex flex-row divide-x">
                <FormField name="gfz">
                  {(control) => (
                    <NumberInput
                      label={t('app.newProjectGfz')}
                      icon={<YardSaleIcon />}
                      className="w-1/2"
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="grz">
                  {(control) => (
                    <NumberInput
                      label={t('app.newProjectGrz')}
                      icon={<GrzIcon />}
                      className="w-1/2"
                      {...control}
                    />
                  )}
                </FormField>
              </div>
            </WizardSlideContent>
          </Wizard.Slide>

          {/* SLIDE 5 - Additional Settings */}
          <Wizard.Slide centered={false}>
            <WizardSlideHeader
              title={t('app.wizardAdditionalSettings')}
              subTitle={t('app.wizardAdditionalSettingsDescription')}
            />
            <WizardSlideContent>
              <BaseSelect
                label={t('app.newProjectCatalogTemplate')}
                options={catalogTemplateOptions}
                icon={<RectangleGroupIcon />}
                value={selectedTemplateId}
                onChange={(value) => updateSelectedTemplate(value)}
              />
              <FormWatch<ProjectNewFormValidationValues>
                onChange={({ costCatalogId }) => {
                  const template = catalogTemplates?.find((x) => x.catalogTemplateId === selectedTemplateId);
                  if (template && costCatalogId !== template?.costCatalog.id) {
                    setSelectedTemplateId('none');
                  }
                }}
                fieldNames={['costCatalogId']}
              >
                {() => (
                  <FormField name="costCatalogId">
                    {(control) => (
                      <BaseSelect
                        label={t('app.newProjectCostCatalog')}
                        options={costCatalogOptions}
                        icon={<EditNodeIcon />}
                        {...control}
                      />
                    )}
                  </FormField>
                )}
              </FormWatch>
              <FormWatch<ProjectNewFormValidationValues>
                onChange={({ riskCatalogId }) => {
                  const template = catalogTemplates?.find((x) => x.catalogTemplateId === selectedTemplateId);
                  if (template?.riskCatalog && riskCatalogId !== template.riskCatalog.id) {
                    setSelectedTemplateId('none');
                  }
                }}
                fieldNames={['riskCatalogId']}
              >
                {() => (
                  <FormField name="riskCatalogId">
                    {(control) => (
                      <BaseSelect
                        label={t('app.newProjectRiskCatalog')}
                        options={riskCatalogOptions}
                        icon={<EditNodeIcon />}
                        {...control}
                      />
                    )}
                  </FormField>
                )}
              </FormWatch>
              <FormWatch<ProjectNewFormValidationValues>
                onChange={({ earningsCatalogId }) => {
                  const template = catalogTemplates?.find((x) => x.catalogTemplateId === selectedTemplateId);
                  if (template?.earningsCatalog && earningsCatalogId !== template.earningsCatalog.id) {
                    setSelectedTemplateId('none');
                  }
                }}
                fieldNames={['earningsCatalogId']}
              >
                {() => (
                  <FormField name="earningsCatalogId">
                    {(control) => (
                      <BaseSelect
                        label={t('app.newProjectEarningsCatalog')}
                        options={earningsCatalogOptions}
                        icon={<EditNodeIcon />}
                        {...control}
                      />
                    )}
                  </FormField>
                )}
              </FormWatch>
              <FormWatch<ProjectNewFormValidationValues>
                onChange={({ financingCatalogId }) => {
                  const template = catalogTemplates?.find((x) => x.catalogTemplateId === selectedTemplateId);
                  if (template?.financingCatalog && financingCatalogId !== template.financingCatalog.id) {
                    setSelectedTemplateId('none');
                  }
                }}
                fieldNames={['financingCatalogId']}
              >
                {() => (
                  <FormField name="financingCatalogId">
                    {(control) => (
                      <BaseSelect
                        label={t('app.newProjectFinancingCatalog')}
                        options={financingCatalogOptions}
                        icon={<EditNodeIcon />}
                        {...control}
                      />
                    )}
                  </FormField>
                )}
              </FormWatch>
              <FormField name="deliveryPhasesCatalogId">
                {(control) => (
                  <BaseSelect
                    label={t('app.newProjectDeliveryPhasesCatalog')}
                    options={deliveryPhasesCatalogsOptions}
                    icon={<EditNodeIcon />}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="vat">
                {(control) => (
                  <NumberInput
                    label={t('app.newProjectTaxRate')}
                    icon={<PercentageIcon />}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="calculateValueType">
                {(control) => (
                  <BaseSelect
                    label={t('projectCalculate.valueType')}
                    options={valueTypeOptions}
                    icon={<UsDollarCircledIcon />}
                    {...control}
                  />
                )}
              </FormField>
              <CurrencySelect />
              <FormField name="measure">
                {(control) => (
                  <ComboSelect
                    icon={<BuildingIcon className="h-6 w-6" />}
                    label={t('project.measure.label')}
                    options={measureOptions}
                    {...control}
                  />
                )}
              </FormField>
            </WizardSlideContent>
          </Wizard.Slide>

          {/* SLIDE 6 - CUSTOM FIELDS */}
          {mandatoryProjectUserDefinedFieldDefinitions.length > 0 && (
            <Wizard.Slide>
              <WizardSlideHeader
                title={t('app.wizardCustomFields')}
                subTitle={t('app.wizardCustomFieldsDescription')}
              />
              <WizardSlideContent>
                <EditUserDefinedFields
                  className="w-full max-h-[250px]"
                  type="Project"
                  setUpdatePayload={setUdfUpdatePayload}
                  isSubmitted={isCustomFieldFormSubmitted}
                  updateIsValid={setCustomFieldsAreValid}
                  showLabel={false}
                  projectSpecific={false}
                />
              </WizardSlideContent>
            </Wizard.Slide>
          )}

          {/* SLIDE 7 - IMAGE UPLOAD */}
          <Wizard.Slide>
            <WizardSlideHeader
              title={t('app.wizardUploadImage')}
              subTitle={t('app.wizardUploadImageDescription')}
            />
            <WizardSlideContent>
              <div className="bg-white p-2 mb-6 flex-1 h-full">
                <FormField name="coverImage">
                  {(control) => (
                    <FileInput
                      className="h-full"
                      acceptedFileTypes={['image/bmp', 'image/jpeg', 'image/jpg', 'image/png']}
                      multiple={false}
                      {...control}
                      setError={setFileError}
                    />
                  )}
                </FormField>
              </div>
            </WizardSlideContent>
          </Wizard.Slide>

          {/* SLIDE 8 - COMPLETE */}
          <Wizard.Slide>
            <WizardSlideHeader
              icon={<TaskCompletedDottedIcon />}
              title={t('app.wizardNewProjectComplete')}
              subTitle={t('app.wizardNewProjectCompleteDescription')}
            />
          </Wizard.Slide>
        </Wizard.Slides>
        <Wizard.Navigation>
          {({ count, isFirst, isLast, canGoPrevious, canGoNext, previous, next }) => (
            <Modal.Controls
              className={classNames({
                'bg-slate-100': currentStep === 0,
                'bg-white': currentStep !== 0,
              })}
            >
              {(() => {
                if (isFirst) {
                  return (
                    <Button passDataCy={`create-project-cancel-button`} variant="text" onClick={handleCloseModal}>
                      {t('common.cancel')}
                    </Button>
                  );
                } else if (isLast) {
                  return null;
                } else {
                  return (
                    <Button passDataCy={`create-project-back-button`} variant="text" disabled={!canGoPrevious} onClick={previous}>
                      {t('app.newProjectBack')}
                    </Button>
                  );
                }
              })()}
              {(() => {
                if (isLast) {
                  return (
                    <Button passDataCy="create-project-jump-to-project-button" variant="primary" onClick={handleOpenProject}>
                      {t('app.newProjectEndWizard')}
                    </Button>
                  );
                } else if (currentStep === count - 2) {
                  return (
                    <Button passDataCy="create-project-button" variant="primary" onClick={handleSubmit} disabled={fileError}>
                      {t('app.newProjectCreateProject')}
                    </Button>
                  );
                } else {
                  return (
                    <Button passDataCy={`create-project-next-button`} variant="primary" disabled={!canGoNext} onClick={next}>
                      {t('app.newProjectNext')}
                    </Button>
                  );
                }
              })()}
            </Modal.Controls>
          )}
        </Wizard.Navigation>
      </Wizard>
    </Form>
  );
};
