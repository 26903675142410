import { PropsWithChildren } from 'react';
import cn from 'classnames';

export interface WidgetContainerContentProps extends PropsWithChildren {
  className?: string;
}

export const WidgetContainerContent = ({ children, className }: WidgetContainerContentProps) => {
  return <div className={cn('p-3', className)}>{children}</div>;
};
