import { useTranslation } from 'react-i18next';
import { DashboardWidgetProps } from '../WidgetDashboard';
import { AddressIcon, CardDataSet, UserAvatar, WidgetContainer, WidgetContainerContent } from '@client/shared/toolkit';
import { getProjectThumbnailByCoverId } from '../../../utils';
import { useLoadedProject } from '@client/project/store';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import React, { useMemo } from 'react';
import cn from 'classnames';
import { useUi } from '@client/shared/store';
import md5 from 'blueimp-md5';

export const DashboardWidgetProjectInformation = (props: DashboardWidgetProps) => {
  const { layout } = props;
  const { t } = useTranslation();

  const project = useLoadedProject();

  const ui = useUi();
  const user = ui.appUser;
  const tenant = ui.appUser.tenant;

  const projectData = useMemo(() => {
    return project?.data?.project?.payload;
  }, [project?.data?.project?.payload]);

  const thumbnail = useMemo(() => {
    return getProjectThumbnailByCoverId(
      projectData?.id ?? '',
      projectData?.name ?? '',
      'Small',
      projectData?.coverPictureId,
      false,
    );
  }, [projectData]);

  const largeVariant = useMemo(() => {
    return layout?.h && layout.h > 1;
  }, [layout?.h]);

  const hasAddress = useMemo(() => {
    return projectData?.street || projectData?.number || projectData?.postalCode || projectData?.city;
  }, [projectData]);

  // TODO
  const logoUrl = '/api/tenant/avatar?updated=d41d8cd98f00b204e9800998ecf8427e';
  const updateHash = md5(tenant?.avatarLastUpdate ?? '');
  const avatarPath = tenant?.hasAvatar ? `/api/tenant/avatar?updated=${updateHash}` : undefined;

  return (
    <WidgetContainer>
      <WidgetContainerContent className="flex-1 overflow-y-auto flex flex-col">
        {largeVariant ? (
          <div className="relative z-0">
            <LazyLoadImage
              className="h-[200px] w-full object-cover"
              alt={project?.data?.project?.payload?.name}
              src={thumbnail}
            />
            <div className="z-10 absolute left-0 bottom-0 w-full max-h-[200px] overflow-y-auto">
              <div className="bg-white mx-3.5 inline-flex flex-col rounded-t-md py-1.5 pl-3.5 pr-10 shadow-xl">
                <div>
                  <span className="font-bold text-[22px]">{projectData?.name}&nbsp;|&nbsp;</span>
                  <span className="font-light text-[22px]">{projectData?.city}</span>
                </div>
                <span className="font-light text-[15px]">
                  {t('app.editProjectId')}: {projectData?.projectId}
                </span>
              </div>
            </div>
            {logoUrl && (
              <div className="z-10 absolute top-3.5 right-3.5 left-auto w-20 h-20 bg-white rounded-full flex items-center justify-center overflow-hidden p-2">
                <img src={logoUrl} className="w-full h-auto object-contain" alt={projectData?.name} />
              </div>
            )}
          </div>
        ) : (
          <div className="flex justify-between">
            <div>
              <div>
                <span className="font-bold text-[22px]">{projectData?.name}&nbsp;|&nbsp;</span>
                <span className="font-light text-[22px]">{projectData?.city}</span>
              </div>
              <span className="font-light text-[15px]">
                {t('app.editProjectId')}: {projectData?.projectId}
              </span>
            </div>
            {logoUrl && (
              <div className="w-20 h-20 bg-white rounded-full flex items-center justify-center overflow-hidden p-2 border-slate-300 border-2 flex-none">
                <img src={logoUrl} className="w-full h-auto object-contain" alt={projectData?.name} />
              </div>
            )}
          </div>
        )}
        <div className={cn('flex-1 relative z-10 bg-white w-full', { 'pt-3': largeVariant })}>
          {largeVariant && (
            <div className="flex gap-2 w-full">
              {hasAddress && (
                <div className="flex gap-3 w-1/2">
                  <AddressIcon className="self-start w-6 h-auto pt-1" />
                  <CardDataSet label={t('common.address')}>
                    <div className="font-bold text-[15px]">
                      {(projectData?.street || projectData?.number) && (
                        <>
                          {`${projectData?.street ?? '-'}${projectData?.number ? ' ' : ''}${projectData?.number}`}
                          <br />
                        </>
                      )}
                      {(projectData?.postalCode || projectData?.city) && (
                        <>
                          {`${projectData?.postalCode ?? '-'}${projectData?.city ? ' ' : ''}${projectData?.city}`}
                          <br />
                        </>
                      )}
                    </div>
                  </CardDataSet>
                </div>
              )}
              <CardDataSet label={t('dashboard.widget.projectInfo.type')} title="Fit-Out Office" className="w-1/2" />
            </div>
          )}
          <div className="flex gap-10 mt-2 justify-between w-full">
            {user && (
              <CardDataSet
                label={t('dashboard.widget.projectInfo.projectManager')}
                className={cn('flex-none', { 'pl-6 ml-3': largeVariant && hasAddress })}
              >
                <div className="flex gap-3.5 mt-0.5 items-center">
                  <UserAvatar
                    userName={user.fullName ?? ''}
                    userId={user.userId ?? ''}
                    lastUpdate={user.lastUpdate ?? ''}
                    hasAvatar={user.hasAvatar ?? false}
                    classNames="w-10 h-10"
                  />
                  <CardDataSet title={user.fullName} text="JLL Projektleiter" />
                </div>
              </CardDataSet>
            )}
            {!largeVariant && (
              <CardDataSet
                label={t('dashboard.widget.projectInfo.type')}
                title="Fit-Out Office"
                className="flex-none"
              />
            )}
            <CardDataSet label={t('dashboard.widget.projectInfo.by')} className="flex-none w-20">
              {tenant?.hasAvatar ? (
                <LazyLoadImage
                  src={avatarPath}
                  alt={tenant?.name}
                  aria-hidden={true}
                  className="w-auto h-full object-contain"
                  width={80}
                />
              ) : (
                <CardDataSet title={tenant?.name ?? '-'} />
              )}
            </CardDataSet>
          </div>
        </div>
      </WidgetContainerContent>
    </WidgetContainer>
  );
};
