import classNames from 'classnames';
import { WidgetContainer, WidgetContainerContent, WidgetContainerTitle } from '.';
import { LoadingIndicator } from '..';
import { t } from 'i18next';

export interface MatrixCellData {
  topValue?: string | number;
  bottomValue: string | number;
}

interface WidgetMatrixProps {
  title?: string;
  isLoading?: boolean;
  data: MatrixCellData[][];
  verticalTitle?: string;
  horizontalTitle?: string;
  reverse?: boolean;
}

export const WidgetMatrix = ({
  title,
  isLoading = false,
  data,
  verticalTitle,
  horizontalTitle,
  reverse = false,
}: WidgetMatrixProps) => {
  const cellTitles = [
    t('project.dashboard.widget.matrix.high'),
    t('project.dashboard.widget.matrix.medium'),
    t('project.dashboard.widget.matrix.low'),
  ];

  const cols = reverse ? [...cellTitles].reverse() : cellTitles;
  const rows = reverse ? cellTitles : [...cellTitles].reverse();

  const colorScheme = reverse
    ? [
        ['bg-yellow-200', 'bg-green-300', 'bg-green-300'],
        ['bg-red-300', 'bg-yellow-200', 'bg-green-300'],
        ['bg-red-300', 'bg-red-300', 'bg-yellow-200'],
      ]
    : [
        ['bg-yellow-200', 'bg-red-300', 'bg-red-300'],
        ['bg-green-300', 'bg-yellow-200', 'bg-red-300'],
        ['bg-green-300', 'bg-green-300', 'bg-yellow-200'],
      ];

  return (
    <WidgetContainer>
      {isLoading ? (
        <LoadingIndicator className="p-4" />
      ) : (
        <>
          {title && <WidgetContainerTitle>{title}</WidgetContainerTitle>}
          <WidgetContainerContent>
            <div className="px-5 h-full overflow-auto">
              <div className="flex justify-end mb-5">
                <div className={classNames(verticalTitle ? 'w-8/12' : 'w-9/12')}>
                  {horizontalTitle && (
                    <div className="w-full flex justify-center text-center mb-2 font-bold text-slate-600">
                      {horizontalTitle}
                    </div>
                  )}
                  <div className="grid grid-cols-3">
                    {cols.map((col, idx) => (
                      <div key={idx} className="text-center font-medium p-2">
                        {col}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="flex">
                {verticalTitle && (
                  <div className="flex justify-center w-1/12 [writing-mode:vertical-lr] rotate-180 font-bold text-slate-600">
                    {verticalTitle}
                  </div>
                )}
                <div className={'grid grid-rows-3 w-3/12'}>
                  {rows.map((row, idx) => (
                    <div key={idx} className="flex items-center justify-center font-medium p-2">
                      {row}
                    </div>
                  ))}
                </div>

                <div className={classNames('grid grid-rows-3 grid-cols-3 gap-[11px]', verticalTitle ? 'w-8/12' : 'w-9/12')}>
                  {data.flat().map((cell, idx) => (
                    <div
                      key={idx}
                      className={`flex flex-col items-center justify-center rounded py-2 px-4 ${
                        colorScheme[idx % 3][Math.floor(idx / 3)]
                      }`}
                    >
                      <span className="font-bold text-lg">{cell.topValue}</span>
                      {cell.bottomValue && <span className="text-xs">{cell.bottomValue}</span>}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </WidgetContainerContent>
        </>
      )}
    </WidgetContainer>
  );
};
