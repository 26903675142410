import { useTranslation } from 'react-i18next';
import { DashboardWidgetProps } from '../WidgetDashboard';
import { WidgetContainer, WidgetContainerContent, WidgetContainerTitle } from '@client/shared/toolkit';

/**
 * Risk and Mitigation List Widget
 */
export const DashboardWidgetRisksList = (props: DashboardWidgetProps) => {
  const { widget } = props;
  const { t } = useTranslation();
  return (
    <WidgetContainer>
      <WidgetContainerTitle>{widget.title ? widget.title : t('dashboard.widget.risksList.title')}</WidgetContainerTitle>
      <WidgetContainerContent className="flex-1 overflow-y-auto">
        Risks Lists
      </WidgetContainerContent>
    </WidgetContainer>
  )
};
