import { AddOutlinedIcon } from '@client/shared/toolkit';
import { MouseEvent } from 'react';

export const DashboardWidgetPlaceholder = ({ handleOnClick }: { handleOnClick?: (e: MouseEvent) => void }) => {
  return (
    <div
      className="bg-slate-900 bg-opacity-5 hover:bg-opacity-10 transition-all duration-300 hover:shadow-inner h-full flex items-center justify-center cursor-pointer rounded"
      onClick={handleOnClick}
    >
      <AddOutlinedIcon className="w-14 text-white" />
    </div>
  );
};
