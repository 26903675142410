import {
  dashboardConfig,
  DashboardConfigKeys,
  DashboardWidgetType,
  widgets,
  WidgetSizeType,
} from './widgetDashboardConfig';
import { Widget, WidgetConfig } from '../WidgetDashboard';
import {
  CashOutWidgetReadModel,
  CustomDashboardReadModel,
  MapViewWidgetReadModel,
  ReportDashboardReadModel,
  TextboxWidgetReadModel,
  TrafficLightsWidgetReadModel
} from '@client/shared/api';
import { Layout, Layouts } from 'react-grid-layout';

/**
 * Gets the configuration of a widget for a given type.
 * Each widget has e.g. its own sizes.
 * @param type The widget type.
 */
export const getWidgetConfigurationForType = (type: DashboardWidgetType): WidgetConfig | null => {
  const foundWidgetConfig = Object.entries(widgets).find((widgetConfig) => {
    return widgetConfig[1].type === type;
  });
  return foundWidgetConfig ? foundWidgetConfig[1] : null;
};

export const getPositionFromIndex = (index: number, cols: number) => {
  return {
    x: index % cols,
    y: Math.floor(index / cols),
  };
};

type WidgetReadModel = CashOutWidgetReadModel | TextboxWidgetReadModel | MapViewWidgetReadModel;

/**
 * Maps the dashboard data from the Backend to the ReactGrid Layout.
 * All cells that are not filled with a widget are filled with placeholder widgets.
 *
 * @param type The dashboard type (custom or report dashboard).
 * @param dashboardWidgets The widgets saved in the Backend for this dashboard.
 */
export const mapDashboardWidgetsToGridLayout = (
  type: DashboardConfigKeys,
  dashboardWidgets?: CustomDashboardReadModel | ReportDashboardReadModel,
) => {
  const mappedLayout: Widget[] = [];
  // map to our layout data
  const dashboardLayoutConfig = dashboardConfig[type];
  const widgetProps = dashboardWidgets?.widgetProperties;
  const { rows, cols = { lg: 0 } } = dashboardLayoutConfig;
  const totalCells = rows * cols.lg;
  const filledCells = Array(totalCells).fill(false);

  if (dashboardWidgets && widgetProps) {
    widgetProps.forEach((property) => {
      const widgetProp = property.charAt(0).toLowerCase() + property.slice(1);
      if (widgetProp in dashboardWidgets) {
        const widgetsAtWidgetProp = dashboardWidgets[widgetProp as keyof (CustomDashboardReadModel | ReportDashboardReadModel)];
        if (Array.isArray(widgetsAtWidgetProp) && widgetsAtWidgetProp.length > 0) {
          widgetsAtWidgetProp.forEach((widget) => {
            const dashboardWidget = widget as WidgetReadModel;
            for (
              let row = dashboardWidget.startingPoint.row;
              row < dashboardWidget.startingPoint.row + dashboardWidget.size.amountOfRows;
              row++
            ) {
              for (
                let col = dashboardWidget.startingPoint.column;
                col < dashboardWidget.startingPoint.column + dashboardWidget.size.amountOfColumns;
                col++
              ) {
                const filledCellIndex = row * cols.lg + col;
                filledCells[filledCellIndex] = true;
              }
            }

            const widgetType = getWidgetTypeForReadModelProp(widgetProp);

            let widgetSizes = null;
            const widgetConfiguration = getWidgetConfigurationForType(widgetType);
            if (widgetConfiguration) {
              widgetSizes = widgetConfiguration.sizes;
            }
            const maxDimensions = widgetSizes
              ? widgetSizes.reduce(
                  (acc, size) => {
                    acc.maxW = Math.max(acc.maxW, size.w);
                    acc.maxH = Math.max(acc.maxH, size.h);
                    return acc;
                  },
                  { maxW: 1, maxH: 1 },
                )
              : { maxW: 1, maxH: 1 };

            mappedLayout.push({
              layout: {
                x: dashboardWidget.startingPoint.column,
                y: dashboardWidget.startingPoint.row,
                w: dashboardWidget.size.amountOfColumns,
                maxW: maxDimensions.maxW,
                h: dashboardWidget.size.amountOfRows,
                maxH: maxDimensions.maxH,
                i: dashboardWidget.id,
              },
              widget: {
                // TODO this needs to be improved!
                type: widgetType,
                id: dashboardWidget.id,
                title: dashboardWidget.name,
                text: widgetProp === 'textboxWidgets' ? (dashboardWidget as TextboxWidgetReadModel).text : undefined,
                lat: widgetProp === 'mapViewWidgets' ? (dashboardWidget as MapViewWidgetReadModel).lat : undefined,
                lng: widgetProp === 'mapViewWidgets' ? (dashboardWidget as MapViewWidgetReadModel).lng : undefined,
                trafficLights: widgetProp === 'trafficLightsWidgets' ? (dashboardWidget as TrafficLightsWidgetReadModel).trafficLights : undefined,
                sizes: widgetSizes || []
              },
            });
          });
        }
      }
    });
  }

  /* // FOR TESTING
  mappedLayout.push({
    layout: {
      x: 0,
      y: 0,
      w: 1,
      h: 2,
      i: 'projectInformation'
    },
    widget: {
      type: DashboardWidgetType.ProjectInformation
    }
  });

  mappedLayout.push({
    layout: {
      x: 0,
      y: 1,
      w: 1,
      h: 1,
      i: 'projectInformation2'
    },
    widget: {
      type: DashboardWidgetType.ProjectInformation
    }
  });

  mappedLayout.push({
    layout: {
      x: 1,
      y: 0,
      w: 1,
      h: 2,
      i: 'risksMatrix'
    },
    widget: {
      type: DashboardWidgetType.RiskMatrix
    }
  });

  mappedLayout.push({
    layout: {
      x: 2,
      y: 0,
      w: 1,
      h: 1,
      i: 'kpi'
    },
    widget: {
      type: DashboardWidgetType.KPI
    }
  }); */

  // fill in empty cells with placeholder widget
  filledCells.forEach((filledCell, index) => {
    if (!filledCell) {
      const key = `placeholder-${index}`;
      const y = Math.floor(index / cols.lg);
      const x = index % cols.lg;
      mappedLayout.push({
        layout: {
          i: key,
          x: x,
          y: y,
          h: 1,
          w: 1,
          isResizable: false,
          isDraggable: false,
          // static: true
        },
        widget: {
          type: DashboardWidgetType.Placeholder,
          id: key,
        },
      });
    }
  });

  return mappedLayout;
};

/**
 * Returns the widget type for a given Backend ReadModel property (CashOutWidgetReadModel).
 * @param widgetProp The widgets list property. Different for each type. So one widget type should be mapped to a list in the ReadModel.
 */
export const getWidgetTypeForReadModelProp = (widgetProp: string) => {
  switch (widgetProp) {
    case 'calculateBudgetTableWidgets':
      return DashboardWidgetType.CalculateBudgetTable;
    case 'cashOutWidgets':
      return DashboardWidgetType.CashOutPlan;
    case 'kpiWidgets':
      return DashboardWidgetType.KPI;
    case 'mapViewWidgets':
        return DashboardWidgetType.MapView;
    case 'projectInformationWidgets':
      return DashboardWidgetType.ProjectInformation;
    case 'riskMatrixWidgets':
      return DashboardWidgetType.RiskMatrix;
    case 'risksListWidgets':
      return DashboardWidgetType.RisksList;
    case 'tasksWidgets':
      return DashboardWidgetType.Tasks;
    case 'textboxWidgets':
      return DashboardWidgetType.TextBox;
    case 'timelineWidgets':
      return DashboardWidgetType.Timeline;
    case 'trafficLightsWidgets':
      return DashboardWidgetType.TrafficLight;
    default:
      return DashboardWidgetType.Placeholder;
  }
};

/**
 * Helper function to find an empty cell in the layout.
 * @param layout The grid layout.
 * @param rows The available number of rows.
 * @param cols The available number of cols.
 */
export const findEmptyCell = (layout: Layout[], rows: number, cols: number) => {
  const totalCells = rows * cols;
  const filledCells = Array(totalCells).fill(false);

  layout.forEach(({ x, y, w, h }) => {
    for (let row = y; row < y + h; row++) {
      for (let col = x; col < x + w; col++) {
        filledCells[row * cols + col] = true;
      }
    }
  });

  const emptyCellIndex = filledCells.findIndex((cell) => !cell);
  if (emptyCellIndex !== -1) {
    return {
      x: emptyCellIndex % cols,
      y: Math.floor(emptyCellIndex / cols),
    };
  }

  return null; // No empty cells found
};

/**
 * Compares two layouts to check if there are changes. Undefined values are not considered.
 * @param layoutA
 * @param layoutB
 */
export const areLayoutsEqual = (layoutA: Layout[], layoutB: Layout[]): boolean => {
  if (layoutA.length !== layoutB.length) return false;

  const layoutMapA = new Map(layoutA.map((item) => [item.i, item]));
  const layoutMapB = new Map(layoutB.map((item) => [item.i, item]));

  for (const [key, itemA] of layoutMapA) {
    const itemB = layoutMapB.get(key);
    if (!itemB || itemA.x !== itemB.x || itemA.y !== itemB.y || itemA.w !== itemB.w || itemA.h !== itemB.h) {
      return false;
    }
  }

  return true;
};

/**
 * The fallback widget size each widget does have.
 */
export const DEFAULT_WIDGET_SIZE: WidgetSizeType = {
  w: 1,
  h: 1,
};

/**
 * Gets the allowed resize sizes for a given widget type
 * @param widget The widget that wants to get resized. Each widget type has its own size configuration.
 * @param layout The current layout where the widget is place in.
 * @param gridType The type of the grid.
 */
export const getWidgetAllowedSizesInLayout = (widget: Widget, layout: Layouts, gridType: DashboardConfigKeys) => {
  const isPositionOccupied = (x: number, y: number, id: string) => {
    return layout.lg.some((item: Layout) =>
      !item.i.includes('placeholder-') && // ignore the placeholder widget
      item.i !== id && // ignore the current widget itself
      x >= item.x && x < item.x + item.w &&
      y >= item.y && y < item.y + item.h
    );
  };

  // Grid config
  const gridConfig = dashboardConfig[gridType];
  const maxCols = gridConfig.cols?.lg ?? 0;
  const maxRows = gridConfig.rows;

  const allowedSizes: WidgetSizeType[] = [DEFAULT_WIDGET_SIZE];

  // Widget config
  const allowedWidgetSizes = getWidgetConfigurationForType(widget.widget.type)?.sizes;
  if (allowedWidgetSizes && widget.layout) {

    // Check if the item exceeds grid boundaries
    const availableSizes = allowedWidgetSizes.map((size) => {
      const disabled = false;
      // Check if item exceeds the grid boundaries
      if (widget.layout.x + size.w > maxCols || widget.layout.y + size.h > maxRows) {
        return { ...size, disabled: true };
      } else {
        // Check if any position within the potential new widget's area is occupied
        for (let i = widget.layout.x; i < widget.layout.x + size.w; i++) {
          for (let j = widget.layout.y; j < widget.layout.y + size.h; j++) {
            if (isPositionOccupied(i, j, widget.layout.i)) {
              return { ...size, disabled: true };
            }
          }
        }
      }
      return { ...size, disabled };
    });

    return availableSizes ?? allowedSizes;
  }
  return allowedSizes;
};
