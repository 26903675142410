import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Form,
  TextInput,
  FormField,
  FormRefHandle,
  LoadingIndicator,
  BaseSelect,
} from '@client/shared/toolkit';
import {
  ProbisErrorDataType,
  useApiGetTenantInviteQuery,
  useApiPostAcceptTenantInviteMutation,
  useApiPostCheckSessionMutation,
  useApiPostInviteSignupMutation,
  useApiPostLoginMutation,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import {
  AcceptTrustedTenantFormValidationSchema,
  AcceptTrustedTenantFormValidationValues,
  RegisterTrustedTenantFormValidationSchema,
  RegisterTrustedTenantFormValidationValues,
} from './RegisterTrustedTenantFormValidationSchema';
import { safeMutation } from '@client/shared/utilities';
import { useUi } from '@client/shared/store';
import toast from 'react-hot-toast';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { EyeSlashIcon, EyeIcon } from '@heroicons/react/24/solid';

export const RegisterTrustedTenantView = () => {
  const { t } = useTranslation();

  const { token } = useParams<{ token: string }>();
  const formRef = useRef<FormRefHandle<RegisterTrustedTenantFormValidationValues>>(null);
  const acceptFormRef = useRef<FormRefHandle<AcceptTrustedTenantFormValidationValues>>(null);

  const navigate = useNavigate();
  const ui = useUi();

  const [accept, { isLoading: isAccepting }] = useApiPostAcceptTenantInviteMutation();
  const [register, { isLoading: isRegistering }] = useApiPostInviteSignupMutation();
  const [loginCall, { isLoading: isLoggingIn }] = useApiPostLoginMutation();
  const [callCheckSession, { isLoading: isCheckingSession }] = useApiPostCheckSessionMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const [isTenantError, setIsTenantError] = useState(false);
  const [isUserError, setIsUserError] = useState(false);

  const {
    data: invitationData,
    isFetching,
    isError,
  } = useApiGetTenantInviteQuery({
    token: token ?? '',
  });

  if (isError) {
    navigate('/auth/login');
  }

  useEffect(() => {
    if (invitationData) {
      acceptFormRef?.current?.setValue('email', invitationData.trustInvite.inviteeMail);
      formRef?.current?.setValue('email', invitationData.trustInvite.inviteeMail);
      formRef?.current?.setValue('company', invitationData.trustInvite.inviteeCompany);
      const fullName = invitationData.trustInvite.inviteePerson;
      const lastSpaceIndex = fullName.lastIndexOf(' ');
      formRef?.current?.setValue('firstName', fullName.substring(0, lastSpaceIndex));
      formRef?.current?.setValue('lastName', fullName.substring(lastSpaceIndex + 1));
    }
  }, [invitationData]);

  const handleRegisterSubmit = async (data: RegisterTrustedTenantFormValidationValues) => {
    if (invitationData?.trustInvite.inviteToken) {
      setIsTenantError(false);
      setIsUserError(false);
      try {
        await safeMutation(
          register,
          {
            body: {
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              password: data.password,
              tenantName: data.company,
              inviteToken: invitationData.trustInvite.inviteToken,
            },
          },
          isRegistering,
        );
        await doLogin(data.email, data.password);
      } catch (e) {
        const error = e as FetchBaseQueryError;
        const data = error.data as ProbisErrorDataType;
        if (data?.code === 'error.tenant.subdomain_exists') {
          setIsTenantError(true);
        } else if (data?.code === 'error.user.already_exists') {
          setIsUserError(true);
        }
      }
    }
  };

  const handleAccept = async (data: AcceptTrustedTenantFormValidationValues) => {
    if (invitationData?.trustInvite.inviteToken) {
      try {
        await safeMutation(
          accept,
          {
            body: {
              inviteToken: invitationData.trustInvite.inviteToken,
            },
          },
          isAccepting,
        );
        toast.success(t('auth.tenantTrustInvitationAcceptSuccess'));
        navigate(ROUTES_CONFIG.PROJECTS.path);
      } catch {
        // intentionally left blank
      }
    }
  };

  const doLogin = useCallback(
    async (email: string, password: string) => {
      try {
        await loginCall({
          body: {
            password,
            email,
          },
        }).unwrap();
        await callCheckSession();
        navigate(ROUTES_CONFIG.PROJECTS.path);
      } catch {
        // intentionally left blank
      }
    },
    [loginCall, navigate, callCheckSession],
  );

  useEffect(() => {
    acceptFormRef?.current?.setValue('tenant', ui?.appUser?.tenants?.length ? ui.appUser.tenants[0].tenantId : '');
  }, [ui?.appUser?.tenants]);

  const tenantOptions = useMemo(() => {
    if (ui) {
      const tenants = ui.appUser?.tenants;
      return (
        tenants?.map((tenant) => {
          return {
            value: tenant.tenantId,
            label: tenant.name,
          };
        }) ?? []
      );
    }
    return [];
  }, [ui]);

  const defaultFormValues = {
    email: '',
    company: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirmation: '',
  };

  const loggedInDefaultFormValues = {
    email: '',
    tenant: '',
  };

  return (
    <>
      {isFetching && <LoadingIndicator text={t('app.settingsTrustedTenantsInvitationLoading') ?? ''} />}
      {(isAccepting || isRegistering || isLoggingIn || isCheckingSession) && (
        <LoadingIndicator text={t('app.settingsTrustedTenantsAcceptingInvitation') ?? ''} mode="overlay-window" />
      )}
      <div className="flex flex-row items-center justify-between mt-3 mb-6">
        <h1 className="text-4xl font-medium text-gray-600">
          {ui.appUser?.userId ? t('auth.acceptTenantTrustInvitation') : t('auth.registerTitle')}
        </h1>
      </div>
      {ui.appUser?.userId ? (
        <Form<AcceptTrustedTenantFormValidationValues>
          onSubmit={handleAccept}
          validationSchema={AcceptTrustedTenantFormValidationSchema}
          defaultValues={loggedInDefaultFormValues}
          ref={acceptFormRef}
        >
          <FormField name="email">
            {(control) => <TextInput label={t('common.email')} disabled={isFetching} {...control} />}
          </FormField>

          <FormField name="tenant">
            {(control) => (
              <BaseSelect
                label={t('app.menuSectionTeam')}
                disabled={isFetching}
                options={tenantOptions}
                {...control}
              />
            )}
          </FormField>

          <div className="pt-16 flex justify-between">
            <Button
              variant="secondary"
              onClick={(evt) => {
                evt.preventDefault();
                navigate('/auth/login');
              }}
            >
              {t('common.cancel')}
            </Button>

            <Button variant="primary" disabled={isFetching} formSubmit={true}>
              {t('auth.acceptTenantTrustInvitation')}
            </Button>
          </div>
        </Form>
      ) : (
        <Form<RegisterTrustedTenantFormValidationValues>
          onSubmit={handleRegisterSubmit}
          validationSchema={RegisterTrustedTenantFormValidationSchema}
          defaultValues={defaultFormValues}
          ref={formRef}
        >
          <div className="divide-gray-100 divide-y">
            <FormField name="email">
              {(control) => (
                <TextInput
                  label={t('common.email')}
                  disabled={isFetching}
                  {...control}
                  isValidationValid={!isUserError && control.isValidationValid}
                  showValidation={isUserError || control.showValidation}
                  helperText={isUserError ? t('auth.tenantTrustInvitationUserExistsError') : control.helperText}
                />
              )}
            </FormField>
            <FormField name="company">
              {(control) => (
                <TextInput
                  label={t('app.masterDataCompany')}
                  disabled={isFetching}
                  {...control}
                  isValidationValid={!isTenantError && control.isValidationValid}
                  showValidation={isTenantError || control.showValidation}
                  helperText={isTenantError ? t('auth.tenantTrustInvitationTenantExistsError') : control.helperText}
                />
              )}
            </FormField>
            <FormField name="firstName">
              {(control) => (
                <TextInput label={t('common.firstName')} disabled={isFetching} autoComplete="given-name" {...control} />
              )}
            </FormField>
            <FormField name="lastName">
              {(control) => (
                <TextInput label={t('common.lastName')} disabled={isFetching} autoComplete="family-name" {...control} />
              )}
            </FormField>
            <FormField name="password">
              {(control) => (
                <div className="flex justify-between items-center bg-white" onBlur={() => setShowPassword(false)}>
                  <TextInput
                    label={t('common.password')}
                    disabled={isFetching}
                    inputType={showPassword ? 'text' : 'password'}
                    autoComplete="new-password"
                    className="w-full"
                    {...control}
                  />
                  <div
                    className="w-5 cursor-pointer mx-2"
                    onClick={() => setShowPassword(!showPassword)}
                    data-cy="button-show-password"
                  >
                    {showPassword ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
                  </div>
                </div>
              )}
            </FormField>
            <FormField name="passwordConfirmation">
              {(control) => (
                <div
                  className="flex justify-between items-center bg-white"
                  onBlur={() => setShowPasswordConfirmation(false)}
                >
                  <TextInput
                    label={t('auth.passwordConfirmation')}
                    disabled={isFetching}
                    inputType={showPasswordConfirmation ? 'text' : 'password'}
                    autoComplete="new-password"
                    className="w-full"
                    {...control}
                  />
                  <div
                    className="w-5 cursor-pointer mx-2"
                    onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                    data-cy="button-show-password"
                  >
                    {showPasswordConfirmation ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
                  </div>
                </div>
              )}
            </FormField>
          </div>

          <div className="pt-16 flex justify-between">
            <Button
              variant="secondary"
              onClick={(evt) => {
                evt.preventDefault();
                navigate('/auth/login');
              }}
            >
              {t('common.cancel')}
            </Button>

            <Button variant="primary" disabled={isFetching} formSubmit={true}>
              {t('auth.registerAction')}
            </Button>
          </div>
          <div className="mt-4 text-right test-xs italic text-secondary hover:text-sky-700 transition-colors duration-200 cursor-pointer">
            <NavLink
              to={`/auth/login?_r=${encodeURIComponent(
                `/auth/register-trusted-company/${invitationData?.trustInvite.inviteToken ?? ''}`,
              )}`}
            >
              {t('auth.alreadyHaveAnAccount')}
            </NavLink>
          </div>
        </Form>
      )}
    </>
  );
};
