import classNames from 'classnames';
import { PropsWithChildren } from 'react';

export type BadgeVariants = 'success' | 'info' | 'danger' | 'primary' | 'secondary' | 'lightSuccess' | 'lightInfo' | 'custom';

export interface BadgeProps extends PropsWithChildren {
  text: string;
  className?: string;
  variant?: BadgeVariants;
  side?: 'left' | 'right';
  onClick?: () => void;
}

const variantStyles = {
  success: 'text-white bg-green-600',
  info: 'text-white bg-sky-600',
  danger: 'text-white bg-red-600',
  secondary: 'text-white bg-gray-600',
  primary: 'text-white bg-primary',
  lightSuccess: 'text-white bg-green-400',
  lightInfo: 'text-white bg-sky-400',
  custom: ''
};

export const Badge = ({ text, className, variant = 'primary', onClick, children }: BadgeProps) => (
  <span
    className={classNames(
      'inline-flex gap-1 items-center px-2 text-xs font-semibold leading-5 rounded-full truncate',
      variantStyles[variant],
      className,
      {
        'cursor-pointer': onClick
      }
    )}
    onClick={onClick}
  >
    {text}
    {children}
  </span>
);
