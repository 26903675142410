/// <reference types="vite/client" />
/// ^-> the above need to be included to type 'import.meta.env'
///     see https://vitejs.dev/guide/env-and-mode.html#intellisense-for-typescript
import { settings } from '@client/shared/store';

// extend window typing with our variable
import queryString from 'query-string';

declare global {
  interface Window {
    env: { [name: string]: string };
  }
}

function getEnv(envName: string, default1: string | undefined, default2: string): string {
  // see if we have it in the window object
  if (window != null && window.env != null && window.env[envName] != null) return window.env[envName];

  const query = queryString.parse(window.location.search);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (query[envName] != null) return query[envName] as any as string;

  // in environment variables?
  if (default1 != null) return default1;

  // fall back to user specified default value
  return default2;
}

export const initSettings = () => {
  settings.mainDomain = getEnv('VITE_MAIN_DOMAIN', undefined, 'probis.test');
  settings.enableSentry = getEnv('VITE_ENABLE_SENTRY', undefined, 'false') === 'true';

  // reflect ASPNETCORE_ENVIRONMENT
  settings.sentryEnvironment = getEnv('VITE_SENTRY_ENVIRONMENT', undefined, 'local.development');

  settings.clarityId = getEnv('VITE_CLARITY_ID', undefined, '');

  /* set by azure pipelines */
  settings.buildId = getEnv('VITE_BUILD_ID', undefined, '(unknown)');

  /* set by azure pipelines */
  settings.devMode = getEnv('VITE_DEV_MODE', import.meta.env.VITE_DEV_MODE, 'false') === 'true';

  settings.queryDelay = getEnv('VITE_QUERY_DELAY', undefined, '');
  settings.googleMapsApiKey = getEnv('VITE_GOOGLE_MAPS_API_KEY', import.meta.env.VITE_GOOGLE_MAPS_API_KEY, '');
};
