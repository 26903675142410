import {
  BillIcon,
  DesignIcon,
  DiggerIcon,
  FlowChartIcon,
  IncreaseIcon,
  LoadingIcon,
  MoneyBagIcon,
  OrganizationIcon,
  TimesheetIcon,
  TrafficLight,
  WarrantyIcon,
  WidgetContainer,
  WidgetContainerContent,
  WidgetContainerTitle,
} from '@client/shared/toolkit';
import { MetricType } from '@client/shared/api';
import { DashboardWidgetProps } from '../WidgetDashboard';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

export const getTrafficLightIconByName = (name: MetricType) => {
  switch (name) {
    case 'OverallStatus':
      return <LoadingIcon className="w-5 h-5" />;
    case 'ProjectOrganization':
      return <FlowChartIcon className="w-5 h-5" />;
    case 'Risks':
      return <IncreaseIcon className="w-5 h-5" />;
    case 'Costs':
      return <MoneyBagIcon className="w-5 h-5" />;
    case 'Deadlines':
      return <TimesheetIcon className="w-5 h-5" />;
    case 'Qualities':
      return <WarrantyIcon className="w-5 h-5" />;
    case 'Awarding':
      return <BillIcon className="w-5 h-5" />;
    case 'Marketing':
      return <OrganizationIcon className="w-5 h-5" />;
    case 'PlanningStatus':
      return <DesignIcon className="w-5 h-5" />;
    case 'ConstructionStatus':
      return <DiggerIcon className="w-5 h-5" />;
  }
};

export const DashboardWidgetTrafficLight = (props: DashboardWidgetProps) => {
  const { widget } = props;
  const { t } = useTranslation();

  return (
    <WidgetContainer>
      <WidgetContainerTitle>
        {widget.title ? widget.title : t('dashboard.widget.trafficLight.title')}
      </WidgetContainerTitle>
      <WidgetContainerContent className="flex-1">
        <div
          className={cn('flex items-center h-full mx-8', {
            'gap-24': widget.trafficLights && widget.trafficLights.length > 2,
            'gap-44': widget.trafficLights && widget.trafficLights.length === 2,
          })}
        >
          {widget.trafficLights?.map((trafficLight, index) => (
            <TrafficLight
              key={`traffic-light-${index}`}
              title={trafficLight.name}
              value={trafficLight.factor}
              icon={getTrafficLightIconByName(trafficLight.metricType)}
              color={trafficLight.color === 'Green' ? 'green' : trafficLight.color === 'Red' ? 'red' : 'yellow'}
            />
          ))}
        </div>
      </WidgetContainerContent>
    </WidgetContainer>
  );
};
