import { WidgetContainer, WidgetContainerContent, WidgetContainerTitle } from '.';
import { LoadingIndicator } from '..';
import { Chart } from 'react-chartjs-2';
import type { ChartData, ChartOptions, ChartType } from 'chart.js';

export interface ChartCellData {
  label?: string | number | React.ReactNode;
  value: string | number | React.ReactNode;
}

interface WidgetChartProps {
  title?: string;
  isLoading?: boolean;
  chartData: ChartData;
  chartOptions?: ChartOptions;
  type?: ChartType;
  className?: string;
}

export const WidgetChart = ({
  title,
  isLoading = false,
  chartData,
  chartOptions,
  type = 'bar' as ChartType,
  className,
}: WidgetChartProps) => {

  return (
    <WidgetContainer>
      {isLoading ? (
        <LoadingIndicator className="p-4" />
      ) : (
        <>
          {title && <WidgetContainerTitle>{title}</WidgetContainerTitle>}
          <WidgetContainerContent className={className}>
            <Chart options={chartOptions} type={type} data={chartData} />
          </WidgetContainerContent>
        </>
      )}
    </WidgetContainer>
  );
};
