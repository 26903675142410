import { WidgetContainer, WidgetContainerContent, WidgetContainerTitle } from '@client/shared/toolkit';
import { DashboardWidgetProps } from '../WidgetDashboard';
import { useTranslation } from 'react-i18next';
import { CashOutChart } from '../../Charts';

export const DashboardWidgetCashOutPlan = (props: DashboardWidgetProps) => {
  const {
    widget,
    layout
  } = props;
  const { t } = useTranslation();
  return (
    <WidgetContainer className="flex flex-col">
      <WidgetContainerTitle>
        {widget.title ? widget.title : t('dashboard.widget.cashOutPlan.title')}
      </WidgetContainerTitle>
      <WidgetContainerContent className="flex-1">
        <CashOutChart variant="compact" height={layout?.w === 1 ? 105 : undefined} />
      </WidgetContainerContent>
    </WidgetContainer>
  )
}
