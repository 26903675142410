import { useTranslation } from 'react-i18next';
import { MatrixCellData, WidgetMatrix } from '@client/shared/toolkit';
import { DashboardWidgetProps } from '../WidgetDashboard';
import { useMemo } from 'react';

export const DashboardWidgetRiskMatrix = (props: DashboardWidgetProps) => {
  const { widget } = props;
  const { t } = useTranslation();

  const matrixData = useMemo(() => {
    let matrixCells: MatrixCellData[][] = [];
    if (widget) {
      matrixCells = [
        [
          { topValue: 10, bottomValue: 10 },
          { topValue: 10, bottomValue: 10 },
          { topValue: 10, bottomValue: 10 },
        ],
        [
          { topValue: 10, bottomValue: 10 },
          { topValue: 10, bottomValue: 10 },
          { topValue: 10, bottomValue: 10 },
        ],
        [
          { topValue: 10, bottomValue: 10 },
          { topValue: 10, bottomValue: 10 },
          { topValue: 10, bottomValue: 10 },
        ],
      ];
    }
    return matrixCells;
  }, [widget]);

  return (
    <WidgetMatrix
      title={t('reporting.reportRisk.riskMatrixTitle')}
      isLoading={false}
      data={matrixData}
      verticalTitle={t('reporting.reportRisk.riskMatrixVerticalTitle')}
      horizontalTitle={t('reporting.reportRisk.riskMatrixHorizontalTitle')}
    />
  );
};
