import { LoadingIndicator, WidgetContainer } from '@client/shared/toolkit';
import { Fragment, PropsWithChildren, ReactNode, useMemo } from 'react';
import { DataFeed, FontLight } from './components';
import { formatDate } from '@client/shared/utilities';
import { DashboardWidgetConfig, DashboardWidgetVariant } from '../WidgetDashboard';
import { useProjectDashboardData } from './hooks';

export interface DashboardWidgetProjectMilestonesProps extends PropsWithChildren {
  config?: DashboardWidgetConfig[];
  variant?: DashboardWidgetVariant;
}

export const DashboardWidgetProjectMilestones = (props: DashboardWidgetProjectMilestonesProps) => {
  const {config, children, variant = 'card'} = props;

  const { data: projectData, isFetching } = useProjectDashboardData();

  const foundMilestones = useMemo(() => {
    const milestonesData: ReactNode[] = [];
    config?.forEach((cf, index) => {
      const foundMilestone = projectData?.project?.calculationModel?.milestones?.find((milestone) => {
        return milestone.name === cf.name;
      });
      const value = foundMilestone?.date ? formatDate(foundMilestone.date) : '-';
      let title = config && config.length > 1 ? <FontLight>{value}</FontLight> : value;
      if (cf.size === 'large') {
        title = value;
      }
      if (foundMilestone || (!foundMilestone && !cf.hideIfEmpty)) {
        milestonesData.push(
          <DataFeed
            className={variant === 'child' ? 'border-t' : '[&:not(:first-child)]:border-t'}
            key={`dashboard-widget-project-custom-field-${index}`}
            header={<FontLight classNames="text-slate-500">{cf.title ?? cf.name}</FontLight>}
            title={title}
            icon={cf.icon}
          />
        );
      }
    });
    return milestonesData;
  }, [config, projectData?.project?.calculationModel?.milestones, variant]);

  const Component = variant === 'card' ? WidgetContainer : Fragment;

  return (
    <Component>
      {isFetching ? (
        <LoadingIndicator className="p-2"/>
      ) : (
        <>
          {foundMilestones.length > 0 && foundMilestones.map((milestone) => milestone)}
          {children}
        </>
      )}
    </Component>
  );
};
