import { PropsWithChildren } from 'react';
import cn from 'classnames';

interface WidgetContainerProps extends PropsWithChildren {
  className?: string;
  divideY?: boolean;
}
export const WidgetContainer = ({ children, className, divideY = true }: WidgetContainerProps) => {
  return (
    <div className={cn('bg-white rounded-md shadow-md break-inside-avoid-column relative h-full', { 'flex flex-col divide-y': divideY }, className)}>
      {children}
    </div>
  )
}
