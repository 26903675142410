import { TaxonomyReadModel } from '@client/shared/api';

export const getBuildingLabel = (item: TaxonomyReadModel | undefined) => {
  if (item === undefined) return '';
  const buildingType = item.itemType;
  const usageType = item.selectedAssetClass?.enumValue;

  switch (buildingType) {
    case 'Project':
      switch (usageType) {
        case undefined:
          return 'projectTaxonomy.taxonomyType.Project';
        case 'Undefined':
          return 'projectTaxonomy.taxonomyType.Project';
        case 'Production':
          return 'projectTaxonomy.taxonomyType.ProductionProject';
        case 'Traffic':
          return 'projectTaxonomy.taxonomyType.Project';
        case 'Technical':
          return 'projectTaxonomy.taxonomyType.Project';
        case 'Residential':
          return 'projectTaxonomy.taxonomyType.ResidentialProject';
        case 'Office':
          return 'projectTaxonomy.taxonomyType.OfficeProject';
        case 'Retail':
          return 'projectTaxonomy.taxonomyType.Project';
        case 'Storage':
          return 'projectTaxonomy.taxonomyType.Project';
        case 'Hotel':
          return 'projectTaxonomy.taxonomyType.HotelProject';
      }
      break;
    case 'BuildingComplex':
      return 'projectTaxonomy.taxonomyType.BuildingComplex';
    case 'Building':
      switch (usageType) {
        case undefined:
          return 'projectTaxonomy.taxonomyType.Building';
        case 'Undefined':
          return 'projectTaxonomy.taxonomyType.Building';
        case 'Production':
          return 'projectTaxonomy.taxonomyType.ProductionBuilding';
        case 'Traffic':
          return 'projectTaxonomy.taxonomyType.Building';
        case 'Technical':
          return 'projectTaxonomy.taxonomyType.Building';
        case 'Residential':
          return 'projectTaxonomy.taxonomyType.ResidentialBuilding';
        case 'Office':
          return 'projectTaxonomy.taxonomyType.OfficeBuilding';
        case 'Retail':
          return 'projectTaxonomy.taxonomyType.RetailBuilding';
        case 'Storage':
          return 'projectTaxonomy.taxonomyType.Building';
        case 'Hotel':
          return 'projectTaxonomy.taxonomyType.HotelBuilding';
      }
      break;
    case 'Floor':
      return 'projectTaxonomy.taxonomyType.Floor';
    case 'Sector':
      switch (usageType) {
        case undefined:
          return 'projectTaxonomy.taxonomyType.Sector';
        case 'Undefined':
          return 'projectTaxonomy.taxonomyType.Sector';
        case 'Production':
          return 'projectTaxonomy.taxonomyType.ProductionSector';
        case 'Traffic':
          return 'projectTaxonomy.taxonomyType.Sector';
        case 'Technical':
          return 'projectTaxonomy.taxonomyType.TechnicalSector';
        case 'Residential':
          return 'projectTaxonomy.taxonomyType.ResidentialSector';
        case 'Office':
          return 'projectTaxonomy.taxonomyType.OfficeSector';
        case 'Retail':
          return 'projectTaxonomy.taxonomyType.Sector';
        case 'Storage':
          return 'projectTaxonomy.taxonomyType.StorageSector';
        case 'Hotel':
          return 'projectTaxonomy.taxonomyType.Sector';
      }
      break;
    case 'Area':
      switch (usageType) {
        case undefined:
          return 'projectTaxonomy.taxonomyType.Area';
        case 'Undefined':
          return 'projectTaxonomy.taxonomyType.Area';
        case 'Production':
          return 'projectTaxonomy.taxonomyType.ProductionArea';
        case 'Traffic':
          return 'projectTaxonomy.taxonomyType.Area';
        case 'Technical':
          return 'projectTaxonomy.taxonomyType.TechnicalArea';
        case 'Residential':
          return 'projectTaxonomy.taxonomyType.ResidentialArea';
        case 'Office':
          return 'projectTaxonomy.taxonomyType.OfficeArea';
        case 'Retail':
          return 'projectTaxonomy.taxonomyType.Area';
        case 'Storage':
          return 'projectTaxonomy.taxonomyType.StorageArea';
        case 'Hotel':
          return 'projectTaxonomy.taxonomyType.Area';
      }
      break;
    case 'Room':
      switch (usageType) {
        case undefined:
          return 'projectTaxonomy.taxonomyType.Room';
        case 'Undefined':
          return 'projectTaxonomy.taxonomyType.Room';
        case 'Production':
          return 'projectTaxonomy.taxonomyType.Room';
        case 'Traffic':
          return 'projectTaxonomy.taxonomyType.Room';
        case 'Technical':
          return 'projectTaxonomy.taxonomyType.TechnicalRoom';
        case 'Residential':
          return 'projectTaxonomy.taxonomyType.Room';
        case 'Office':
          return 'projectTaxonomy.taxonomyType.OfficeRoom';
        case 'Retail':
          return 'projectTaxonomy.taxonomyType.Room';
        case 'Storage':
          return 'projectTaxonomy.taxonomyType.Room';
        case 'Hotel':
          return 'projectTaxonomy.taxonomyType.HotelRoom';
      }
      break;
    case 'Zone':
      return 'projectTaxonomy.taxonomyType.Zone';
    case 'Parking':
      return 'projectTaxonomy.taxonomyType.Parking';
    case 'Bed':
      return 'projectTaxonomy.taxonomyType.Bed';
    case 'Other':
      return 'projectTaxonomy.taxonomyType.Other';
  }

  return `projectTaxonomy.taxonomyType.${item.itemType}`;
};
