import { Fragment, PropsWithChildren } from 'react';
import { WidgetContainer } from '@client/shared/toolkit';
import { DashboardWidgetVariant, WidgetConfig } from '../WidgetDashboard';
import { getWidget } from '../utils';
import { ProjectReadModel } from '@client/shared/api';

export interface DashboardWidgetMixedProps extends PropsWithChildren {
  widgets: WidgetConfig[];
  variant?: DashboardWidgetVariant;
  projectData: ProjectReadModel;
  multiProject: boolean;
}

export const DashboardWidgetMixed = ({ widgets, children, variant = 'card', projectData, multiProject }: DashboardWidgetMixedProps) => {
  const Component = variant === 'card' ? WidgetContainer : Fragment;
  return (
    <Component>
      {widgets.map((widget: WidgetConfig, index: number) => (
        <Fragment key={`dashboard-widget-mixed-child-widget-${index}`}>
          {getWidget(widget, 'child', projectData, multiProject)}
        </Fragment>
      ))}
      {children}
    </Component>
  );
};
