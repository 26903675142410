import { useTranslation } from 'react-i18next';
import { WidgetContainer, WidgetContainerContent, WidgetContainerTitle } from '@client/shared/toolkit';
import { DashboardWidgetProps } from '../WidgetDashboard';

export const DashboardWidgetTextBox = (props: DashboardWidgetProps) => {
  const { widget } = props;
  const { t } = useTranslation();
  return (
    <WidgetContainer>
      <WidgetContainerTitle>{widget.title ? widget.title : t('dashboard.widget.textBox.title')}</WidgetContainerTitle>
      <WidgetContainerContent className="flex-1 overflow-y-auto">
        <div
          className="text-xs text-gray-500"
          dangerouslySetInnerHTML={{ __html: widget.text ? widget.text.replace(/\n/g, '<br />') : '' }}
        />
      </WidgetContainerContent>
    </WidgetContainer>
  );
};
