import {
  ProjectReadModel,
  useApiPostImportTenantDatevActiveApiMappingMutation,
} from '@client/shared/api';
import {
  Button,
  SlideOver,
  SlideOverOnCloseProps,
  DecoratedCard,
  SlideOverTabOptions,
  SlideOverWithTabs
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { useState, useRef } from 'react';
import { InvoiceCoverSheets } from '@client/project/shared';
import { ProjectData } from '../ProjectData';
import { EditProjectWorkflows } from './EditProjectWorkflows';
import { safeMutation } from '@client/shared/utilities';
import classNames from 'classnames';
import { EditProjectConnections } from '.';
import { ProjectBenchmarkingSettings } from '../ProjectBenchmarkingSettings';

export interface EditProjectSlideOverProps extends SlideOverOnCloseProps {
  projectId: string;
  readOnly: boolean;
  project?: ProjectReadModel;
  thumbnail?: string;
}

export type ProjectSettingGroupType = 'Status' | 'Construction Phase' | 'Vat' | 'Tag';

export const EditProjectSlideOver = ({
  projectId,
  project,
  readOnly,
  thumbnail,
  onClose,
}: EditProjectSlideOverProps) => {
  const { t } = useTranslation();

  const formRef = useRef<HTMLFormElement>(null);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isDatevImportEnabled, setIsDatevImportEnabled] = useState(false);
  const [importMappings, { isLoading: isImporting }] = useApiPostImportTenantDatevActiveApiMappingMutation();

  const handleTabChange = (index: number) => {
    setSelectedIndex(index);
  };

  const importDatevConnections = async () => {
    try {
      await safeMutation(
        importMappings,
        {
          projectId,
        },
        isImporting,
      );
    } catch (e) {
      console.error(e);
    }
  };

  const tabOptions: SlideOverTabOptions[] = [
    {
      name: 'projectData',
      header: t('projectSetting.projectData'),
      panel: (
        <ProjectData 
          project={project} 
          thumbnail={thumbnail}
          onClose={onClose} 
          formRef={formRef} 
          readOnly={readOnly} 
        />
      ),
    },
    {
      name: 'benchmarking',
      header: t('projectSetting.benchmarking.title'),
      panel: (
        <ProjectBenchmarkingSettings 
          project={project} 
          onClose={onClose} 
          formRef={formRef} 
          readOnly={readOnly} />
      ),
      disabled: false
    },
    {
      name: 'invoiceCoverSheets',
      header: t('app.settingsInvoiceCoverSheets'),
      panel: (
        <DecoratedCard>
          <DecoratedCard.Header>{t('app.settingsInvoiceCoverSheets')}</DecoratedCard.Header>
          <DecoratedCard.Content className="h-full px-5">
            <InvoiceCoverSheets type="project" projectId={projectId} />
          </DecoratedCard.Content>
        </DecoratedCard>
      ),
    },
    {
      name: 'workflows',
      header: t('app.settingsWorkflows'),
      panel: (
        <DecoratedCard>
          <DecoratedCard.Header>{t('app.settingsWorkflows')}</DecoratedCard.Header>
          <DecoratedCard.Content className="h-full px-5">
            <EditProjectWorkflows projectId={projectId} project={project} />
          </DecoratedCard.Content>
        </DecoratedCard>
      ),
    },
    {
      name: 'connections',
      header: t('projectSetting.tabConnections'),
      panel: (
        <EditProjectConnections
          projectId={projectId}
          setIsDatevImportEnabled={setIsDatevImportEnabled}
        />
      ),
    },
  ];

  return (
    <SlideOverWithTabs
      title={project?.payload.name ?? ''}
      subtitle={`${t('app.newProjectId')}: ${project?.payload.projectId ?? ''}`}
      onClose={onClose}
      tabOptions={tabOptions}
      onChange={handleTabChange}
    >
      <SlideOver.Controls>
        <div
          className={classNames('w-full flex justify-end', {
            'justify-between': tabOptions[selectedIndex].name === 'connections',
          })}
        >
          {tabOptions[selectedIndex].name === 'connections' && (
            <Button onClick={importDatevConnections} variant="primary" disabled={!isDatevImportEnabled}>
              {t('app.settingsApiDatevImportMapping')}
            </Button>
          )}
          <div className="flex gap-2">
            <Button onClick={() => onClose(true)} className="mr-2" variant="secondary">
              {tabOptions[selectedIndex].name !== 'projectData' ? t('common.close') : t('common.cancel')}
            </Button>
            {(tabOptions[selectedIndex].name === 'projectData' || tabOptions[selectedIndex].name === 'benchmarking') && (
              <Button onClick={() => formRef?.current?.submitForm()} variant="primary">
                {t('common.save')}
              </Button>
            )}
          </div>
        </div>
      </SlideOver.Controls>
    </SlideOverWithTabs>
  );
};
