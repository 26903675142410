import * as yup from 'yup';
import { InferType } from 'yup';
import {
  BaseSelect,
  Form,
  FormField, SlideOver,
  TextInput,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { WidgetDashboardEditFormProps } from './WidgetEditFormDefault';

export const WidgetDashboardEditFormMapViewValidationSchema = yup.object({
  title: yup.string().required('validation.required'),
  lat: yup.string().nullable().optional(),
  lng: yup.string().nullable().optional(),
  size: yup.string().required('validation.required'),
});

export type WidgetDashboardEditFormMapViewValidationValues = InferType<
  typeof WidgetDashboardEditFormMapViewValidationSchema
>;

export const WidgetEditFormMapView = (props: WidgetDashboardEditFormProps) => {
  const { buttons, widget, handleSubmit, sizeOptions, selectedSize } = props;
  const { t } = useTranslation();

  const defaultFormValues = useMemo(() => {
    return {
      title: widget?.widget.title ?? t('dashboard.widget.mapView.title'),
      lat: widget?.widget.lat ?? '',
      lng: widget?.widget.lng ??'',
      size: selectedSize
    };
  }, [t, widget?.widget.title, widget?.widget.lat, widget?.widget.lng, selectedSize]);

  return (
    <Form<WidgetDashboardEditFormMapViewValidationValues>
      onSubmit={handleSubmit}
      validationSchema={WidgetDashboardEditFormMapViewValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
    >
      <SlideOver.Content className="p-8">
        <FormField name="title">
          {(control) => <TextInput label={t('dashboard.widget.mapView.edit.title')} {...control} />}
        </FormField>
        <FormField name="lat">
          {(control) => <TextInput label={t('dashboard.widget.mapView.edit.lat')} {...control} />}
        </FormField>
        <FormField name="lng">
          {(control) => <TextInput label={t('dashboard.widget.mapView.edit.lng')} {...control} />}
        </FormField>
        <FormField name="size">
          {(control) => <BaseSelect label={t('dashboard.widget.size')} options={sizeOptions} {...control} />}
        </FormField>
      </SlideOver.Content>
      {buttons}
    </Form>
  );
};
