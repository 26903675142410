import { SelectCompanyBranchReadModel } from "@client/shared/api";
import { ComboSelectOption } from "@client/shared/toolkit";

export const getCompanyBranches = (branches : SelectCompanyBranchReadModel[] | undefined) : ComboSelectOption[] => {
    const options: ComboSelectOption[] = [];
    const companies = [] as ComboSelectOption[];
    
    branches?.forEach((branch) => {
        if (!companies.some((company) => company.value === branch.companyId)) {
            companies.push({
                label: branch.companyName,
                value: branch.companyId,
                disabled: true,
            });
        }
    });

    companies.forEach((company) => {
        const companyBranches = branches?.filter((branch) => branch.companyId === company.value) ?? [];
        const isSingleBranch = companyBranches?.length === 1;
        const isPrivatePerson = companyBranches?.some((branch) => branch.companyType === 'PrivatePerson');

        const label = isPrivatePerson
            ? companyBranches[0]?.name
            : isSingleBranch
                ? `${company.label} - ${companyBranches[0]?.name}`
                : company.label;
        const value = isSingleBranch || isPrivatePerson ? companyBranches[0]?.id : company.value;
        options.push({
            label: label,
            value: value,
            disabled: !isSingleBranch && !isPrivatePerson,
            options: isSingleBranch || isPrivatePerson
                ? []
                : companyBranches?.map((branch) => ({
                    label: branch.name,
                    value: branch.id,
                    disabled: false,
                })),
            });
    });

    return options;
};
