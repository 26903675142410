import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SlideOverOnCloseProps,
  DiggerOtherIcon,
  RoadDottedIcon,
  MapMarkerDottedIcon, SlideOverTabOptions, SlideOverWithTabs
} from '@client/shared/toolkit';

import { PlotReadModel } from '@client/shared/api';
import { getPlotLabel } from '../utils';
import { TaxonomyPlotData } from './TaxonomyPlotData';
import { TaxonomyPlotDocuments } from './TaxonomyPlotDocuments';

export interface TaxonomyPlotDashboardProps extends SlideOverOnCloseProps, PropsWithChildren {
  plot?: PlotReadModel;
  headerMenu?: React.ReactNode;
  isReadOnly: boolean;
  canDelete: boolean;
}

type PlotTypes = 'Plots' | 'Plot' | 'Infrastructure' | 'SpecialItem' | 'None';

export const TaxonomyPlotDashboard = (props: TaxonomyPlotDashboardProps) => {
  const {
    plot,
    onClose
  } = props
  const { t } = useTranslation();

  const icon = plot ? (
    <>
      {plot.type === 'Plot' && <MapMarkerDottedIcon className="w-8 h-8" />}
      {plot.type === 'Infrastructure' && <RoadDottedIcon className="w-8 h-8" />}
      {plot.type === 'SpecialItem' && <DiggerOtherIcon className="w-8 h-8" />}
    </>
  ) : undefined;

  const plotType: PlotTypes = plot?.isProjectPlot ? 'Plots' : plot?.type ?? 'None';
  const plotTitle = plotType === 'Plots' ? t('projectTaxonomy.basePlotTitle') : plot?.plotName ?? '';

  const tabOptions: SlideOverTabOptions[] = [
    {
      header: t('projectTaxonomy.plot'),
      name: 'plot',
      panel: <TaxonomyPlotData {...props} />
    },
    {
      header: t('common.documents'),
      name: 'documents',
      panel: <TaxonomyPlotDocuments {...props} />
    }
  ]

  return (
    <SlideOverWithTabs
      hasPadding={false}
      icon={icon}
      preTitle={t(getPlotLabel(plot))}
      title={plotTitle}
      subtitle={plot?.plotAddress}
      tabOptions={tabOptions}
      onClose={onClose}
    />
  )
}
