import { CustomListItemReadModel } from '@client/shared/api';
import { getMeasureLabel } from './getMeasureLabel';
import { ComboSelectOption } from '@client/shared/toolkit';

export const getMeasures = (availableMeasures : CustomListItemReadModel[])  : ComboSelectOption[] => {
  return availableMeasures
    .map((measure) => toComboSelectOptionRecursivly(measure))
    .sort((a, b) => {return a.label.localeCompare(b.label)});
};

const toComboSelectOptionRecursivly = (measure : CustomListItemReadModel) : ComboSelectOption => {
  return {
    label: getMeasureLabel(measure), 
    value: measure.id,
    disabled: false,
    options: measure.children
      .map((child) => toComboSelectOptionRecursivly(child))
      .sort((a, b) => {return a.label.localeCompare(b.label)})
  }
}