import { useEffect, useRef, useCallback } from 'react';
import Prism from 'prismjs';
import 'prismjs/components/prism-excel-formula';

// Configure Prism language
Prism.languages.insertBefore('excel-formula', 'string', {
  string: {
    pattern: /('[^']*')(\.\w+)?/g,
  },
});

type FormulaInputProps = {
  value: string;
  onChange: (arg: string) => void;
  onUpdateOffset: ({ start, end }: { start: number; end: number }) => void;
  disabled?: boolean;
};

export const FormulaInput = ({ value, onChange, onUpdateOffset, disabled }: FormulaInputProps) => {
  const textAreaRef = useRef<HTMLDivElement>(null);
  const cursorPosRef = useRef<{ start: number; end: number } | null>(null);
  const isUserEditingRef = useRef(false);
  const previousValueRef = useRef(value);

  const saveCursorPosition = useCallback((element: HTMLDivElement) => {
    const selection = window.getSelection();
    if (!selection?.rangeCount) return null;

    const range = selection.getRangeAt(0);
    const preCaretRange = document.createRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.startContainer, range.startOffset);

    return {
      start: preCaretRange.toString().length,
      end: preCaretRange.toString().length + range.toString().length,
    };
  }, []);

  const restoreCursorPosition = useCallback((element: HTMLDivElement, isExternalInput: boolean) => {
    const selection = window.getSelection();
    if (!selection) return;

    if (isExternalInput) {
      // For external input, move cursor to the end
      const nodeIterator = document.createNodeIterator(element, NodeFilter.SHOW_TEXT, null);
      let lastNode = null;
      let lastNodeLength = 0;
      
      let currentNode;
      while ((currentNode = nodeIterator.nextNode())) {
        lastNode = currentNode;
        lastNodeLength = currentNode.textContent?.length || 0;
      }

      if (lastNode) {
        const range = document.createRange();
        range.setStart(lastNode, lastNodeLength);
        range.setEnd(lastNode, lastNodeLength);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    } else {
      // For user input, restore the previous cursor position
      const position = cursorPosRef.current;
      if (!position) return;

      const nodeIterator = document.createNodeIterator(element, NodeFilter.SHOW_TEXT, null);
      let currentPos = 0;
      let startNode = null;
      let startOffset = 0;
      let endNode = null;
      let endOffset = 0;

      let currentNode;
      while ((currentNode = nodeIterator.nextNode())) {
        const nodeLength = currentNode.textContent?.length || 0;

        // Find start position
        if (!startNode && currentPos + nodeLength >= position.start) {
          startNode = currentNode;
          startOffset = position.start - currentPos;
        }

        // Find end position
        if (!endNode && currentPos + nodeLength >= position.end) {
          endNode = currentNode;
          endOffset = position.end - currentPos;
          break;
        }

        currentPos += nodeLength;
      }

      if (startNode && endNode) {
        const range = document.createRange();
        range.setStart(startNode, startOffset);
        range.setEnd(endNode, endOffset);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }, []);

  useEffect(() => {
    const handleSelectionChange = () => {
      const selection = document.getSelection();

      if (!selection?.rangeCount || !textAreaRef.current) return;
      const range = selection.getRangeAt(0);
      const preCaretRange = document.createRange();
      preCaretRange.selectNodeContents(textAreaRef.current);
      preCaretRange.setEnd(range.startContainer, range.startOffset);

      onUpdateOffset({
        start: preCaretRange.toString().length,
        end: preCaretRange.toString().length + range.toString().length,
      });
    };

    document.addEventListener('selectionchange', handleSelectionChange);

    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, [onUpdateOffset]);

  const handleTextChange = useCallback(() => {
    if (!textAreaRef.current) return;

    // Save cursor position
    cursorPosRef.current = saveCursorPosition(textAreaRef.current);

    const text = textAreaRef.current.innerText;

    if (text !== value) {
      isUserEditingRef.current = true;
      onChange(text);
    }
  }, [saveCursorPosition, value, onChange]);

  useEffect(() => {
    if (!textAreaRef.current) return;

    // Highlight content
    const highlightedHTML = Prism.highlight(value, Prism.languages['excel-formula'], 'excel-formula');

    if (textAreaRef.current.innerHTML !== highlightedHTML) {
      textAreaRef.current.innerHTML = highlightedHTML;
    }
    const isExternatInput = value !== previousValueRef.current && !isUserEditingRef.current

    // Restore cursor position
    restoreCursorPosition(textAreaRef.current, isExternatInput);

    // Reset the user editing flag
    isUserEditingRef.current = false;
    previousValueRef.current = value;
  }, [value, restoreCursorPosition]);

  return (
    <pre className="grid grow-wrap w-full h-full bg-white">
      <code
        contentEditable={!disabled}
        spellCheck={false}
        ref={textAreaRef}
        onInput={handleTextChange}
        style={{
          fontFamily: 'Roboto, monospace',
          fontSize: '16px',
          lineHeight: '1.5',
          whiteSpace: 'pre-wrap',
        }}
        className="p-2 w-full min-h-[65px] max-h-[200px] overflow-auto language-excel-formula bg-white rounded break-words focus:outline-none focus:ring-1 focus:ring-slate-400"
      />
    </pre>
  );
};