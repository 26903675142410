import { Fragment, ReactNode, useMemo, useState } from 'react';
import {
  BusIcon,
  BuyingIcon, ChargingStationIcon, LocationIcon,
  LocationInformation, MetroCustomIcon,
  PlaneIcon,
  ProjectInfoCardMapContent,
  RoadDottedIcon, TrainCustomIcon
} from '@client/shared/toolkit';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { PoiType, ProjectPoi } from '@client/shared/api';
import { formatUnit } from '@client/shared/utilities';
import { MapMarketPricesCard } from '@client/project/shared';

type ProjectLocationContainerProps = {
  header: string;
  location: LocationInformation;
  latitude?: number | null;
  longitude?: number | null;
  pois: ProjectPoi[];
  multiProject: boolean;
};

const Row = ({ header, icon, content }: { header: ReactNode; icon: ReactNode; content: ReactNode }) => (
  <div className="flex justify-start flex-auto py-1 gap-1 h-auto">
    <div className="w-8 pl-4 flex flex-col justify-center">{icon}</div>
    <div className="ml-4 flex flex-col justify-evenly flex-grow">
      {header && <div>{header}</div>}
      <div className="font-bold leading-tight text-md">{content}</div>
    </div>
  </div>
);

interface LocationDetailsProps {
  location: LocationInformation;
  setShowLocationDetails: (show: boolean) => void;
  pois: ProjectPoi[];
  multiProject: boolean;
  locationDetailsWidth: number;
}

type RenderPoiProps = {
  type: PoiType;
  poi: ProjectPoi[];
};

const RenderPoi = (props: RenderPoiProps) => {
  const { t } = useTranslation();
  const icon = useMemo(() => {
    switch (props.type) {
      case 'Metro':
      case 'Tram':
        return <MetroCustomIcon className="aspect-square w-full" />;
      case 'Bus':
      return <BusIcon className="aspect-square w-full" />;
      case 'Train':
        return <TrainCustomIcon className="aspect-square w-full" />;
      case 'Airport':
        return <PlaneIcon className="aspect-square w-full" />;
      case 'EvCharging':
        return <ChargingStationIcon className="aspect-square w-full" />;
      case 'Shopping':
        return <BuyingIcon className="aspect-square w-full" />;
      default:
        return <RoadDottedIcon className="aspect-square w-full" />;
    }
  }, [props.type]);

  const poiDetails = useMemo(() => {
    return props.poi.map((p) => ({
      name: p.name,
      distance: p.distance,
    }));
  }, [props.poi]);

  if (props.poi.length === 0) return null;

  return (
    <Row
      header={<span className="font-light text-sm">{t(`poi.${props.type.toLowerCase()}`, props.type)}</span>}
      content={poiDetails.map((p, index, col) => (
        <Fragment key={index}>
          <span className="text-sm font-bold">{p.name}</span>&nbsp;
          <span className="text-sm font-light">
            {p.distance &&
              formatUnit(p.distance.value ?? 0, p.distance.unit, {
                maxDigits: 2,
              })}
          </span>
          {!(col.length === index + 1) ? <span className="text-sm"> , </span> : null}
        </Fragment>
      ))}
      icon={icon}
    />
  );
};

const LocationDetails = ({ location, setShowLocationDetails, ...props }: LocationDetailsProps) => {
  const { t } = useTranslation();
  const addressLine1 = `${location.street} ${location.number}`;
  const addressLine2 = `${location.postalCode} ${location.city}`;

  return (
    <div
      className={`absolute top-20 right-6 w-[${props.locationDetailsWidth}px] h-full bg-white rounded-md shadow-md m-0 max-h-[80%] overflow-y-auto`}
    >
      <div className="h-full grid grid-rows-[1fr_5fr_1fr]">
        <div className="w-full relative border-b-2 border-slate-100">
          <div className="absolute right-0 m-2">
            <button onClick={() => setShowLocationDetails(false)}>
              <XMarkIcon className="w-6 h-6 hover:text-gray-600 transition-color duration-200 cursor-pointer" />
            </button>
          </div>
          <Row
            icon={<LocationIcon />}
            content={
              <>
                <span className="text-sm">{addressLine1}</span>
                <br />
                <span className="text-sm">{addressLine2}</span>
              </>
            }
            header={<span className="font-light text-sm">{t('common.address')}</span>}
          />
        </div>
        <div className="w-full py-1">
          <RenderPoi type="Metro" poi={props.pois.filter((p) => p.type === 'Metro').slice(0, 2)} />
          <RenderPoi type="Bus" poi={props.pois.filter((p) => p.type === 'Bus').slice(0, 2)} />
          <RenderPoi type="Train" poi={props.pois.filter((p) => p.type === 'Train').slice(0, 2)} />
          <RenderPoi type="Airport" poi={props.pois.filter((p) => p.type === 'Airport').slice(0, 2)} />
          <RenderPoi type="Tram" poi={props.pois.filter((p) => p.type === 'Tram').slice(0, 2)} />
        </div>
        <div className="w-full py-1">
          <RenderPoi type="EvCharging" poi={props.pois.filter((p) => p.type === 'EvCharging').slice(0, 2)} />
          <RenderPoi type="Shopping" poi={props.pois.filter((p) => p.type === 'Shopping').slice(0, 2)} />
        </div>
        <div className="w-full py-1">
          <MapMarketPricesCard multiProject={props.multiProject} />
        </div>
      </div>
    </div>
  );
};

export const ProjectLocationContainer = ({ header, location, ...props }: ProjectLocationContainerProps) => {
  const { t } = useTranslation();
  const [showLocationDetails, setShowLocationDetails] = useState(true);

  const locationDetailsWidth = 500;

  return (
    <div className="grid grid-rows-[0.1fr_1fr] w-full h-full project-location rounded-md shadow-md bg-white p-2 relative min-h-[425px]">
      <div className="py-2 border-b-2 border-slate-100">
        <span className="flex justify-between">
          <div className="text-xl font-bold">{header}</div>
          {!showLocationDetails && (
            <div
              className="mx-2 text-sm font-bold cursor-pointer hover:opacity-60"
              onClick={() => setShowLocationDetails(true)}
            >
              {t('project.showLocationDetails')}
            </div>
          )}
        </span>
      </div>
      <div className="h-full py-2 relative">
        <ProjectInfoCardMapContent
          latitude={props.latitude}
          longitude={props.longitude}
          offsetWidth={showLocationDetails ? locationDetailsWidth : 0}
        />
      </div>
      {showLocationDetails && (
        <LocationDetails
          pois={props.pois}
          location={location}
          setShowLocationDetails={setShowLocationDetails}
          multiProject={props.multiProject}
          locationDetailsWidth={locationDetailsWidth}
        />
      )}
    </div>
  );
};
