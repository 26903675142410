import { useNavigate } from 'react-router-dom';
import { LoadingIndicator } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useApiGetExchangeQuery } from '@client/shared/api';

export const SsoRoute = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const realm = window.location.pathname.match(/sso\/([^?]*)/)?.[1];
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get('code');
  const returnUrl = searchParams.get('returnUrl');
  const { data: exchangeData } = useApiGetExchangeQuery({
    realm: realm ?? '',
    code: code ?? '',
    returnUrl: returnUrl ?? '',
  });

  useEffect(() => {
    if (exchangeData?.returnUrl) {
      navigate(exchangeData.returnUrl);
    }
  }, [exchangeData, navigate]);

  return (
    <div className="flex-grow flex items-center justify-center bg-gray-200 z-50 absolute top-0 left-0 w-screen h-screen">
      <LoadingIndicator text={t('app.loadingApplication')} />
    </div>
  );
};
