import {
  ApiGetBaseReportApiArg,
  ApiGetBaseReportApiResponse,
  ApiGetBmwRiskReportApiArg,
  ApiGetBmwRiskReportApiResponse,
  ApiGetBmwWaterfallReportApiArg,
  ApiGetBmwWaterfallReportApiResponse,
  ApiGetBugetReportApiArg,
  ApiGetBugetReportApiResponse,
  ApiGetComparisonReportApiArg,
  ApiGetComparisonReportApiResponse,
  ApiGetDetailReportApiArg,
  ApiGetDetailReportApiResponse,
  ApiGetForecastReportApiArg,
  ApiGetForecastReportApiResponse,
  ApiGetReportGenericDataSourceApiArg,
  ApiGetReportGenericDataSourceApiResponse,
  ApiGetReportInvoiceCoverSheetDataSourceApiArg,
  ApiGetReportInvoiceCoverSheetDataSourceApiResponse,
  ApiGetSystemReportsApiArg,
  ApiGetSystemReportsApiResponse,
  ApiGetTenantReportsApiArg,
  ApiGetTenantReportsApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiReportingEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetBaseReport: {
    providesTags: (_result: ApiGetBaseReportApiResponse, _error: FetchBaseQueryError, args: ApiGetBaseReportApiArg) => [
      { type: ApiTagTypes.BaseReport, id: args.projectId },
    ],
  },
  apiGetBugetReport: {
    providesTags: (
      _result: ApiGetBugetReportApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetBugetReportApiArg,
    ) => [
      { type: ApiTagTypes.BudgetReport, id: args.calculationModelId },
      { type: ApiTagTypes.Report, id: args.calculationModelId },
    ],
  },
  apiGetForecastReport: {
    providesTags: (
      _result: ApiGetForecastReportApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetForecastReportApiArg,
    ) => [
      { type: ApiTagTypes.ForecastReport, id: args.calculationModelId },
      { type: ApiTagTypes.Report, id: args.calculationModelId },
    ],
  },
  apiGetDetailReport: {
    providesTags: (
      _result: ApiGetDetailReportApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetDetailReportApiArg,
    ) => [
      { type: ApiTagTypes.DetailReport, id: args.calculationModelId },
      { type: ApiTagTypes.Report, id: args.calculationModelId },
    ],
  },
  apiGetComparisonReport: {
    providesTags: (
      _result: ApiGetComparisonReportApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetComparisonReportApiArg,
    ) => [
      { type: ApiTagTypes.ComparisonReport, id: args.calculationModelId },
      { type: ApiTagTypes.ComparisonReport, id: args.targetId },
      { type: ApiTagTypes.Report, id: args.calculationModelId },
    ],
  },
  apiGetTenantReports: {
    providesTags: (
      _result: ApiGetTenantReportsApiResponse,
      _error: FetchBaseQueryError,
      _args: ApiGetTenantReportsApiArg,
    ) => [{ type: ApiTagTypes.Report, id: 'LIST' }],
  },
  apiGetSystemReports: {
    providesTags: (
      _result: ApiGetSystemReportsApiResponse,
      _error: FetchBaseQueryError,
      _args: ApiGetSystemReportsApiArg,
    ) => [{ type: ApiTagTypes.Report, id: 'LIST' }],
  },
  apiGetReportGenericDataSource: {
    providesTags: (
      _result: ApiGetReportGenericDataSourceApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetReportGenericDataSourceApiArg,
    ) => [{ type: ApiTagTypes.Report, id: args.calculationModelId }],
  },
  apiGetReportInvoiceCoverSheetDataSource: {
    providesTags: (
      _result: ApiGetReportInvoiceCoverSheetDataSourceApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetReportInvoiceCoverSheetDataSourceApiArg,
    ) => [{ type: ApiTagTypes.Report, id: args.calculationModelId }],
  },
  apiGetBmwWaterfallReport: {
    providesTags: (
      _result: ApiGetBmwWaterfallReportApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetBmwWaterfallReportApiArg,
    ) => [{ type: ApiTagTypes.Report, id: args.calculationModelId }],
  },
  apiGetBmwRiskReport: {
    providesTags: (
      _result: ApiGetBmwRiskReportApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetBmwRiskReportApiArg,
    ) => [{ type: ApiTagTypes.Report, id: args.calculationModelId }],
  },
};
