import { WidgetContainer, WidgetContainerContent, WidgetContainerTitle } from '.';
import { LoadingIndicator } from '..';
import cn from 'classnames';

export interface SummaryCellData {
  label?: string | number | React.ReactNode;
  value: string | number | React.ReactNode;
}

interface WidgetSummaryProps {
  title?: string;
  isLoading?: boolean;
  data: SummaryCellData[];
}

export const WidgetSummary = ({ title, isLoading = false, data }: WidgetSummaryProps) => {
  return (
    <WidgetContainer>
      {isLoading ? (
        <LoadingIndicator className="p-4" />
      ) : (
        <>
          {title && <WidgetContainerTitle>{title}</WidgetContainerTitle>}
          <WidgetContainerContent className="flex-1">
            <div className="h-full grid gap-2 grid-cols-4">
              {data.map((element, i) => (
                <div
                  key={i}
                  className={cn('pr-1.5 relative flex flex-col gap-1', {
                    'before:content-[""] before:w-0 before:h-4 before:block before:absolute before:-left-2.5 before:top-1/2 before:-mt-2 before:z-[1] before:border-l-2':
                      i % 4,
                  })}
                >
                  <div className="text-xs text-slate-600 leading-[13px] h-[29px] overflow-hidden text-ellipsis content-center line-clamp-2">
                    {element.label}
                  </div>
                  <div className="text-lg font-bold leading-none">{element.value}</div>
                </div>
              ))}
            </div>
          </WidgetContainerContent>
        </>
      )}
    </WidgetContainer>
  );
};
