import {
  ContractReadModel,
  ExternalApiReadModel,
  InvoiceReadModel,
  useApiGetExternalApisQuery,
  useApiStartInvoiceWorkflowMutation,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  ContextMenuItem,
  DecoratedCard,
  ListTitle,
  ReceiptAndChangeIcon,
  SlideOver,
  SlideOverSortableListTotalListItem,
  AddIcon,
  TrashIcon,
  EyeIcon,
  Modal,
  ContextMenu,
  DecoratedCardAddButton,
  CircledPlayIcon,
  LoadingIndicator,
} from '@client/shared/toolkit';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ROUTES_CONFIG, useValidateProjectPermission } from '@client/shared/permissions';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { formatDate, safeMutation, SortHeaderType } from '@client/shared/utilities';
import { SlideOverSortableList, SlideOverSortableListItemData } from '@client/shared/toolkit';
import {
  InvoiceSlideOver,
  FormattedCurrency,
  InvoiceDeleteModal,
  InvoiceEditContextProvider,
  StartWorkflowDisabledMessage,
  InvoiceDocumentUploadModal,
  InvoiceCreateWizard,
  InvoiceTakeoverModal,
} from '../..';
import { sortByProperty } from '@client/shared/utilities';
import { useNavigate } from 'react-router-dom';
import { ArrowsRightLeftIcon } from '@heroicons/react/20/solid';
import { formatDistanceToNow } from 'date-fns';

export interface ContractInvoicesProps {
  contract: ContractReadModel;
  setChildSlideOverIsOpen: (open: boolean) => void;
}

export const ContractInvoices = ({ contract, setChildSlideOverIsOpen }: ContractInvoicesProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const navigate = useNavigate();

  const canWrite = useValidateProjectPermission(['CONTRACT_WRITE'], loadedProjectId ?? '');

  const [openSlideOver, setOpenSlideOver] = useState<
    'uploadInvoice' | 'createInvoice' | 'viewInvoice' | 'deleteInvoice' | 'takeoverInvoice' | null
  >(null);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceReadModel | null>(null);

  const { data: externalApis, isFetching: isLoadingExternalApis } = useApiGetExternalApisQuery();

  const invoiceAiActive = useMemo(() => {
    return !!externalApis?.find((x: ExternalApiReadModel) => x.api.name === 'Probis Invoice AI')?.api.isActive
  }, [externalApis])

  const [startWorkflow, { isLoading: isStartingWorkflow }] = useApiStartInvoiceWorkflowMutation();

  const [openInvoice, setOpenInvoice] = useState('');
  const [unsavedData, setUnsavedData] = useState(false);

  const handleStartWorkflow = async (invoiceId: string) => {
    if (loadedProjectId && loadedVariantId) {
      try {
        await safeMutation(
          startWorkflow,
          {
            body: {
              projectId: loadedProjectId,
              calculationModelId: loadedVariantId,
              invoiceId: invoiceId,
            },
          },
          isStartingWorkflow,
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const defaultSortHeader: SortHeaderType[] = useMemo(() => {
    return [
      {
        asc: null,
        label: t('projectContract.invoiceDateAndDueDate'),
        type: 'date',
        value: 'invoiceDate',
      },
      {
        asc: true,
        label: t('projectControl.invoiceClaim'),
        type: 'number',
        value: 'claim',
      },
      {
        asc: null,
        label: t('projectControl.InvoiceAmountLabel'),
        type: 'number',
        value: 'invoiceValueNet',
      },
      {
        asc: null,
        label: t('projectControl.PaymentReleaseLabel'),
        type: 'number',
        value: 'paymentValueNet',
      },
    ];
  }, [t]);

  const [sortHeader, setSortHeader] = useState<SortHeaderType[]>(defaultSortHeader);

  const invoices: InvoiceReadModel[] = useMemo(() => {
    if (contract) {
      return contract.invoices;
    }
    return [];
  }, [contract]);

  const titlesByGroup = useMemo(() => {
    const invoiceListItems: SlideOverSortableListItemData[] = [];
    const invoiceSubtotals: number[] = [-1, 0, 0, 0];
    const totals: number[] = [-1, 0, 0, 0];

    let sortedInvoices = [...invoices];
    if (sortedInvoices.length) {
      const sortBy = sortHeader.find((sort) => sort.asc !== null);
      if (sortBy) {
        sortedInvoices = sortByProperty(sortedInvoices, sortBy) as InvoiceReadModel[];
      }
    }

    sortedInvoices.forEach((invoice) => {
      const contextMenuItems: ContextMenuItem[] = [
        {
          label: t('common.view'),
          subtitle: t('projectControl.viewInvoice'),
          icon: <EyeIcon />,
          onClick: () => {
            setSelectedInvoice(invoice);
            setOpenSlideOver('viewInvoice');
          },
          stopPropagation: true,
        }
      ];

      if (!invoice.isPxInvoice) {

        contextMenuItems.push({
          label: t('projectControl.takeoverInvoice'),
          subtitle: t('projectControl.takeoverInvoiceDetails'),
          icon: <ArrowsRightLeftIcon/>,
          isDisabled: !invoice.contractId || invoice.state !== 'Pending',
          onClick: () => {
            setSelectedInvoice(invoice);
            setOpenSlideOver('takeoverInvoice');
          },
          stopPropagation: true,
        });

        contextMenuItems.push({
          label: t('common.delete'),
          subtitle: t('projectControl.deleteInvoice'),
          icon: <TrashIcon />,
          onClick: () => {
            setSelectedInvoice(invoice);
            setOpenSlideOver('deleteInvoice');
          },
          stopPropagation: true,
        });
        contextMenuItems.push({
          label: invoice.startWorkflowStatus.canRestartWorkflow
            ? invoice.startWorkflowStatus.workflowName ? t('projectControl.restartWorkflowName', { name: invoice.startWorkflowStatus.workflowName }) : t('projectControl.restartWorkflow')
            : invoice.startWorkflowStatus.workflowName ? t('projectControl.startWorkflowName', { name: invoice.startWorkflowStatus.workflowName }) : t('projectControl.startWorkflow'),
          subtitle: t('projectControl.startWorkflowForInvoice'),
          icon: <CircledPlayIcon />,
          onClick: () => {
            handleStartWorkflow(invoice.id)
          },
          isDisabled: !invoice.startWorkflowStatus?.canStartWorkflow && !invoice.startWorkflowStatus?.canRestartWorkflow,
          children: !invoice.startWorkflowStatus.canStartWorkflow && !invoice.startWorkflowStatus?.canRestartWorkflow ? (
            <StartWorkflowDisabledMessage invoice={invoice} projectId={loadedProjectId ?? ''} />
          ) : null,
          truncateText: false,
          stopPropagation: true
        })
      }

      invoiceListItems.push({
        id: invoice.id,
        name: (
          <div className="flex flex-row md:flex-col lg:flex-row gap-2 flex-wrap items-start">
            <span className="order-1 md:order-2 relative whitespace-normal">{invoice.code}</span>
            {invoice.state === 'Pending' && (
              <Badge
                variant="lightInfo"
                text={t('projectControl.statePending')}
                className="order-2 md:order-1 lg:order-2"
              />
            )}
            {invoice.state === 'Audited' && (
              <Badge
                variant="custom"
                className="bg-emerald-500 text-white order-2 md:order-1 lg:order-2"
                text={t('projectControl.stateAudited')}
              />
            )}
            {invoice.state === 'Approved' && (
              <Badge
                variant="custom"
                className="bg-emerald-500 text-white order-2 md:order-1 lg:order-2"
                text={t('projectControl.stateApproved')}
              />
            )}
            {invoice.state === 'Paid' && (
              <Badge
                variant="custom"
                className="bg-slate-300 text-white order-2 md:order-1 lg:order-2"
                text={t('projectControl.statePaid')}
              />
            )}
          </div>
        ),
        description: (
          <span className="font-bold">
            {t('projectContract.numberLabel')} {invoice.sequentialNumber ? invoice.sequentialNumber : '-'}
          </span>
        ),
        cols: [
          {
            value: invoice.invoiceDate ? formatDate(new Date(invoice.invoiceDate.replace(/-/g, '/'))) : '',
            title: invoice.dueDate ? formatDistanceToNow(invoice.dueDate, { addSuffix: true, includeSeconds: false }) : '',
            header: t('projectContract.invoiceDateAndDueDate'),
          },
          {
            value: invoice.claim ? <FormattedCurrency amount={invoice.claim} /> : <FormattedCurrency amount={0} />,
            title: t('projectContract.invoiceNet'),
            header: t('projectContract.invoiceAmount'),
          },
          {
            value: invoice.invoiceValueNet ? (
              <FormattedCurrency amount={invoice.invoiceValueNet} />
            ) : (
              <FormattedCurrency amount={0} />
            ),
            title: t('projectContract.invoiceNet'),
            header: t('projectContract.invoiceAdjustments'),
          },
          {
            value: invoice.paymentValueNet ? (
              <FormattedCurrency amount={invoice.paymentValueNet} />
            ) : (
              <FormattedCurrency amount={0} />
            ),
            title: t('projectContract.invoiceNet'),
            header: t('projectContract.invoicePaymentRelease'),
          },
        ],
        contextMenu: <ContextMenu items={contextMenuItems} stopPropagation />,
      });

      invoiceSubtotals[1] += invoice.claim ?? 0;
      invoiceSubtotals[2] += invoice.invoiceValueNet ?? 0;
      invoiceSubtotals[3] += invoice.paymentValueNet ?? 0;

      totals[1] += invoice.claim ?? 0;
      totals[2] += invoice.invoiceValueNet ?? 0;
      totals[3] += invoice.paymentValueNet ?? 0;
    });

    return {
      invoices: {
        listItems: invoiceListItems,
        items: sortedInvoices,
        subTotals: [
          '',
          <FormattedCurrency amount={invoiceSubtotals[1]} />,
          '', // <FormattedCurrency amount={invoiceSubtotals[2]} />,
          <FormattedCurrency amount={invoiceSubtotals[3]} />,
        ],
      },
      changes: {
        listItems: [],
        items: [],
        subTotals: [],
      },
      totals: [
        '',
        <FormattedCurrency amount={totals[1]} />,
        '', // <FormattedCurrency amount={totals[2]} />,
        <FormattedCurrency amount={totals[3]} />,
      ],
      // TODO
      billed: ['', '', '', <FormattedCurrency amount={totals[3]} />],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices, sortHeader, t]);

  const onHandleSort = useCallback(
    (index: number) => {
      const currentSortValues = [...sortHeader];
      const update = currentSortValues[index];
      update.asc = update.asc === null ? true : !update.asc;
      currentSortValues.forEach((val, i) => {
        if (i !== index) {
          val.asc = null;
        }
      });
      currentSortValues[index] = update;
      setSortHeader(currentSortValues);
    },
    [sortHeader],
  );

  const menuItems: ContextMenuItem[] = [
    {
      label: t('projectContract.newInvoice'),
      subtitle: t('projectContract.createNewInvoice'),
      icon: <AddIcon />,
      isDisabled: !canWrite,
      onClick: () => {
        setSelectedInvoice(null);
        setOpenSlideOver('createInvoice');
      },
    },
    {
      label: t('projectControl.uploadInvoiceTitle'),
      subtitle: t('projectControl.uploadInvoiceSubTitle'),
      icon: <AddIcon />,
      onClick: () => {
        setSelectedInvoice(null);
        setOpenSlideOver('uploadInvoice');
      },
      isDisabled: !invoiceAiActive,
    },
  ];

  useEffect(() => {
    if (openInvoice) {
      const foundInvoice = invoices.find((i) => i.id === openInvoice);
      if (foundInvoice) {
        setSelectedInvoice(foundInvoice);
        setOpenSlideOver('viewInvoice');
        setOpenInvoice('');
      }
    }
  }, [invoices, openInvoice]);

  return (
    <div className="mb-10">
      {isLoadingExternalApis || isStartingWorkflow && <LoadingIndicator text={t(isLoadingExternalApis ? 'common.loading' : 'projectContract.startingWorkflow')} mode="overlay" />}
      <DecoratedCard shadowVariant="normal">
        <DecoratedCard.Content>
          <ListTitle title={t('projectContract.invoices')} />
          <SlideOverSortableList
            icon={<ReceiptAndChangeIcon className="w-5 text-sky-800" />}
            data={titlesByGroup.invoices.listItems}
            headline={t('projectContract.invoices')}
            handleSelect={(index: number) => {
              setSelectedInvoice(titlesByGroup.invoices.items[index]);
              setOpenSlideOver('viewInvoice');
            }}
            subTotals={titlesByGroup.invoices.listItems.length ? titlesByGroup.invoices.subTotals : undefined}
            sortHeader={sortHeader}
            onHandleSort={onHandleSort}
            noItemsMessage={t('projectContract.noInvoices')}
            gridCols="grid-cols-2 md:grid-cols-4"
            color="bg-sky-800"
            textColor="text-sky-800"
            subTotalLabel=""
            subTotalSumLabel={t('projectContract.sum')}
            subTotalHeaders={[
              '',
              t('projectContract.subTotalInvoiceClaim'),
              '', // t('projectContract.subTotalInvoiceAmount'),
              t('projectContract.subTotalInvoicePaymentRelease'),
            ]}
            contextMenu
          />
          {!contract?.isPxContract && <DecoratedCardAddButton menuItems={menuItems} />}
        </DecoratedCard.Content>
      </DecoratedCard>
      {titlesByGroup.invoices.listItems.length > 0 && (
        <>
          <SlideOverSortableListTotalListItem
            className="mt-2"
            size="lg"
            nrOfItems={titlesByGroup.invoices.listItems.length}
            totals={titlesByGroup.totals}
            textColor="text-gray-500"
            totalHeaders={[
              '',
              t('projectContract.totalInvoiceClaim'),
              '', // t('projectControl.invoiceTotal'),
              t('projectContract.totalInvoicePaymentRelease'),
            ]}
            gridCols="grid-cols-2 md:grid-cols-4"
            totalSumLabel={t('projectContract.sumNet')}
            totalLabel=""
            borderTop={false}
            contextMenu
          />
          <SlideOverSortableListTotalListItem
            className="mt-2 pt-4 border-t-2"
            size="xl"
            nrOfItems={titlesByGroup.invoices.listItems.length}
            totals={titlesByGroup.billed}
            textColor="text-slate-900"
            totalHeaders={[]}
            gridCols="grid-cols-2 md:grid-cols-4"
            showSumOnColumns={[]}
            totalLabel=""
            contextMenu
          />
        </>
      )}

      {/* CREATE INVOICE */}
      <InvoiceCreateWizard
        contract={contract}
        isOpen={openSlideOver === 'createInvoice'}
        onClose={() => {
          setOpenSlideOver(null);
          setSelectedInvoice(null);
        }}
        openInvoice={(invoiceId: string) => {
          setOpenInvoice(invoiceId);
        }}
      />

      {/* UPLOAD INVOICE DOCUMENT */}
      <InvoiceDocumentUploadModal
        isOpen={openSlideOver === 'uploadInvoice'}
        onClose={() => {
          navigate(ROUTES_CONFIG.PROJECT_CONTROL.path.replace(':id', loadedProjectId ?? ''));
        }}
      />

      {/* VIEW INVOICE */}
      <SlideOver
        isOpen={openSlideOver === 'viewInvoice'}
        onClose={() => setOpenSlideOver(null)}
        variant="2xl"
        confirmBeforeClose={unsavedData}
        cancelConfirmTitle={t('portfolio.projecting.cancelEditingConfirmationTitle')}
        cancelConfirmDescription={t('portfolio.projecting.cancelEditingConfirmationDescription')}
        cancelButtonText={t('portfolio.projecting.continueEditing')}
        confirmButtonText={t('portfolio.projecting.cancelEditingWithoutSaving')}
      >
        {selectedInvoice && (
          <InvoiceEditContextProvider setHasUnsavedData={setUnsavedData}>
            <InvoiceSlideOver
              onClose={() => setOpenSlideOver(null)}
              selectedInvoiceId={selectedInvoice.id}
              contract={contract}
            />
          </InvoiceEditContextProvider>
        )}
      </SlideOver>

      {/* DELETE INVOICE */}
      <Modal isOpen={openSlideOver === 'deleteInvoice'} onClose={() => setOpenSlideOver(null)}>
        {selectedInvoice && <InvoiceDeleteModal invoice={selectedInvoice} onClose={() => setOpenSlideOver(null)} />}
      </Modal>

      {/* TAKEOVER INVOICE */}
      <Modal isOpen={openSlideOver === 'takeoverInvoice'} onClose={() => setOpenSlideOver(null)}>
        {selectedInvoice && <InvoiceTakeoverModal invoiceId={selectedInvoice.id} contractId={selectedInvoice.contractId ?? ''} onClose={() => setOpenSlideOver(null)} />}
      </Modal>
    </div>
  );
};
