import React, { ReactElement, ReactNode, useMemo } from 'react';
import { TrafficLightGreenCustomIcon, TrafficLightYellowCustomIcon, TrafficLightRedCustomIcon } from '../../icons';

interface TrafficLightProps {
  title: string;
  value: number;
  icon: ReactNode;
  color: 'green' | 'red' | 'yellow';
}

export const TrafficLight = (props: TrafficLightProps) => {
  const { title, value, icon, color } = props;

  const trafficLightImage = useMemo(() => {
    switch (color) {
      case 'green':
        return <TrafficLightGreenCustomIcon className="w-[30px]" />;
      case 'red':
        return <TrafficLightRedCustomIcon className="w-[30px]" />;
      case 'yellow':
        return <TrafficLightYellowCustomIcon className="w-[30px]" />;
    }
  }, [color]);

  return (
    <div className="flex gap-3 items-center">
      {trafficLightImage}
      <div className="flex flex-col gap-0.5 justify-center">
        {React.cloneElement(icon as ReactElement, { className: 'w-6 h-6' })}
        <span className="text-lg font-bold leading-none">{value * 100}%</span>
        <span className="text-[11px] text-gray-500 font-medium leading-none">{title}</span>
      </div>
    </div>
  );
};
