import React from 'react';
import { LoadingIndicator } from '@client/shared/toolkit';
import { ProjectMetadata } from '@client/project/shared';
import { ProjectReadModel } from '@client/shared/api';
import { ProjectDetails, ProjectLocationContainer, ReportKpi } from '..';
import { useTranslation } from 'react-i18next';

interface DefaultDashboardProps {
  isFetchingProject: boolean;
  // TODO this meta data thingy needs a whole refactoring...
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata: any;
  project: ProjectReadModel;
  multiProject: boolean;
}

export const DefaultDashboard = (props: DefaultDashboardProps) => {
  const {
    isFetchingProject,
    metadata,
    project,
    multiProject
  } = props;
  const { t } = useTranslation();

  return (
    <div className="flex flex-col lg:grid xl:grid lg:grid-cols-3 xl:grid-cols-8 2xl:grid-cols-7 3xl:grid-cols-3 gap-3" id="pdf-content">
      {isFetchingProject ? (
        <LoadingIndicator mode="full-height" />
      ) : (
        <div className="order-0 col-span-full lg2:col-span-2 xl:col-span-4 2xl:col-span-3 3xl:col-span-1">
          <ProjectMetadata
            bgf={metadata.bgf}
            grz={metadata.grz}
            plot={metadata.plot}
            gfz={metadata.gfz}
            avgRentPerSqMtr={metadata.avgRentPerSqMtr}
            avgSalePerSqMtr={metadata.avgSalePerSqMtr}
            roi={metadata.roi}
            irr={metadata.irr}
            roe={metadata.roe}
            ownCapitalRatio={metadata.ownCapitalRatio}
            startDate={metadata.startDate}
            endDate={metadata.endDate}
            address={metadata.address}
            deliveryPhasesCount={metadata.deliveryPhasesCount}
            totalProfit={metadata.totalProfit}
            plannedCostAndRisk={metadata.plannedCostAndRisk}
            plannedCostUnit={metadata.plannedCostUnit}
            plannedEarnings={metadata.plannedEarnings}
            deviation={metadata.deviation}
            deviationPercent={metadata.deviationPercent}
            usageType={metadata.usageType}
            unitSystem={metadata.unitSystem}
            projectId={project?.payload.id}
          />
        </div>
      )}

      <div className="order-1 lg2:order-2 xl:order-1 col-span-full xl:col-span-4 2xl:col-span-4 3xl:col-span-2">
        <ProjectLocationContainer
          header={t('project.projectInfoCardProjectLocationTitle')}
          location={metadata.address}
          latitude={metadata.latitude}
          longitude={metadata.longitude}
          pois={metadata.pois}
          multiProject={multiProject}
        />
        {false && <ProjectDetails />}
      </div>
      <ReportKpi />
    </div>
  )
}
