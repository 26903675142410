import {
  UpsertCustomDashboardPayload,
  useApiGetCustomDashboardQuery,
  useApiPostCreateCustomDashboardMutation,
  useApiPostUpdateCustomDashboardMutation
} from '@client/shared/api';
import {
  mapDashboardWidgetsToGridLayout,
  prepareWidgetsToSave,
  Widget,
  WidgetDashboard
} from '.';
import { useMemo } from 'react';
import { useLoadedProjectId } from '@client/project/store';
import { EditButton, LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { settings } from '@client/shared/store';

interface WidgetCustomDashboardProps {
  multiProject: boolean;
  children: React.ReactNode;
}

export const WidgetCustomDashboard = (props: WidgetCustomDashboardProps) => {
  const {
    multiProject,
    children
  } = props;
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();

  const [createCustomDashboard, { isLoading: isCreatingCustomDashboard }] = useApiPostCreateCustomDashboardMutation();
  const [updateCustomDashboard, { isLoading: isUpdatingCustomDashboard }] = useApiPostUpdateCustomDashboardMutation();

  const { data: dashboardData, isFetching: isFetchingDashboard } = useApiGetCustomDashboardQuery(
    {
      projectId: loadedProjectId || '',
    },
    { skip: loadedProjectId == null },
  );

  /**
   * Maps the data from the backend to our layout data
   */
  const dashboardLayout: Widget[] = useMemo(() => {
    if (dashboardData) {
      return mapDashboardWidgetsToGridLayout('default', dashboardData);
    }
    // TODO create default dashboard widgets
    return [];
  }, [dashboardData]);

  const handleSave = async (widgets: Widget[]) => {
    if (loadedProjectId) {
      if (!dashboardData?.id) {
        try {
          await safeMutation(
            createCustomDashboard,
            {
              projectId: loadedProjectId,
              body: {
                name: 'Custom Dashboard',
                cashOutWidgets: [],
                mapViewWidgets: [],
              },
            },
            isCreatingCustomDashboard,
          );
        } catch (e) {
          console.error(e);
        }
      } else {
        await safeMutation(
          updateCustomDashboard,
          {
            projectId: loadedProjectId,
            dashboardId: dashboardData.id,
            body: {
              name: dashboardData?.name,
              ...prepareWidgetsToSave(widgets)
            } as UpsertCustomDashboardPayload,
          },
          isUpdatingCustomDashboard,
        );
      }
    }
  };

  return (
    <div id="pdf-content">
      {(isFetchingDashboard || isCreatingCustomDashboard || isUpdatingCustomDashboard) ? (
        <LoadingIndicator text={t('common.loading')} mode="overlay-window" />
      ) : (
        <>
          {!dashboardData ? (
            <>
              {/* render old default dashboard for now */}
              {children}
              {/* TODO */}
              {settings.devMode && (
                <EditButton className="absolute top-52 right-6" onClick={() => handleSave([])} />
              )}
            </>
          ) : (
            <WidgetDashboard layout={dashboardLayout} onSave={handleSave} dashboardId={dashboardData?.id} dashboardName={dashboardData?.name} multiProject={multiProject} />
          )}
        </>
      )}
    </div>
  );
};
