import { TaxonomyCostItemsReadModel } from '@client/shared/api';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getAssetClassLabel } from '@client/project/store';
import { useMemo } from 'react';
import { getTaxonomyIcon } from '@client/project/shared';

interface TaxonomyTreeItemProps {
  item: TaxonomyCostItemsReadModel;
  depth: number;
  selectedItem?: TaxonomyCostItemsReadModel;
  doSelect: (item: TaxonomyCostItemsReadModel) => void;
}

export const TaxonomyTreeItem = ({ depth, doSelect, item, selectedItem }: TaxonomyTreeItemProps) => {
  const { t } = useTranslation();

  const treeItem = item.taxonomyItem;
  const contractsTextClasses = item.costItems.length > 0 ? 'text-sky-900/60' : 'text-slate-900/20';

  const isSelected = selectedItem?.taxonomyItem.itemId === item.taxonomyItem.itemId;

  const icon = useMemo(() => {
    const IconComponent = getTaxonomyIcon(treeItem.itemType)
    return <IconComponent className="h-10 mb-2" />
  }, [treeItem.itemType])

  return (
    <div className="text-sky-900/60 bg-slate-100 font-semibold pl-0 divide-white divide-y-2">
      <div
        className={classNames('relative h-fit p-3 flex hover:bg-slate-200 ', { 'bg-slate-200': isSelected })}
        onClick={() => doSelect(item)}
      >
        <div className="w-3/12 flex flex-row cursor-pointer">
          <div style={{ paddingRight: 25 * depth + 'px' }} />
          <div className="pl-10 flex-none">
            {icon}
          </div>
          <div className="pl-8">
            <p className="font-bol">
              {treeItem.itemName}
              {treeItem.itemCustomerName && <span> &bull; {treeItem.itemCustomerName}</span>}
            </p>
            <p className="text-sm font-extralight">
              {t(`projectTaxonomy.taxonomyType.${treeItem.itemType}`)}
              {treeItem.selectedAssetClass && treeItem.selectedAssetClass.enumValue !== 'Undefined' && (
                <span> &bull; {getAssetClassLabel(treeItem.selectedAssetClass)}</span>
              )}
            </p>
          </div>
        </div>
        <div className={`w-5/12 flex flex-row cursor-pointer ${contractsTextClasses}`}>
          {item.attachedContracts && item.attachedContracts.length > 0 && (
            <div>
              <div className="pl-24">
                <p className="font-bol text-right">{item.attachedContracts.length}</p>
                <p className="text-sm font-extralight">
                  {t('projectRent.taxonomyTreeItemContract', { count: item.attachedContracts.length })}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      {item.childItems != null &&
        item.childItems.length > 0 &&
        item.childItems.map((c) => (
          <TaxonomyTreeItem
            depth={depth + 1}
            item={c}
            key={c.taxonomyItem.itemId}
            selectedItem={selectedItem}
            doSelect={doSelect}
          />
        ))}
    </div>
  );
};
