import React, { RefObject, useMemo } from 'react';
import { FileInput, Form, FormField, FormWatch, Tabs, TextInput } from '..';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { InferType } from 'yup';

export const CreateEmbeddedLinkFormValidationSchema = yup.object({
  fileName: yup.string().required('validation.required'),
  fileLink: yup.string().required('validation.required'),
});

export type CreateEmbeddedLinkFormValidationValues = InferType<typeof CreateEmbeddedLinkFormValidationSchema>;

interface UploadFileTabsProps {
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  acceptedFileTypes: string[];
  setFile: (file: File | null) => void;
  formRef: RefObject<HTMLFormElement>;
  handleCreateEmbedLink: (data: CreateEmbeddedLinkFormValidationValues) => void;
  setFileError?: (error: boolean) => void;
  setData: (values: CreateEmbeddedLinkFormValidationValues) => void;
}

export const UploadFileTabs = (props: UploadFileTabsProps) => {
  const { selectedIndex, setSelectedIndex, acceptedFileTypes, setFile, setFileError } = props;
  const { t } = useTranslation();

  const tabs = useMemo(() => {
    const handleFileChange = (fileList: FileList | null) => {
      if (fileList) {
        setFile(fileList[0]);
      } else {
        setFile(null);
      }
    };

    return [
      {
        header: t('common.upload'),
        panel: (
          <FileInput
            className="bg-white rounded-sm"
            acceptedFileTypes={acceptedFileTypes}
            multiple={false}
            onChange={handleFileChange}
            setError={setFileError}
          />
        ),
      },
      {
        header: t('common.embedLink'),
        panel: <EmbedLinkForm {...props} />,
      },
    ];
  }, [t, acceptedFileTypes, setFile, props, setFileError]);

  return <Tabs options={tabs} selectedIndex={selectedIndex} tabChange={setSelectedIndex} variant="upload" />;
};

export const EmbedLinkForm = (props: UploadFileTabsProps) => {
  const { formRef, handleCreateEmbedLink, setData } = props;

  const defaultFormValues = {
    fileLink: '',
    fileName: '',
  };

  const { t } = useTranslation();
  return (
    <Form<CreateEmbeddedLinkFormValidationValues>
      onSubmit={handleCreateEmbedLink}
      validationSchema={CreateEmbeddedLinkFormValidationSchema}
      defaultValues={defaultFormValues}
      ref={formRef}
    >
      <FormWatch fieldNames={['fileLink', 'fileName']}>
        {({ fileLink, fileName }) => (
          <>
            <FormField name="fileLink">
              {(control) => (
                <TextInput
                  label={t('common.fileURL')}
                  className="mb-1"
                  {...control}
                  onChange={(value) => {
                    setData({ fileLink: value, fileName: fileName });
                    control.onChange(value);
                  }}
                />
              )}
            </FormField>
            <FormField name="fileName">
              {(control) => (
                <TextInput
                  label={t('common.fileName')}
                  {...control}
                  onChange={(value) => {
                    setData({ fileLink: fileLink, fileName: value });
                    control.onChange(value);
                  }}
                />
              )}
            </FormField>
          </>
        )}
      </FormWatch>
    </Form>
  );
};
