import { Chart } from 'react-chartjs-2';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Scale,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import { CashOutReportReadModel, useApiGetCashOutReportQuery } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { getLanguageAndLocale, isDateInBetween } from '@client/shared/utilities';
import { useLoadedProjectId, useLoadedVariant, useLoadedVariantId } from '@client/project/store';
import { format, getMonth, getYear } from 'date-fns';
import { eachMonthOfInterval } from 'date-fns/fp';
import { LoadingIndicator } from '@client/shared/toolkit';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// ------- CONSTANTS START -------
const CHART_PADDING_X = 20;

const BLUE_LINE_COLOR = '#0A5A85'; // secondary
const WHITE_COLOR = '#ffffff'; // white
const TODAY_MARKER_COLOR = '#fbbf24'; // amber-400
const STATUS_MONTH_MARKER_COLOR = '#84cc16'; // lime-500
const GREEN_LINE_COLOR = '#34d399'; // emerald-400
const GRAY_COLOR = '#6b7280'; // gray-500
const BORDER_COLOR = '#d1d5db'; // gray-300
// ------- CONSTANTS END -------

export interface CashOutChartProps {
  showNet?: boolean;
  setLabels?: (labels: string[]) => void;
  updateData?: (data?: CashOutReportReadModel[]) => void;
  height?: number;
  variant?: 'default' | 'compact';
}

/**
 * SAZ / Ramp curve / Cashout Chart
 */
export const CashOutChart = (props: CashOutChartProps) => {
  ChartJS.register(
    annotationPlugin,
    BarController,
    BarElement,
    LinearScale,
    PointElement,
    Tooltip,
    TimeScale,
    LinearScale,
    CategoryScale,
    LineElement,
    LineController,
    BarController,
    Title,
    ChartDataLabels,
  );
  const { updateData, setLabels, showNet = true, height, variant = 'default' } = props;
  const { t } = useTranslation();
  const locale = getLanguageAndLocale().locale;

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const { data: variantData, isFetching: isLoadingCalculationModel } = useLoadedVariant();
  const { data, isFetching } = useApiGetCashOutReportQuery(
    {
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId,
    },
  );

  useEffect(() => {
    if (updateData) {
      updateData(data);
    }
  }, [updateData, data]);

  const cashFlowSettings = useMemo(() => {
    return variantData?.calculationModel?.modelMetadata?.cashFlowSettings;
  }, [variantData?.calculationModel?.modelMetadata?.cashFlowSettings]);

  // To fill up the previous month
  const dataStartIndex = useMemo(() => {
    if (data?.length) {
      const firstDataDate = new Date(data[0].date);
      return firstDataDate.getMonth();
    }
    return 1;
  }, [data]);

  const start = useMemo(() => {
    if (data?.length) {
      const firstDataDate = new Date(data[0].date);
      const year = firstDataDate.getFullYear();
      return new Date(`${year}-01-01`); //new Date(data[0].date);
    }
    return new Date();
  }, [data]);

  const end = useMemo(() => {
    if (data?.length) {
      const lastDataDate = new Date(data[data.length - 1].date);
      const year = lastDataDate.getFullYear();
      return new Date(`${year}-12-01`); // return new Date(data[data.length - 1].date);
    }
    return new Date();
  }, [data]);

  const labels = useMemo(() => {
    const monthLabels: string[] = [];
    const yearLabels: string[] = [];
    const timelineMonths = eachMonthOfInterval({ start: start, end: end });
    timelineMonths.forEach((month) => {
      const monthName = format(month, 'LLL');
      const yearName = format(month, 'yyyy');
      monthLabels.push(monthName);
      if (!yearLabels.includes(yearName)) {
        yearLabels.push(yearName);
      }
    });

    return {
      monthLabels: monthLabels,
      yearLabels: yearLabels,
    };
  }, [start, end]);

  useEffect(() => {
    if (setLabels) {
      setLabels(labels.monthLabels);
    }
  }, [labels.monthLabels, setLabels]);

  const today = useMemo(() => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 1).setHours(0, 0, 0, 0);
  }, []);

  const statusMonth = useMemo(() => {
    if (cashFlowSettings?.statusMonth) {
      const statusMonthDate = new Date(cashFlowSettings.statusMonth);
      return new Date(statusMonthDate.getFullYear(), statusMonthDate.getMonth(), 1).setHours(0, 0, 0, 0);
    }
    return undefined;
  }, [cashFlowSettings?.statusMonth]);

  const datasetValues = useMemo(() => {
    const isBar: (number | null)[] = [];
    const shouldBar: (number | null)[] = [];
    const committed: (number | null)[] = [];
    const planned: (number | null)[] = [];
    const plannedFuture: (number | null)[] = [];
    const actuals: (number | null)[] = [];

    if (data) {
      // fill up the previous months
      if (dataStartIndex) {
        for (let i = 0; i < dataStartIndex; i++) {
          isBar.push(null);
          shouldBar.push(null);
          committed.push(null);
          planned.push(null);
          plannedFuture.push(null);
          actuals.push(null);
        }
      }
      data.forEach((dataset) => {
        const datasetDate = new Date(dataset.date).setHours(0, 0, 0, 0);
        isBar.push(showNet ? dataset.actualValuesSum.net : dataset.actualValuesSum.gross);
        committed.push(
          datasetDate <= today
            ? showNet
              ? dataset.cumulatedCommissionedContractTitlesValuesSum.net !== 0
                ? dataset.cumulatedCommissionedContractTitlesValuesSum.net
                : null
              : dataset.cumulatedCommissionedContractTitlesValuesSum.gross !== 0
                ? dataset.cumulatedCommissionedContractTitlesValuesSum.gross
                : null
            : null,
        );
        planned.push(
          datasetDate <= today
            ? showNet
              ? dataset.cumulatedPlannedValuesSum.net
              : dataset.cumulatedPlannedValuesSum.gross
            : null,
        );
        if (cashFlowSettings?.statusMonth && statusMonth) {
          if (cashFlowSettings?.overridePlan) {
            plannedFuture.push(
              datasetDate < statusMonth
                ? null
                : showNet
                  ? dataset.cumulatedPlannedValuesSum.net
                  : dataset.cumulatedPlannedValuesSum.gross
            );
            shouldBar.push(
              datasetDate < statusMonth
                ? null
                : showNet
                  ? dataset.plannedValuesSum.net
                  : dataset.plannedValuesSum.gross
            );
          }
          actuals.push(
            datasetDate <= statusMonth
              ? showNet
                ? dataset.cumulatedActualValuesSum.net
                : dataset.cumulatedActualValuesSum.gross
              : null,
          );
        } else {
          plannedFuture.push(
            datasetDate < today
             ? null
             : showNet
                ? dataset.cumulatedPlannedValuesSum.net
                : dataset.cumulatedPlannedValuesSum.gross
          );
          shouldBar.push(showNet ? dataset.plannedValuesSum.net : dataset.plannedValuesSum.gross);
          // actuals.push(showNet ? dataset.cumulatedActualValuesSum.net : dataset.cumulatedActualValuesSum.gross);
          actuals.push(
            datasetDate <= today
              ? showNet
                ? dataset.cumulatedActualValuesSum.net
                : dataset.cumulatedActualValuesSum.gross
              : null,
          );
        }
      });
    }
    return {
      isBar: isBar,
      shouldBar: shouldBar,
      committed: committed,
      planned: planned,
      plannedFuture: plannedFuture,
      actuals: actuals,
    };
  }, [
    data,
    showNet,
    today,
    cashFlowSettings?.statusMonth,
    dataStartIndex,
    cashFlowSettings?.overridePlan,
    statusMonth,
  ]);

  const yBarScaleMax = useMemo(() => {
    let arr = cashFlowSettings?.overridePlan
      ? [...datasetValues.plannedFuture]
      : [...datasetValues.planned, ...datasetValues.plannedFuture];
    arr = [...arr, ...datasetValues.committed, ...datasetValues.actuals, 0].filter((el) => el != null);
    const highestBarValue = Math.max(...(arr as number[]));
    return highestBarValue * 0.7;
  }, [datasetValues, cashFlowSettings?.overridePlan]);

  const todayMarkerPositionY = useMemo(() => {
    return yBarScaleMax;
  }, [yBarScaleMax]);

  const valueParsed = useCallback(
    (value: number) => {
      return new Intl.NumberFormat(locale, {
        maximumFractionDigits: 0,
        signDisplay: 'auto',
        notation: 'compact',
        compactDisplay: 'short',
      }).format(Number(value));
    },
    [locale],
  );

  const todayMarkerPositionX = useMemo(() => {
    const nowIsInBetween = isDateInBetween(today, start, end);
    if (nowIsInBetween) {
      const month = getMonth(today);
      const startYear = getYear(start);
      const todayYear = getYear(today);
      const yearDiff = todayYear - startYear;
      return yearDiff * 12 + month;
    }
    return -1;
  }, [start, end, today]);

  const statusMonthMarkerPositionX = useMemo(() => {
    if (cashFlowSettings?.statusMonth) {
      const statusMonthDate = new Date(cashFlowSettings.statusMonth);
      const nowIsInBetween = isDateInBetween(statusMonthDate, start, end);
      if (nowIsInBetween) {
        const month = getMonth(statusMonthDate);
        const startYear = getYear(start);
        const statusMonthYear = getYear(statusMonthDate);
        const yearDiff = statusMonthYear - startYear;
        return yearDiff * 12 + month;
      }
    }
    return -1;
  }, [cashFlowSettings?.statusMonth, start, end]);

  const isPlannedValuesHidden = useMemo(() => {
    return !!cashFlowSettings?.statusMonth && !!cashFlowSettings?.overridePlan;
  }, [cashFlowSettings?.statusMonth, cashFlowSettings?.overridePlan]);

  // ------- OPTIONS START -------
  const options: ChartOptions = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: false,
        },
        htmlLegend: {},
        legend: {
          display: false,
        },
        annotation: {
          annotations: {
            todayMarker: {
              type: 'label',
              xValue: todayMarkerPositionX,
              yValue: todayMarkerPositionY,
              padding: {
                top: 3,
                left: 10,
                right: 10,
                bottom: 1,
              },
              backgroundColor: TODAY_MARKER_COLOR,
              borderRadius: 50,
              content: [`  ${t('projectCalculate.timeLineToday')}  `],
              position: {
                x: 'center',
                y: 'center',
              },
              color: WHITE_COLOR,
              font: {
                family: 'Roboto',
                size: 9,
                weight: 'bold',
              },
              z: 2,
              display: todayMarkerPositionX >= 0,
            },
            todayMarkerLine: {
              type: 'line',
              yMin: 0,
              yMax: todayMarkerPositionY,
              xMin: todayMarkerPositionX,
              xMax: todayMarkerPositionX,
              borderWidth: 1,
              borderDash: [1.5],
              borderColor: TODAY_MARKER_COLOR,
              display: todayMarkerPositionX >= 0,
            },
            statusMonthMarker: {
              type: 'label',
              xValue: statusMonthMarkerPositionX,
              yValue: todayMarkerPositionY,
              padding: {
                top: 3,
                left: 10,
                right: 10,
                bottom: 1,
              },
              backgroundColor: STATUS_MONTH_MARKER_COLOR,
              borderRadius: 50,
              content: [`  ${t('projectCalculate.timeLineDueDate')}  `],
              position: {
                x: 'center',
                y: 'center',
              },
              color: WHITE_COLOR,
              font: {
                family: 'Roboto',
                size: 9,
                weight: 'bold',
              },
              z: 2,
              display: statusMonthMarkerPositionX >= 0,
            },
            statusMonthLine: {
              type: 'line',
              yMin: 0,
              yMax: todayMarkerPositionY,
              xMin: statusMonthMarkerPositionX,
              xMax: statusMonthMarkerPositionX,
              borderWidth: 1,
              borderDash: [1.5],
              borderColor: STATUS_MONTH_MARKER_COLOR,
              display: statusMonthMarkerPositionX >= 0,
            },
            commitment: {
              type: 'label',
              xValue: todayMarkerPositionX,
              yValue: datasetValues.committed[todayMarkerPositionX] ?? 0,
              yAdjust: -5,
              content: `${t('reporting.cashout.committed')} | ${valueParsed(
                datasetValues.committed[todayMarkerPositionX] ?? 0,
              )}`,
              color: WHITE_COLOR,
              backgroundColor: BLUE_LINE_COLOR,
              borderRadius: 50,
              padding: {
                top: 3,
                left: 10,
                right: 10,
                bottom: 1,
              },
              position: {
                x: 'start',
                y: 'end',
              },
              yScaleID: 'yLineChart',
              font: {
                family: 'Roboto',
                size: 9,
                weight: 'bold',
              },
            },
            planned: {
              type: 'label',
              xValue: todayMarkerPositionX,
              yValue: datasetValues.planned[todayMarkerPositionX] ?? 0,
              yAdjust: -5,
              content:
                statusMonthMarkerPositionX > 0
                  ? t('reporting.cashout.planned')
                  : `${t('reporting.cashout.planned')} | ${valueParsed(
                      datasetValues.planned[todayMarkerPositionX] ?? 0,
                    )}`,
              color: WHITE_COLOR,
              backgroundColor: GREEN_LINE_COLOR,
              borderRadius: 50,
              padding: {
                top: 3,
                left: 10,
                right: 10,
                bottom: 1,
              },
              position: {
                x: 'start',
                y: 'end',
              },
              yScaleID: 'yLineChart',
              font: {
                family: 'Roboto',
                size: 9,
                weight: 'bold',
              },
            },
            actuals: {
              type: 'label',
              xValue: statusMonthMarkerPositionX > 0 ? statusMonthMarkerPositionX : todayMarkerPositionX,
              yValue:
                statusMonthMarkerPositionX > 0
                  ? datasetValues.actuals[statusMonthMarkerPositionX] ?? 0
                  : datasetValues.actuals[todayMarkerPositionX] ?? 0,
              yAdjust: -5,
              content: `${t('reporting.cashout.actuals')} | ${valueParsed(
                statusMonthMarkerPositionX > 0
                  ? datasetValues.actuals[statusMonthMarkerPositionX] ?? 0
                  : datasetValues.actuals[todayMarkerPositionX] ?? 0,
              )}`,
              color: WHITE_COLOR,
              backgroundColor: BORDER_COLOR,
              borderRadius: 50,
              padding: {
                top: 3,
                left: 10,
                right: 10,
                bottom: 1,
              },
              position: {
                x: 'start',
                y: 'end',
              },
              yScaleID: 'yLineChart',
              font: {
                family: 'Roboto',
                size: 9,
                weight: 'bold',
              },
            },
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          labels: labels.monthLabels,
          beginAtZero: true,
          grid: {
            drawOnChartArea: false,
            tickLength: variant === 'default' ? -10 : 6,
            lineWidth: 2,
          },
          border: {
            width: 2,
            color: BORDER_COLOR,
          },
          afterFit(axis: Scale) {
            axis.height = variant === 'default' ? 20 : 0;
          },
          ticks: {
            padding: variant === 'default' ? 15 : 0,
            font: {
              family: 'Roboto',
              size: 9,
            },
            callback: function(_val, index) {
              return variant === 'default' ? labels.monthLabels[index]  : '';
            },
          },
        },
        xYear: {
          labels: labels.yearLabels,
          beginAtZero: true,
          border: {
            display: false,
            color: BORDER_COLOR,
          },
          grid: {
            drawOnChartArea: false,
            tickLength: variant === 'default' ? -20 : 15,
            lineWidth: 2,
          },
          afterFit(axis: Scale) {
            axis.height = 25;
          },
          ticks: {
            padding: variant === 'default' ? 20 : -5,
            font: {
              family: 'Roboto',
              size: 9,
              weight: 'bold',
            },
          },
        },
        yBarChart: {
          beginAtZero: false,
          stacked: false,
          id: 'yBarChart',
          type: 'linear',
          position: 'left',
          border: {
            width: 2,
            color: BORDER_COLOR,
          },
          grid: {
            drawOnChartArea: false,
          },
          min: 0,
          // max: yBarScaleMax,
          ticks: {
            font: {
              family: 'Roboto',
              size: 9,
              weight: 'bold',
            },
            color: GRAY_COLOR,
            beginAtZero: true,
            callback: (value) => {
              return new Intl.NumberFormat(locale, {
                maximumFractionDigits: 2,
                signDisplay: 'auto',
                notation: 'compact',
                compactDisplay: 'short',
              }).format(Number(value));
            },
            autoSkip: variant === 'compact',
            maxTicksLimit: variant === 'compact' && height && height <= 105 ? 3 : undefined
          },
        },
        yLineChart: {
          beginAtZero: true,
          id: 'yLineChart',
          type: 'linear',
          position: 'right',
          border: {
            width: 2,
          },
          grid: {
            drawOnChartArea: false,
          },
          ticks: {
            font: {
              family: 'Roboto',
              size: 9,
              weight: 'bold',
            },
            color: GRAY_COLOR,
            callback: (value) => {
              return new Intl.NumberFormat(locale, {
                maximumFractionDigits: 2,
                signDisplay: 'auto',
                notation: 'compact',
                compactDisplay: 'short',
              }).format(Number(value));
            },
            autoSkip: variant === 'compact',
            maxTicksLimit: variant === 'compact' && height && height <= 105 ? 3 : undefined
          },
        },
      },
      layout: {
        padding: {
          top: 10,
          left: CHART_PADDING_X,
          bottom: 10,
          right: CHART_PADDING_X,
        },
      },
    };
  }, [
    todayMarkerPositionX,
    labels.monthLabels,
    labels.yearLabels,
    todayMarkerPositionY,
    datasetValues,
    valueParsed,
    t,
    locale,
    statusMonthMarkerPositionX,
    variant,
    height
  ]);
  // ------- OPTIONS END -------

  // ------- CHART DATA START -------
  const chartData: ChartData = useMemo(() => {
    return {
      labels: labels.monthLabels,
      datasets: [
        {
          label: t('reporting.cashout.actuals') ?? 'Actual',
          data: datasetValues.isBar,
          backgroundColor: GRAY_COLOR,
          type: 'bar' as const,
          xAxisID: 'x',
          yAxisID: 'yBarChart',
          stack: 'Stack 1',
          datalabels: {
            labels: {
              title: null,
            },
          },
          barPercentage: 3.5,
          categoryPercentage: 0.3,
          order: 3,
        },
        {
          label: t('reporting.cashout.planned') ?? 'Planned',
          data: datasetValues.shouldBar,
          datalabels: {
            labels: {
              title: null,
            },
          },
          type: 'bar' as const,
          backgroundColor: GREEN_LINE_COLOR,
          xAxisID: 'x',
          yAxisID: 'yBarChart',
          stack: 'Stack 0',
          barPercentage: 3.5,
          categoryPercentage: 0.3,
          order: 3,
        },
        {
          label: t('reporting.cashout.committed') ?? 'Committed',
          data: datasetValues.committed,
          type: 'line' as const,
          xAxisID: 'x',
          yAxisID: 'yLineChart',
          fill: false,
          borderWidth: 2,
          borderDash: [2, 2.5],
          borderColor: BLUE_LINE_COLOR,
          datalabels: {
            labels: {
              title: null,
            },
          },
          order: 1,
        },
        {
          label: t('reporting.cashout.planned') ?? 'Planned',
          data: datasetValues.planned,
          type: 'line' as const,
          xAxisID: 'x',
          yAxisID: 'yLineChart',
          fill: false,
          borderColor: GREEN_LINE_COLOR,
          datalabels: {
            labels: {
              title: null,
            },
          },
          order: 1,
          hidden: isPlannedValuesHidden,
        },
        {
          label: t('reporting.cashout.planned') ?? 'Planned',
          data: datasetValues.plannedFuture,
          type: 'line' as const,
          xAxisID: 'x',
          yAxisID: 'yLineChart',
          fill: false,
          borderColor: GREEN_LINE_COLOR,
          borderDash: [2, 2.5],
          borderWidth: 2,
          datalabels: {
            labels: {
              title: null,
            },
          },
          order: 1,
        },
        {
          label: t('reporting.cashout.actuals') ?? 'Actual',
          data: datasetValues.actuals,
          type: 'line' as const,
          xAxisID: 'x',
          yAxisID: 'yLineChart',
          fill: false,
          borderColor: BORDER_COLOR,
          datalabels: {
            labels: {
              title: null,
            },
          },
          order: 1,
        },
      ],
    };
  }, [labels.monthLabels, datasetValues, t, isPlannedValuesHidden]);
  // ------- CHART DATA END -------

  return (
    <>
      {isLoadingCalculationModel || isFetching ? (
        <LoadingIndicator />
      ) : (
        <>
          {data && data.length > 0 ? (
            <Chart options={options} data={chartData} type="bar" height={height} className="chart-export" />
          ) : (
            <span className="mx-6">{t('reporting.noDataAvailable')}</span>
          )}
        </>
      )}
    </>
  );
};
