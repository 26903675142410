import {
  UpsertCashOutWidgetPayload,
  UpsertMapViewWidgetPayload,
  UpsertTextboxWidgetPayload,
  UpsertTrafficLightsWidgetPayload,
} from '@client/shared/api';
import { Widget, WidgetConfig } from '../WidgetDashboard';
import { DashboardConfigKeys, DashboardWidgetType, WidgetSizeType } from './widgetDashboardConfig';
import {
  WidgetDashboardEditFormMapViewValidationValues,
  WidgetDashboardEditFormTextBoxValidationValues,
  WidgetDashboardEditFormTrafficLightsValidationValues,
} from '../WidgetEditForms';
import { WidgetDashboardEditFormValues } from '../WidgetDashboardEdit';

const generateWidgetPayload = (widget: Widget) => {
  return {
    name: widget.widget.title,
    ...(widget.widget.lat !== undefined && { lat: widget.widget.lat }), // MapView widget
    ...(widget.widget.lng !== undefined && { lng: widget.widget.lng }), // MapView widget
    ...(widget.widget.text && { text: widget.widget.text }), // Text widget
    startingColumn: widget.layout.x,
    startingRow: widget.layout.y,
    amountOfColumns: widget.layout.w,
    amountOfRows: widget.layout.h,
    ...(widget.widget.trafficLights !== undefined && { trafficLights: widget.widget.trafficLights }) // Traffic Lights widget
  };
};

export const prepareWidgetsToSave = (
  currentLayout: Widget[],
  widgetToSave?: Widget,
  type: DashboardConfigKeys = 'default',
) => {
  const cashOutWidgets: UpsertCashOutWidgetPayload[] = [];
  const mapViewWidgets: UpsertMapViewWidgetPayload[] = [];
  const textboxWidgets: UpsertTextboxWidgetPayload[] = [];
  const trafficLightsWidgets: UpsertTrafficLightsWidgetPayload[] = [];

  const allWidgets = !widgetToSave || widgetToSave.widget.id ? currentLayout : [...currentLayout, widgetToSave];

  allWidgets.forEach((layoutItem) => {
    const widgetPayload =
      widgetToSave && layoutItem.widget.id === widgetToSave.widget.id
        ? generateWidgetPayload(widgetToSave)
        : generateWidgetPayload(layoutItem);

    switch (layoutItem.widget.type) {
      case DashboardWidgetType.CashOutPlan:
        cashOutWidgets.push(widgetPayload as UpsertCashOutWidgetPayload);
        break;
      case DashboardWidgetType.MapView:
        mapViewWidgets.push(widgetPayload as UpsertMapViewWidgetPayload);
        break;
      case DashboardWidgetType.TextBox:
        textboxWidgets.push(widgetPayload as UpsertTextboxWidgetPayload);
        break;
      case DashboardWidgetType.TrafficLight:
        trafficLightsWidgets.push(widgetPayload as UpsertTrafficLightsWidgetPayload);
        break;
      default:
        break;
    }
  });

  if (type === 'report') {
    return { cashOutWidgets, textboxWidgets, trafficLightsWidgets };
  }
  return { cashOutWidgets, mapViewWidgets };
};

export const prepareWidgetToSave = (
  data: WidgetDashboardEditFormValues,
  widget: Widget,
  size?: WidgetSizeType
): Widget => {
  return {
    layout: {
      ...widget.layout,
      x: widget?.layout.x ?? 0,
      y: widget?.layout.y ?? 0,
      w: size ? size.w : 1,
      h: size ? size.h : 1,
    },
    widget: getUpdatedWidgetForType(data, widget)
  };
};

export const getUpdatedWidgetForType = (
  data: WidgetDashboardEditFormValues,
  widget: Widget,
): WidgetConfig => {
  switch (widget.widget.type as DashboardWidgetType) {
    case DashboardWidgetType.MapView: {
      const mappedData = data as WidgetDashboardEditFormMapViewValidationValues;
      return {
        ...widget.widget,
        title: data.title,
        lat: mappedData.lat ?? undefined,
        lng: mappedData.lng ?? undefined,
      };
    }
    case DashboardWidgetType.TextBox: {
      const mappedData = data as WidgetDashboardEditFormTextBoxValidationValues;
      return {
        ...widget.widget,
        title: data.title,
        text: mappedData.text ?? '',
      };
    }
    case DashboardWidgetType.TrafficLight: {
      const mappedData = data as WidgetDashboardEditFormTrafficLightsValidationValues;
      return {
        ...widget.widget,
        title: data.title,
        trafficLights: mappedData.trafficLights ?? [],
      };
    }
    default:
      return {
        ...widget.widget,
        title: data.title,
      };
  }
};
