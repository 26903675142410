import { CustomListItemReadModel } from '@client/shared/api';
import { getAssetClassLabel } from './getAssetClassLabel';
import { ComboSelectOption } from '@client/shared/toolkit';

export const getAssetClasses = (availableAssetClasses : CustomListItemReadModel[]) : ComboSelectOption[] => {
  return availableAssetClasses
    .map((assetClass) => toComboSelectOptionRecursivly(assetClass))
    .sort((a, b) => {return a.label.localeCompare(b.label)});
};

const toComboSelectOptionRecursivly = (assetClass : CustomListItemReadModel) : ComboSelectOption => {
  return {
    label: getAssetClassLabel(assetClass), 
    value: assetClass.id,
    disabled: false,    
    options: assetClass.children
      .map((child) => toComboSelectOptionRecursivly(child))
      .sort((a, b) => {return a.label.localeCompare(b.label)})
  }
}